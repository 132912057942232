/**
 * Dokumententyp
 */
export enum DocumentType {

    /**
     * Selbstauskunft
     */
    SelfDisclosure = 82,

    /**
     * Ablehnungsschreiben
     */
    RejectionLetter = 83,

    /**
     *  Nachhaltiges Einkommen
     */
    SustainableIncome = 85,

    /** 
     * Kurzantrag stationär
     */ 
    ShortApplicationStationary = 86,
 
    /**
     * Kurzantrag mobil
     */
    ShortApplicationMobile = 87,

    /**
     * Kurzantrag eigene Mitarbeiter
     */
    ShortApplicationOwnEmployee = 88,
 
    /**
     * Kurzantrag Kleinstunternehmen (Micro Business)
     */
    ShortApplicationSmallBusiness = 89,

    /**
     * Pensions-Haushaltsrechnung
     * 
     */
    PensionHouseholdBalanceSignature = 90,

    /**
     * Zusatzblatt Haushaltsrechnung
     */
    AdditionalSheetSignature = 91,

    /**
     * Fire Ergebnis PDF
     */
    FireResult = 92,

    /**
     * Dokument zur Hinterlegung der Verbindlichkeitenaufstellung aus AGP (bankintern)
     */
    LiabilitiesInternal = 95,

    /**
     * Dokument zur Hinterlegung der Verbindlichkeitenaufstellung aus KSV (extern)
     */
    LiabilitiesExternal = 96
}
