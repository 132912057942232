import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * Übersetzt die Fehlermeldungen einer FormControl
 */
@Pipe({
    name: 'finProcessFormError',
})
export class FormErrorPipe implements PipeTransform {

    /**
     * Konstruktor
     *
     * @param {TranslateService} translate TranslateService-Injektor
     */
    public constructor(private translate: TranslateService) {

    }

    /**
     * Übersetzt die Fehlermeldung
     *
     * @param {ValidationErrors | null} error Validierungsfehler
     * @param {string} fieldName Optionaler Feldname
     * @returns {any} Übersetzung des Validierungsfehlers
     */
    public transform(error: ValidationErrors | null | undefined, fieldName?: string): string {
        if (error === null || error === undefined) {
            return '';
        }

        const keys = Object.keys(error);

        if (keys.length === 0) {
            return '';
        }

        const firstKey = keys[0];

        if (!!fieldName && firstKey === 'required') {
            return this.translate.instant('validation.requiredWithFieldName', { field: fieldName });
        }

        return this.translate.instant(`validation.${firstKey}`, error[firstKey]);
    }
} 
