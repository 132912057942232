<div *ngIf="!((isEsisVisible$ | async) ?? false)">
    <finprocess-hint type="info" class="p-3">
        <span header>
            {{ 'general.notice' | translate }}
        </span>
        <span content>
            {{ 'financing.features.financing-processing.esis.esisUnavailable' | translate }}
        </span>
    </finprocess-hint>
</div>
<div *ngIf="!loading && ((isEsisVisible$ | async) ?? false)" fxLayout="column" fxLayout.gt-md="row">
    <div fxLayout="column" fxLayoutGap="20px" fxFlex fxFlex.gt-md="60" fxFlex.gt-lg="35">
        <h2 class="color-primary">{{ 'navigation.financing.esis' | translate }}</h2>
        <input type="file" #file [multiple]="false" (change)="onFileUploadClick($event.target)" accept=".pdf"
            style="display: none;" />
        <mat-card *ngFor="let product of productsWithDocuments; let i = index">
            <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card-title style="word-break: break-word;" fxLayout="row" fxLayoutAlign="start center">
                    <span *ngIf="!product.editTitle">{{ product.name }}</span>
                    <mat-form-field *ngIf="product.editTitle" fxFlex>
                        <input matInput [ngModel]="product.name" (keydown.esc)="product.editTitle=false" #productName="ngModel" maxlength="80" required #productInput
                            (keydown.enter)="changeProductName($event.target, product)">
                        <button mat-icon-button matSuffix (click)="changeProductName(productInput, product);">
                            <mat-icon>done</mat-icon>
                        </button>
                        <mat-error *ngIf="!!productName.errors">{{ productName.errors | finProcessFormError }}</mat-error>
                    </mat-form-field>

                </mat-card-title>
                <div>
                    <button mat-icon-button (click)="product.editTitle = !product.editTitle;" matTooltip="{{ 'financing.features.financing-processing.esis.editProductName' | translate }}"
                        [disabled]="(readonly$ | async) ?? true">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf="!isNewCase" mat-icon-button (click)="deleteProduct(product)" matTooltip="{{ 'financing.features.financing-processing.esis.deleteProduct' | translate }}"
                        [disabled]="((readonly$ | async) ?? true) || productsWithDocuments.length <= 1">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <mat-card-subtitle>{{ product.productType | finprocessEnumTranslation:'ProductPackageType' }}
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayout="row" fxLayoutGap="12px">
                <ng-container *ngTemplateOutlet="Document; context:{documentType: DocumentType.ESIS, product, documents: product.esis}" ></ng-container>
                <ng-container *ngIf="(isFinService$ | async) ?? false">
                    <ng-container *ngTemplateOutlet="Document; context:{documentType: DocumentType.ContractDraft, product, documents: product.contractDrafts}" ></ng-container>
                </ng-container>
            </mat-card-content>

        </mat-card>
        <button *ngIf="!isNewCase" mat-stroked-button color="primary" (click)="addNewProduct()"
            [disabled]="(readonly$ | async) ?? true">
            <mat-icon>add</mat-icon>
            {{ 'financing.features.financing-processing.esis.addEsis' | translate }}
        </button>
    </div>
    <div fxFlex fxFlex.gt-md="40" fxFkex.gt-lg="65" class="esis-action-container px-3" fxLayout="column" fxLayoutGap="20px">
        <finprocess-hint *ngIf="(esisResponse$ | async) as esisResponse" [type]="esisResponse.status === FinancingStatus.Completed ? 'success' : 'error'">
            <span header *ngIf="esisResponse.status === FinancingStatus.Completed">{{ FinancingStatus.Completed | finprocessEnumTranslation:'FinancingStatus' }}</span>
            <span header *ngIf="esisResponse.status !== FinancingStatus.Completed">{{ FinancingSubStatus.EsisRejected | finprocessEnumTranslation:'FinancingSubStatus' }}</span>
            <div content>
                {{ esisResponse?.response }}
            </div>
        </finprocess-hint>
        <finprocess-hint [type]="sendEnabled ? 'success' : 'info'" *ngIf="(isEsisEditable$ | async) ?? false">
            <span header *ngIf="!sendEnabled">Achtung</span>
            <span header *ngIf="sendEnabled">{{ 'financing.features.financing-processing.esis.documentsReady' | translate }}</span>
            <div content fxLayout="column">
                <span header *ngIf="!sendEnabled" class="py-2">
                    <span *ngIf="(isFinService$ | async) === true">{{ 'financing.features.financing-processing.esis.missingDocumentsFinService' | translate }}</span>
                    <span *ngIf="(isFinService$ | async) === false">{{ 'financing.features.financing-processing.esis.missingDocuments' | translate }}</span>
                </span>
                <span *ngIf="sendEnabled" class="py-2">{{ 'financing.features.financing-processing.esis.sendDocumentsText' | translate }}</span>
                <button content mat-raised-button color="primary" [disabled]="!sendEnabled || ((readonly$ | async) ?? true)" (click)="setStatus()">
                    {{ 'financing.features.financing-processing.esis.sendDocumentsAction' | translate }}
                </button>
            </div>
        </finprocess-hint>
    </div>
</div>

<div class="waiter w-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <waiter-component [isLoading]="loading"></waiter-component>
</div>

<ng-template #Document let-documentType="documentType" let-product="product" let-documents="documents">
    <input type="file" #file [multiple]="false" (change)="onFileUploadClick($event.target)" accept=".pdf"
            style="display: none;" />
    <finprocess-label-value>
        <span label>{{ documentType | finprocessEnumTranslation:'DocumentType'}}</span>
        <div value>
            <div *ngFor="let doc of documents">
                <div *ngFor="let file of doc.files" fxLayout="row" fxLayoutGap="6px">
                    <div class="file-thumbnail mr-2"
                        (mouseenter)="handleOnMouseEnterForFileUpload($event, product)"
                        (mouseleave)="handleOnMouseLeaveForFileUpload($event, product)">
                        <img *ngIf="file.hasThumbnail && !!file.base64" class="thumbnail-img"
                            [src]="'data:image/jpeg;base64,' + file.base64" (click)="onDocumentAction(file, 'Open')">
                        <div *ngIf="!file.hasThumbnail" class="thumbnail-empty" fxLayout="row" fxLayoutAlign="center center">
                            <mat-icon>hide_image</mat-icon>
                        </div>
                        <div class="file-actions p-2" fxLayout="row" fxLayoutAlign="center center" @fadeInOut
                            fxLayoutGap="12px" *ngIf="product.buttonsVisible">
                            <button mat-icon-button matTooltip="{{ 'financing.features.financing-processing.esis.deleteDocument' | translate }}" [disabled]="readonly$.value" (click)="deleteDocumemt(doc)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="{{ 'financing.features.financing-processing.esis.downloadDocument' | translate }}" (click)="onDocumentAction(file, 'Download')">
                                <mat-icon>download</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="documents.length === 0" class="file-placeholder"
                [class.readonly]="readonly$.value" (dragenter)="handleOnDragEnterForFileUpload($event)"
                (dragleave)="handleOnDragLeaveForFileUpload($event)"
                (dragover)="handleOnDragOverForFileUpload($event)"
                (drop)="handleOnDropForFileUpload($event, documentType, product.id)"
                (click)="file.click(); setDocumentTypeAndProductId(documentType, product.id)">
                <div fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon>upload_file</mat-icon>
                    <span>{{ 'financing.features.financing-processing.esis.uploadFile' | translate }}</span>
                </div>
            </div>
        </div>

    </finprocess-label-value>
</ng-template>