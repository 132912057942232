import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService, ILiabilityConfiguration, Nullable, UUID } from 'app/modules/shared';
import { Observable, iif, map, mergeMap, of } from 'rxjs';

import { IAssignLiability, ICreateJointHeading, IDebitor, IFinancingSumLiabilities, ILiabilityData, ILiabilityDetail, ILiabilityUpdateForm } from '../../interfaces';
import { LiabilityLoaded } from '../../states';

/**
 * Liabilites Service
 */
@Injectable()
export class LiabilitiesService {

    /**
     * Konstruktor
     *
     * @param {AuthorizationService} authorizationService AuthorizationService 
     * @param {HttpClient} httpClient httpClient
     * @param {ConfigService} config configService
     * @param {Store} store Store-Injector
     */
    public constructor(
        private authorizationService: AuthorizationService,
        private httpClient: HttpClient,
        private config: ConfigService,
        private store: Store,
    ) { }

    /**
     * get liabilities
     *
     * @param {UUID} id financing container id
     * @returns {Observable} ILiabilityData | undefined
     */
    public getLiabilities(id: UUID): Observable<ILiabilityData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                (authorized ? this.httpClient.get<ILiabilityData>(`${this.config.getEnvironment().apiUrl}/Liabilities/getjh`,
                    {
                        params:
                    {
                        financingContainerID: id,
                    },
                    }).pipe(
                    mergeMap(data => this.store.dispatch(new LiabilityLoaded(data)).pipe(map(() => data))),
                ): of(undefined)),
            ),
        );
    }


    /**
     * get a single liability
     *
     * @param {UUID} id liability id
     * @returns {Observable} ILiabilityData | undefined
     */
    public getLiability(id: UUID): Observable<ILiabilityDetail | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                (authorized ? this.httpClient.get<ILiabilityDetail>(`${this.config.getEnvironment().apiUrl}/Liabilities/get`,
                    {
                        params:
                    {
                        liabilityId: id,
                    },
                    }) : of(undefined)),
            ),
        );
    }

    /**
     * create new Liability
     *
     * @param {ILiabilityDetail} liabilityData Liability Data
     * @returns {Observable} Observable
     */
    public createNewLiability(liabilityData: Nullable<ILiabilityDetail>): Observable<ILiabilityDetail | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<ILiabilityDetail>(
                        `${this.config.getEnvironment().apiUrl}/Liabilities/create`, liabilityData, {},
                    ),
                    of(undefined),
                ),
            ),
        );
    }


    /**
     * create new Joint Heading
     * 
     * @param {ICreateJointHeading} jointHeadingData joint Heading Interface
     * @returns {Observable} Observable
     */
    public createNewJointHeading(jointHeadingData: ICreateJointHeading): Observable<ICreateJointHeading | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<ICreateJointHeading>(`${this.config.getEnvironment().apiUrl}/Liabilities/createjh`, jointHeadingData,
                ),
                of(undefined),
            )),
        );
    }

    /**
     * assign Liability
     * 
     * @param {IAssignLiability} assignLiabilityData joint Heading Interface
     * @returns {Observable} Observable
     */
    public assignLiability(assignLiabilityData: IAssignLiability): Observable<unknown | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.put<unknown>(`${this.config.getEnvironment().apiUrl}/Liabilities/assignToJh`, undefined, {
                    params: {
                        ...assignLiabilityData,
                    },
                },
                ),
                of(undefined),
            )),
        );
    }


    /**
     * get all debitors for liabilities
     * 
     * @param {UUID} id id
     * @returns {Observable} Observable with Debitors
     */
    public getAllDebitors(id: UUID): Observable<IDebitor[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                (authorized ? this.httpClient.get<IDebitor[]>(`${this.config.getEnvironment().apiUrl}/Debitor/getall`,
                    {
                        params:
                    {
                        containerId: id,
                    },
                    }) : of(undefined)),
            ),
        );
    }


    /**
     * set Liability active
     * 
     * @param {UUID} liabilityId liability id
     * @param {boolean} active active checkbox
     * @returns {Observable} observable
     */
    public setLiabilityActive(liabilityId: UUID, active: boolean): Observable<unknown | undefined> {
        const params: { liabilityId: UUID, active: boolean } = {
            liabilityId,
            active,
        };
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.patch<unknown>(`${this.config.getEnvironment().apiUrl}/Liabilities/active`, undefined, { params }),
                of(undefined),
            )),
        );
    }

    /**
     * Ruft die Liste der Verbindlichkeiten und Summen für die Übersichtsseite ab
     * 
     * @param {UUID} financingContainerId ID des Containers
     * @returns {IFinancingSumLiabilities} Liste der Verbindlichkeiten und Summen
     */
    public getLiabilitieSumOverview(financingContainerId: UUID): Observable<IFinancingSumLiabilities | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IFinancingSumLiabilities>(`${this.config.getEnvironment().apiUrl}/Liabilities/getsumoverview`, {
                    params: {
                        financingContainerId,
                    },
                },
                ),
                of(undefined),
            )),
        );
    }

    /**
     * update Liability
     * 
     * @param {ILiabilityUpdateForm} updateLiabilityData update liability data
     * @returns {Observable} Observable
     */
    public updateLiability(updateLiabilityData: ILiabilityUpdateForm): Observable<ILiabilityDetail | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.put<ILiabilityDetail>(`${this.config.getEnvironment().apiUrl}/Liabilities/update`, updateLiabilityData,
                ),
                of(undefined),
            )),
        );
    }

    /**
     * get current Liability Configuration
     * 
     * @returns {Observable} ILiabilityConfiguration
     */
    public getCurrentLiabilityConfiguration(): Observable<ILiabilityConfiguration | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                (authorized ? this.httpClient.get<ILiabilityConfiguration>(`${this.config.getEnvironment().apiUrl}/Liabilities/CurrentLiabilityConfiguration`) : of(undefined)),
            ),
        );
    }

    /**
     * get Liabilities From External System
     * 
     * @param { UUID} financingContainerId financingContainerId
     * @returns {Observable} Observable
     */
    public getLiabilitiesFromExternalSystem(financingContainerId: UUID): Observable<unknown | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<string>(`${this.config.getEnvironment().apiUrl}/RiskManagement/GetRiskManagement`, undefined,
                    {
                        params: { finProcessContainerId: financingContainerId },
                    },
                ),
                of(undefined),
            )),
        );
    }
}
