export * from './core';
export { ITaxOfficeArrear } from './tax-office-arrear.interface';
export { IStatusEntry } from './status-entry.interface';
export { ISalesPartner } from './sales-partner.interface';
export { IRealEstate } from './real-estate.interface';
export { INewLiability } from './new-liability.interface';
export { ILiability } from './liability.interface';
export { ICareer } from './career.interface';
export { ICompany } from './company.interface';
export { IAssignmentHistoryEntry } from './assignment-history-entry.interface';
export { IAsset } from './asset.interface';
export { IAddress } from './address.interface';
export { IDebitor } from './debitor.interface';
export { IHousehold } from './household.interface';
export { IDocFile } from './doc-file.interface';
export { IFinancing } from './financing.interface';
export { IOverwriteCreateOrUpdateRequest } from './overwrite-create-or-update.request';
export { IOverwriteDelete } from './overwrite-delete.request';
export { IDocument } from './document.interface';
export { IDocumentView } from './document-view.interface';
export { IFile } from './file.interface';
export { IDownloadFilesRequest } from './download-files.request';
export { IDocumentViewerInput } from './document-viewer-input.interface';
export { IDeleteSampleCalculationRequest } from './delete-sample-calculation.request';
export { ISaveInternalFieldRequest } from './save-internal-field.request';
export { ISetStatusRequest } from './set-status.request';
export { IHouseholdFileUploadRequest } from './household-file-upload.request';
export { IDebitorFileUploadRequest } from './debitor-file-upload.request';
export { IFileUploadRequest } from './file-upload.request';
export { IUploadContext, IUploadList, IUploadDocument } from './upload-tab.interface';
export { IDeleteHouseholdFileRequest } from './delete-household-file.request';
export { IDeleteFileRequest } from './delete-file.request';
export { ICreditLineCalculation } from './credit-line-calculation.interface';
export { ISampleCalculationContainer } from './sample-calculation-container.interface';
export { IFixedInterestRateEndDateHelper } from './fixed-interest-rate-end-date-helper.interface';
export { ICustomerBasicData } from './customer-basic-data.interface';
export { IVerifiedCustomer } from './verifyed-customer.interface';
export { IUpdateModel } from './customer-update-data.interface';
export { IDeleteDebtorFile } from './delete-debtor-file'
export { IRFPData } from './riskfinancingplans-data.interface';
export { IAssignLiability } from './assign-liability.interface';
export { ICreateJointHeading } from './create-joint-heading.interface';
export { IJointHeading } from './joint-heading.interface';
export { ILiabilityDebitor } from './liability-debitor.interface';
export { ILiabilityData, IHouseholdJointHeading, IFilteredJointHeading, ILiabilityDataByHousehold, ILiabilityWithJointHeading } from './liabilitydata.interface';
export { ILiabilityDetail } from './liability-detail.interface';
export { IFinancingSumLiabilities } from './financing-sum-liabilities.interface';
export { IHouseholdSumLiabilities } from './household-sum-liabilities.interface';
export { IDebitorSumLiabilities } from './debitor-sum-liabilities.interface';
export { ILiabilityForSum } from './liability-for-sum.interface';
export { IFinprocessContainer } from './finprocess-container.interface';
export { IScoringGWB } from './scoring-gwb.interface';
export { IAdditionalSheetsInformation } from './additional-sheet-information.interface';
export { IAdditionalSheetsActivate } from './additional-sheet-activate.interface';
export { IAdditionalSheetsInformationText } from './additional-sheet-information-text.interface';
export { IAdditionalSheetsGenerate } from './additional-sheet-generate.interface';
export { IHouseholdCalcData } from './household-calc-data.interface';
export { IProductPackageData } from './product-package-data.interface';
export { IProductPackage } from './product-package.interface';
export { IHouseholdOverviewDocument } from './household-overview-document';
export { IHouseholdOverviewDocuments } from './household-overview-documents';
export { IHouseholdOverviewDocumentType } from './household-overview-document-type';
export { ISignHouseholdDocument } from './household-overview-sign-document';
export { ISampleCalculationData } from './sample-calculation-data.interface';
export { IGetSampleCalculationDocuments, IGetSampleCalculationDocumentsInfo } from './sample-calculation-document.interface';
export { ISampleCalculationProducts } from './sample-calculation-products.interface';
export { IApprovalData } from './approval-data.interface';
export { IFireRiskDecision } from './fire-risk-decision.interface';
export { IDebitorItem } from './debitor-item.interface';
export { IEditLiabilities } from './edit-liabilities.interface';
export { IApprovalStateData } from './approval-state-data';
export { IFinancingPlanStatusEntry } from './financingplan-status-entry.interface';
export { IProductPackageStatusEntry } from './product-package-status-entry.interface';
export { ILiabilityUpdateForm } from './liability-update-form-interface';
export { ISetApproverRequest } from './set-approver-request.interface';
export { IApproveProductPackageRequest } from './approve-product-package-request.interface';
export { IStepperStep } from './stepper-step.interface';
export { IAssetRealEstate } from './asset-real-estate';
export { IAssetProvider } from './asset-provider';
export { IAssetInsurance } from './asset-insurance';
export { IAssetBase } from './asset-basse';
export { IAssetAgp} from './asset-agp';
export { IAssetData } from './asset-data';
export { IAssetAccount} from './asset-account';
export { ICollateralRealEstateDetail } from './collateral-interfaces/collateral-real-estate-detail.interface';
export { ICollateralInsuranceDetail } from './collateral-interfaces/collateral-insurance-detail.interface';
export { ICollateralAccountDetail } from './collateral-interfaces/collateral-account-detail.interface';
export { IDgaCustomer } from './dga-customer';
export { IDgaSearch } from './dga-search';
export { ISecuredCollateralProducts } from './collateral-interfaces/collateral-secured-products.interface';
export { ICollateralAssignmentInsurance } from './collateral-interfaces/collateral-assignment-insurance.interface';
export { ICollateralAssignmentRealEstates } from './collateral-interfaces/collateral-assignment-realestates.interface';
export { ICollateralAssignmentAccount } from './collateral-interfaces/collateral-assignment-account.interface';
export { IRatingResult } from './rating-result';
export { IRatingResultRequest } from './rating-result-request';
export { IDecisionRequestModel } from './decision-request.interface';
export { IApprovalProductPackageCompetences } from './approval-product-packages-competences.interface';
export { IEsisForProducts } from './esis-for-products.interface';
export { IProductWithEsisDocuments } from './product-with-esis-documents.interface';
export { IProductFileUploadRequest } from './product-file-upload.request';
export { IDeleteEsisFileRequest } from './delete-esis-file.interface';
export { IProductPackageItems } from './product-package-items.interface';
export { IProductPackageAccepted } from './product-package-accepted.interface';
export { IMultipleStepsResult} from './multi-step-result.interface';
export { IMultiStepResponse } from './multi-step-response';
export { IJointHeadingFileUploadRequest } from './jointheading-file-upload-request';
export { IDeleteJointHeadingFileRequest } from './delete-jointheading-file.request';
export { IFireErrorMessage } from './fire-error-message.interface';
export { IRealEstateDetailDialogData } from './real-estate-detail-dialog-data.interface';
