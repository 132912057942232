/**
 * Tabs innerhalb einer Finanzierung
 */
export enum FinancingTab {
    General = 'General',
    Formal = 'Formal',
    Debitor = 'Debitor',
    Household = 'Household',
    FinancingPlan = 'FinancingPlan',
    RealEstate = 'RealEstate',
    ProductCalculator = 'ProductCalculator',
    Documents = 'Documents',
    Process = 'Process',
    SampleCalculation = 'SampleCalculation',
}
