<finprocess-two-column-layout leftFxLayoutAlign="start start" rightFxLayoutAlign="center center" [rightFxFlex]="40"
    [leftFxFlex]="60" rigthClass="pb-0 pt-3 px-5" leftClass="pb-0 pt-3 px-2">
    <div left *ngIf="(financing$ | async) as financing" style="padding-left: 15px;">
        <ng-container *ngIf="(debitors$ | async) as debitors">
            <div class="w-100 mb-0" fxLayout="row wrap">
                <div fxLayout="column" style="height: 48px;" *ngFor="let deb of debitors; index as i">
                    <div class="nav-item-custom" fxLayout="row" fxLayoutAlign="center center"
                        [class.nav-item-selected-custom]="selectedDebitor.value && deb.debitor.id === selectedDebitor.value.debitor.id"
                        (click)="debitorClicked(i)">
                        {{ deb.firstNameOverwrite }} {{ deb.lastNameOverwrite }}
                        <mat-icon *ngIf="deb.debitor.id === (economicLeader$ | async)?.id" color="primary"
                            class="ml-1">star</mat-icon>
                        <mat-icon *ngIf="deb.debitor.isOwnEmployee" class="ml-1 bank-logo"
                            svgIcon="finprocess-bank-logo"></mat-icon>
                    </div>
                    <hr
                        [class.border-animated]="selectedDebitor.value && deb.debitor.id === selectedDebitor.value.debitor.id">
                </div>

            </div>
            <hr class="custom-hr" style="position: relative;">
        </ng-container>


        <ng-container *ngIf="selectedDebitor.value">
            <input type="hidden" [value]="selectedDebitor.value.debitor.id" />
            <div class="area-container">
                <!-- Allgemein -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.person.subHeaderGeneral' | translate">
                </finprocess-sub-header>

                <finprocess-calculation-display [numberFunctionObservable]="householdNumber$"
                    [label]="'financing.features.financing-processing.person.housholdNumber' | translate"
                    [fxValueAlignment]="'start'" [format]="'1.0-0'">
                </finprocess-calculation-display>

                <finprocess-select-input *ngIf="(branchesItems$ | async) as branches"
                    [label]="'financing.features.financing-processing.person.branchId' | translate" [items]="branches"
                    [valueStorageType]="ValueStorageType.Guid"
                    [overwriteValueClassType]="OverwriteValueClassType.FinProcessContainer" [readonly]="alwaysReadonly$"
                    [entity]="financing" fieldName="branchId">
                </finprocess-select-input>

                <ng-container *ngIf="financing.mandantType !== MandantType.FinAdvisory">
                    <finprocess-select-input *ngIf="(salesPartnerCenterItems$ | async) as salesPartnerCenterItems"
                        [label]="'financing.features.financing-processing.person.salesPartnerCenterId' | translate"
                        [items]="salesPartnerCenterItems" [valueStorageType]="ValueStorageType.String"
                        [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                        [readonly]="alwaysReadonly$" [entity]="financing" fieldName="salesPartnerCenterId">
                    </finprocess-select-input>
                </ng-container>
            </div>

            <div class="area-container">
                <!-- Voraussichtliche Kreditlaufzeit -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.person.subHeaderCreditDuration' | translate">
                </finprocess-sub-header>

                <finprocess-integer-input
                    [label]="'financing.features.financing-processing.person.assumedDuration' | translate"
                    [placeholder]="'...'" [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="financing" fieldName="assumedDuration">
                </finprocess-integer-input>

                <finprocess-calculation-display [numberFunctionObservable]="ageDurationEndMapped$"
                    [label]="'financing.features.financing-processing.person.ageDurationEnd' | translate"
                    [fxValueAlignment]="'start'" [format]="'1.0-0'">
                </finprocess-calculation-display>

                <finprocess-textarea-input
                    [label]="'financing.features.financing-processing.person.creditDurationInRetirement' | translate"
                    placeholder="..." [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor"
                    fieldName="creditDurationInRetirement">
                </finprocess-textarea-input>
            </div>

            <div class="area-container">
                <!-- Kreditnehmer -->
                <finprocess-sub-header
                    *ngIf="selectedDebitor.value.firstNameOverwrite && selectedDebitor.value.lastNameOverwrite"
                    label="{{ selectedDebitor.value.firstNameOverwrite }} {{ selectedDebitor.value.lastNameOverwrite }}">
                </finprocess-sub-header>
                <finprocess-sub-header
                    *ngIf="!(selectedDebitor.value.firstNameOverwrite && selectedDebitor.value.lastNameOverwrite)"
                    [label]="'financing.features.financing-processing.person.debitor' | translate">
                </finprocess-sub-header>
                <span>

                </span>
                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.person.customerNumber' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="customerNumber"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <div class="w-100" fxLayoutAlign="end center" style="padding: 10px;"
                    *ngIf="(dgaAvailable$ | async) ?? false">
                    <button [disabled]="(fieldReadonly$ | async) ?? true" mat-raised-button color="primary"
                        (click)="getNdg(selectedDebitor.value.debitor, financing.id, financing.salesPartnerCenterId, selectedDebitor.value.householdId)">NDG
                        Abfrage</button>
                </div>
                <finprocess-textbox-input [label]="'dashboard.features.dashboard.communityCustomerNumber' | translate"
                    [entity]="financing" fieldName="communityCustomerNumber"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-select-input [label]="'financing.features.financing-processing.person.gender' | translate"
                    [items]="genderItems" [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="gender">
                </finprocess-select-input>

                <finprocess-textbox-input [label]="'financing.features.financing-processing.person.title' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="title"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input [label]="'financing.features.financing-processing.firstName' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="firstName"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input [label]="'financing.features.financing-processing.lastName' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="lastName"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>


                <finprocess-textbox-input [label]="'financing.features.financing-processing.street' | translate"
                    [entity]="selectedDebitor.value.debitor.homeAddress" fieldName="street"
                    [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input [label]="'financing.features.financing-processing.streetNumber' | translate"
                    [entity]="selectedDebitor.value.debitor.homeAddress" fieldName="streetNumber"
                    [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input [label]="'financing.features.financing-processing.stairway' | translate"
                    [entity]="selectedDebitor.value.debitor.homeAddress" fieldName="stairway"
                    [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input [label]="'financing.features.financing-processing.top' | translate"
                    [entity]="selectedDebitor.value.debitor.homeAddress" fieldName="top"
                    [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input [label]="'financing.features.financing-processing.zip' | translate"
                    [entity]="selectedDebitor.value.debitor.homeAddress" fieldName="zip"
                    [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input [label]="'financing.features.financing-processing.city' | translate"
                    [entity]="selectedDebitor.value.debitor.homeAddress" fieldName="city"
                    [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-select-input *ngIf="(allCountriesBirthCountry$ | async) as allCountries"
                    [label]="'financing.features.financing-processing.person.country' | translate"
                    [items]="allCountries" [valueStorageType]="ValueStorageType.String"
                    [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor.homeAddress" fieldName="country"
                    [showOther]="true">
                </finprocess-select-input>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.person.phoneNumber' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="phoneNumber"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.person.mobileNumber' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="mobileNumber"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input [label]="'financing.features.financing-processing.email' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="email"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-datepicker-input
                    [label]="'financing.features.financing-processing.person.birthday' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="birthday"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-datepicker-input>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.person.birthPlace' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="birthPlace"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-select-input *ngIf="(allCountriesBirthCountry$ | async) as allCountries"
                    [label]="'financing.features.financing-processing.person.birthcountry' | translate"
                    [items]="allCountries" [valueStorageType]="ValueStorageType.String"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="birthcountry"
                    [showOther]="true">
                </finprocess-select-input>

                <finprocess-select-input *ngIf="(allCountriesCiticen$ | async) as allCountries"
                    [label]="'financing.features.financing-processing.person.citizenship' | translate"
                    [items]="allCountries" [valueStorageType]="ValueStorageType.String"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="citizenship"
                    [showOther]="true">
                </finprocess-select-input>

                <finprocess-select-input *ngIf="(allCountriesCiticen$ | async) as allCountries"
                    [label]="'financing.features.financing-processing.person.additionalCitizenship' | translate"
                    [items]="allCountries" [valueStorageType]="ValueStorageType.String"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor"
                    fieldName="additionalCitizenship" [showOther]="true">
                </finprocess-select-input>

                <finprocess-textbox-input *ngIf="showTinNumber | async"
                    [label]="'financing.features.financing-processing.person.tinNumber' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="tinNumber"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-select-input
                    [label]="'financing.features.financing-processing.person.housingType' | translate"
                    [items]="housingTypeItems" [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="housingType">
                </finprocess-select-input>

                <finprocess-datepicker-input
                    [label]="'financing.features.financing-processing.person.livingAtHomeAddressSince' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="livingAtHomeAddressSince"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-datepicker-input>

                <finprocess-select-input *ngIf="(allCountriesTaxResidence$ | async) as allCountries"
                    [label]="'financing.features.financing-processing.person.taxResidence' | translate"
                    [items]="allCountries" [valueStorageType]="ValueStorageType.String"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="taxResidence"
                    [showOther]="true">
                </finprocess-select-input>

                <finprocess-select-input
                    [label]="'financing.features.financing-processing.person.maritalStatus' | translate"
                    [items]="maritalStatusItems"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="maritalStatus">
                </finprocess-select-input>

                <finprocess-integer-input
                    [label]="'financing.features.financing-processing.person.childrenCount' | translate"
                    [placeholder]="'...'" [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="childrenCount">
                </finprocess-integer-input>

                <finprocess-integer-input
                    [label]="'financing.features.financing-processing.person.dependentPersonsCount' | translate"
                    [placeholder]="'...'" [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor"
                    fieldName="dependentPersonsCount">
                </finprocess-integer-input>

                <finprocess-boolean-input
                    [label]="'financing.features.financing-processing.person.ownCarExists' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="ownCarExists"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-boolean-input>

                <finprocess-select-input *ngIf="(bankAccounts$ | async) as bankAccounts"
                    [label]="'financing.features.financing-processing.person.bankAccount' | translate"
                    [items]="bankAccounts" [valueStorageType]="ValueStorageType.String"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="bankAccount">
                </finprocess-select-input>

                <finprocess-select-input
                    [label]="'financing.features.financing-processing.person.bankAustriaStatus' | translate"
                    [items]="bankAustriaStatusItems"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="bankAustriaStatus">
                </finprocess-select-input>

                <finprocess-select-input
                    [label]="'financing.features.financing-processing.person.levelOfTraining' | translate"
                    [items]="levelOfTrainingItems"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="levelOfTraining">
                </finprocess-select-input>
            </div>

            <div class="area-container">
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.person.subHeaderCustomerRating' | translate">
                </finprocess-sub-header>

                <ng-container *ngIf="selectedDebitor.value.debitor?.ratingResult?.pollingStatus === undefined">
                    <p>{{ 'financing.features.financing-processing.person.scoringNotCalled' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="selectedDebitor.value.debitor?.ratingResult?.pollingStatus !== undefined">
                    <finprocess-select-input [overwrite]="false"
                        [label]="'financing.features.financing-processing.person.status' | translate"
                        [items]="ratingPollingStatusSelectItems" [readonly]="alwaysReadonly$"
                        [entity]="selectedDebitor.value.debitor.ratingResult" fieldName="pollingStatus">
                    </finprocess-select-input>
                    <finprocess-textbox-input [overwrite]="false"
                        [label]="'financing.features.financing-processing.person.rating' | translate"
                        [entity]="selectedDebitor.value.debitor?.ratingResult" fieldName="rating"
                        [readonly]="alwaysReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-datepicker-input [overwrite]="false"
                        [label]="'financing.features.financing-processing.person.date' | translate"
                        [entity]="selectedDebitor.value.debitor.ratingResult" fieldName="date"
                        [readonly]="alwaysReadonly$">
                    </finprocess-datepicker-input>

                    <finprocess-textbox-input [overwrite]="false"
                        [label]="'financing.features.financing-processing.person.ratingStatus' | translate"
                        [entity]="selectedDebitor.value.debitor.ratingResult" fieldName="ratingStatus"
                        [readonly]="alwaysReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textarea-input [overwrite]="false"
                        [label]="'financing.features.financing-processing.person.notesPerson' | translate"
                        [placeholder]="'...'" [readonly]="alwaysReadonly$"
                        [entity]="selectedDebitor.value.debitor.ratingResult" fieldName="comment">
                    </finprocess-textarea-input>

                </ng-container>

            <div class="w-100" fxLayout="column" fxLayoutAlign="end end" style="padding: 10px;">                      
                <div style="color: red;" *ngIf="getDebtorNdg(selectedDebitor.value.debitor) === undefined">{{ 'financing.features.financing-processing.person.ratingWarn' | translate}}</div>
                <button [disabled]="(fieldReadonly$ | async) ?? true || getDebtorNdg(selectedDebitor.value.debitor) === undefined"  mat-raised-button color="primary" (click)="refreshScoring(selectedDebitor.value.debitor, selectedDebitor.value.householdId)">
                    {{ 'financing.features.financing-processing.person.calcNewScoring' | translate}}
                </button>
            </div> 
        </div>

            <div class="area-container">
                <!-- Arbeitsverhältnis -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.person.subHeaderEmployeeStatus' | translate">
                </finprocess-sub-header>

                <finprocess-select-input
                    [label]="'financing.features.financing-processing.person.employeeStatus' | translate"
                    [items]="employeeStatusItems"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="employeeStatus">
                </finprocess-select-input>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.person.profession' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="profession"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.person.employerName' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="employerName"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType.Debitor"
                    [label]="'financing.features.financing-processing.person.employerBranchCode' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="employerBranchesCode"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-datepicker-input
                    [label]="'financing.features.financing-processing.person.employedSince' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="employedSince"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-datepicker-input>

                <finprocess-integer-input
                    [label]="'financing.features.financing-processing.person.previousEmploymentDuration' | translate"
                    [placeholder]="'...'" [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor"
                    fieldName="previousEmploymentDuration">
                </finprocess-integer-input>

                <finprocess-boolean-input *ngIf="isEmployeeBranch"
                    [label]="'financing.features.financing-processing.person.ucbaEmployee' | translate"
                    [entity]="selectedDebitor.value.debitor" fieldName="isOwnEmployee" [overwrite]="false"
                    [entityClassType]="entityClassType.Debitor" [readonly]="fieldReadonly$">
                </finprocess-boolean-input>

                <ng-container *ngIf="selectedDebitor.value.debitor.employerAddress">

                    <finprocess-textbox-input [label]="'financing.features.financing-processing.street' | translate"
                        [entity]="selectedDebitor.value.debitor.employerAddress" fieldName="street"
                        [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.streetNumber' | translate"
                        [entity]="selectedDebitor.value.debitor.employerAddress" fieldName="streetNumber"
                        [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input [label]="'financing.features.financing-processing.zip' | translate"
                        [entity]="selectedDebitor.value.debitor.employerAddress" fieldName="zip"
                        [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input [label]="'financing.features.financing-processing.city' | translate"
                        [entity]="selectedDebitor.value.debitor.employerAddress" fieldName="city"
                        [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>
                </ng-container>

                <!-- career -->
                <!-- <ng-container class="area-container" *ngIf="!!selectedDebitor.value.debitor.careers"> -->
                <ng-container class="area-container">
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.person.career' | translate">
                    </finprocess-sub-header>

                    <div *ngFor="let career of selectedDebitor.value.debitor.careers">
                        <finprocess-datepicker-input
                            [label]="'financing.features.financing-processing.person.careerFrom' | translate"
                            [entity]="career" fieldName="from"
                            [overwriteValueClassType]="OverwriteValueClassType.CareerOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-datepicker-input>

                        <finprocess-datepicker-input
                            [label]="'financing.features.financing-processing.person.careerTo' | translate"
                            [entity]="career" fieldName="to"
                            [overwriteValueClassType]="OverwriteValueClassType.CareerOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-datepicker-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.person.careerWork' | translate"
                            [entity]="career" fieldName="work"
                            [overwriteValueClassType]="OverwriteValueClassType.CareerOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.person.careerBranch' | translate"
                            [entity]="career" fieldName="branch"
                            [overwriteValueClassType]="OverwriteValueClassType.CareerOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textarea-input
                            [label]="'financing.features.financing-processing.person.careerNotes' | translate"
                            [placeholder]="'...'"
                            [overwriteValueClassType]="OverwriteValueClassType.CareerOverwriteValue"
                            [readonly]="fieldReadonly$" [entity]="career" fieldName="notes">
                        </finprocess-textarea-input>
                    </div>
                </ng-container>

                <ng-container class="area-container">
                    <!-- Anmerkungen -->
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.person.subHeaderNotices' | translate">
                    </finprocess-sub-header>

                    <finprocess-textarea-input
                        [label]="'financing.features.financing-processing.person.notesPerson' | translate"
                        [placeholder]="'...'" [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                        [readonly]="fieldReadonly$" [entity]="selectedDebitor.value.debitor" fieldName="notesPerson">
                    </finprocess-textarea-input>
                </ng-container>
            </div>

        </ng-container>

    </div>

    <div right fxFill>
        <finprocess-document-viewer [documentFunction]="documents$"></finprocess-document-viewer>
    </div>
</finprocess-two-column-layout>