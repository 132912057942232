import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HouseholdCalculationType } from '@ntag-ef/finprocess-enums';
import { FinancingService } from 'app/modules/financing/data';
import { Observable } from 'rxjs';

import { IHouseholdCalcHouseholdsExtended } from '../household-calculations/household-calculations.component';


/**
 * household calculation overview table template
 */
@Component({
    selector: 'finprocess-household-calculation-table',
    templateUrl: './household-calculation-table.component.html',
    styleUrls: ['./household-calculation-table.component.scss'],
})
export class HouseholdCalculationTableComponent implements OnInit {

    @Input() public generateDocumentDisabled?: boolean

    @Input() public isBaf?: boolean

    // Input property to pass the currentRiskHouseHoldPage value
    @Input() public currentHouseHoldPage?: number;

    // Input property to pass the totalRiskHouseHoldDebitorPages value
    @Input() public totalHouseHoldPages?: number;

    // Input property to pass the visibleRiskHouseHoldDebitors array
    @Input() public visibleHouseHoldOverview?: IHouseholdCalcHouseholdsExtended[];

    // Input property to pass the household data array
    @Input() public householdData?: IHouseholdCalcHouseholdsExtended[];

    //previous or next output
    @Output() public previousOrNextDataEvent = new EventEmitter<number>();

    // Input property to pass the calctype value
    @Input() public calcType?: HouseholdCalculationType;

    //ermittelt ob es um die Tabelle Risikohaushaltsrechnung handelt
    @Input() public isRiskCalcTable = false;

    @Output()
    public generateDocument = new EventEmitter<string>();

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;


    /**
     * Konstruktor
     * 
     * @param {FinancingService} financingService financingservice 
     */
    public constructor(private financingService: FinancingService) {}
    
    /**
     * Initialisierung
     */
    public ngOnInit(): void {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
    }

    
    /**
     * Methode für emit Event - Generierung Haushaltsrechnungen
     * 
     * @param {string} householdId householdId
     */
    public onGenerateDocuments(householdId: string): void {
        this.generateDocument.emit(householdId)
    }

    /**
     * previous/next household data
     * 
     * @param {number} direction backwards/forwards
     */
    public onPreviousOrNextData(direction: number): void {
        this.previousOrNextDataEvent.emit(direction);
    }
}
