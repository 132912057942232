<finprocess-two-column-layout leftFxLayoutAlign="start start" rightFxLayoutAlign="center center" [rightFxFlex]="40"
    [leftFxFlex]="60" rigthClass="pb-0 pt-3 px-2" leftClass="pb-0 pt-3 px-2">
    <div left>

        <ng-container *ngIf="(financing$ | async) as financing">
            <div class="area-container">
                <!-- Allgemein -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.formal.subHeaderGeneral' | translate ">
                </finprocess-sub-header>

                <finprocess-boolean-input
                    [label]="'financing.features.financing-processing.formal.privacyNoticeComplete' | translate"
                    [entity]="financing" fieldName="privacyNoticeComplete"
                    [overwrite]="false"
                    [entityClassType]="entityClassType.FinProcessContainer"
                    [readonly]="fieldReadonly$">
                </finprocess-boolean-input>

                <finprocess-boolean-input
                    [label]="'financing.features.financing-processing.formal.standardInformationComplete' | translate"
                    [entity]="financing" fieldName="standardInformationComplete"
                    [overwrite]="false"
                    [entityClassType]="entityClassType.FinProcessContainer"
                    [readonly]="fieldReadonly$">
                </finprocess-boolean-input>

                <finprocess-boolean-input
                    [label]="'financing.features.financing-processing.formal.signaturesComplete' | translate"
                    [entity]="financing" fieldName="signaturesComplete"
                    [overwrite]="false"
                    [entityClassType]="entityClassType.FinProcessContainer"
                    [readonly]="fieldReadonly$">
                </finprocess-boolean-input>
            </div>

            <ng-container *ngIf="(salesPartner$ | async) as salesPartner">

                <!-- Standardinformationen -->
                <div class="area-container">
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.formal.subHeaderStandardInfo' | translate">
                    </finprocess-sub-header>

                    <finprocess-textbox-input [label]="'financing.features.financing-processing.firstName' | translate"
                        [entity]="salesPartner" fieldName="firstName"
                        [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input [label]="'financing.features.financing-processing.lastName' | translate"
                        [entity]="salesPartner" fieldName="lastName"
                        [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.formal.salesPartner.vPConsultantNumber' | translate"
                        [entity]="salesPartner" fieldName="vPConsultantNumber"
                        [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.formal.salesPartner.brokerageContractNumber' | translate"
                        [entity]="financing" fieldName="brokerageContractNumber"
                        [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.formal.salesPartner.phoneNumber' | translate"
                        [entity]="salesPartner" fieldName="phoneNumber"
                        [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <ng-container *ngIf="(salesPartnerAddress$ | async) as salesPartnerAddress">
                        <finprocess-textbox-input [label]="'financing.features.financing-processing.street' | translate"
                            [entity]="salesPartnerAddress" fieldName="street"
                            [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.streetNumber' | translate"
                            [entity]="salesPartnerAddress" fieldName="streetNumber"
                            [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input [label]="'financing.features.financing-processing.zip' | translate"
                            [entity]="salesPartnerAddress" fieldName="zip"
                            [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input [label]="'financing.features.financing-processing.city' | translate"
                            [entity]="salesPartnerAddress" fieldName="city"
                            [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.formal.gisaNumber' | translate"
                            [entity]="salesPartner" fieldName="gisaNumber"
                            [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.formal.salesPartner.gisaAuthority' | translate"
                            [entity]="salesPartner" fieldName="gisaAuthority"
                            [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.formal.salesPartner.creditBrokerageKind' | translate"
                            [entity]="salesPartner" fieldName="creditBrokerageKind"
                            [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.formal.salesPartner.consultingServices' | translate"
                            [entity]="salesPartner" fieldName="consultingServices"
                            [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.formal.salesPartner.complaintChance' | translate"
                            [entity]="salesPartner" fieldName="complaintChance"
                            [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>
                    </ng-container>
                </div>
            </ng-container>

            <!-- Vertriebsunternehmen -->
            <div class="area-container">
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.formal.subHeaderCompany' | translate">
                </finprocess-sub-header>

                <ng-container *ngIf="(salesPartnerCompany$ | async) as salesPartnerCompany">
                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.formal.salesPartner.company.name' | translate"
                        [entity]="salesPartnerCompany" fieldName="name"
                        [overwriteValueClassType]="OverwriteValueClassType.CompanyOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.formal.salesPartner.company.registrationNumber' | translate"
                        [entity]="salesPartnerCompany" fieldName="registrationNumber"
                        [overwriteValueClassType]="OverwriteValueClassType.CompanyOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-select-input
                        [label]="'financing.features.financing-processing.formal.salesPartner.company.creditBroker' | translate"
                        [items]="creditBrokerSingleSelectItems"
                        [overwriteValueClassType]="OverwriteValueClassType.CompanyOverwriteValue"
                        [readonly]="fieldReadonly$" [entity]="salesPartnerCompany" fieldName="creditBroker">
                    </finprocess-select-input>

                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.formal.salesPartner.company.legalForm' | translate"
                        [entity]="salesPartnerCompany" fieldName="legalForm"
                        [overwriteValueClassType]="OverwriteValueClassType.CompanyOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <ng-container *ngIf="(salesPartnerCompanyAddress$ | async) as salesPartnerCompanyAddress">
                        <finprocess-textbox-input [label]="'financing.features.financing-processing.street' | translate"
                            [entity]="salesPartnerCompanyAddress" fieldName="street"
                            [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.streetNumber' | translate"
                            [entity]="salesPartnerCompanyAddress" fieldName="streetNumber"
                            [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input [label]="'financing.features.financing-processing.zip' | translate"
                            [entity]="salesPartnerCompanyAddress" fieldName="zip"
                            [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input [label]="'financing.features.financing-processing.city' | translate"
                            [entity]="salesPartnerCompanyAddress" fieldName="city"
                            [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                    </ng-container>

                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.formal.salesPartner.company.companyRegistrationNumber' | translate"
                        [entity]="salesPartnerCompany" fieldName="companyRegistrationNumber"
                        [overwriteValueClassType]="OverwriteValueClassType.CompanyOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.formal.salesPartner.company.companyRegistrationCourt' | translate"
                        [entity]="salesPartnerCompany" fieldName="companyRegistrationCourt"
                        [overwriteValueClassType]="OverwriteValueClassType.CompanyOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.formal.gisaNumber' | translate"
                        [entity]="salesPartnerCompany" fieldName="gisaNumber"
                        [overwriteValueClassType]="OverwriteValueClassType.CompanyOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.formal.salesPartner.company.authorizedRepresentatives' | translate"
                        [entity]="salesPartnerCompany" fieldName="authorizedRepresentatives"
                        [overwriteValueClassType]="OverwriteValueClassType.CompanyOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input
                        [label]="'financing.features.financing-processing.formal.salesPartner.company.website' | translate"
                        [entity]="salesPartnerCompany" fieldName="website"
                        [overwriteValueClassType]="OverwriteValueClassType.CompanyOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>

                    <finprocess-textbox-input [label]="'financing.features.financing-processing.email' | translate"
                        [entity]="salesPartnerCompany" fieldName="email"
                        [overwriteValueClassType]="OverwriteValueClassType.CompanyOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>
                </ng-container>
            </div>

        </ng-container>
    </div>
    <div right fxFill>
        <finprocess-document-viewer [documentFunction]="documents$"></finprocess-document-viewer>
    </div>
</finprocess-two-column-layout>
