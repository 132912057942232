/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { ArgumentError, COMFORTCREDIT_PLUS_ASSUMEDDURATION, CalculationHelperService, ComfortProductCalculationService, DebitorCalculationService, FinancingMapCalculationService, HouseholdCalculationService, LiabilityCalculationService, NewLiabilityCalculationService, ProductCalculationService, RealEstateCalculationService } from '@ntag-ef/finprocess-calculations';
import { Collateralization, MarketValueType, ObjectPurposeType, RealEstateType } from '@ntag-ef/finprocess-enums/finprocess';
import { IUserStateModel, UserState } from 'app/modules/auth/data';
import { IApprovalCompetence, ISystemConfigurationStateModel, SystemConfigurationState, SystemConfigurationType } from 'app/modules/masterdata/data';
import { IUser, Logout, Role } from 'auth';
import { sort } from 'fast-sort';
import { IBranch, IMasterdataStateModel, ISalesPartnerCenter, MasterdataState } from 'masterdata';
import { FinancingStatus } from 'shared/data';
import { HelperService, UUID } from 'shared/util';

import { EntityClassType, ValueStorageType } from '../../enums';
import { IDebitor, IDocument, IDocumentView, IFinancing, IFinprocessContainer, IHousehold, ILiability, INewLiability, IProductPackage, IProductPackageAccepted, IProductPackageData, IRealEstate, ISampleCalculationContainer } from '../../interfaces';
import { IRFPData } from '../../interfaces/riskfinancingplans-data.interface';
import { FinancingLeaved } from '../financing-tab/financing-tab.actions';

import { OverwriteHelperService } from './../../../util';
import {
    AcceptedProductPackageLoaded,
    AssignedApprover,
    CreditLineCalculationAddedOrUpdated,
    DebitorRatingUpdated,
    DebitorsRatingUpdated,
    DebtorDocumentUploaded,
    DeleteDebtorFile,
    FinProcessContainerLoaded,
    FinancingContainerIDLoaded,
    FinancingDocumentUploaded,
    FinancingLoaded,
    HouseholdDocumentUploaded,
    InternalFieldUpdated,
    OverwriteCreatedOrUpdated,
    OverwriteDeleted,
    ProductPackageStatusLoaded,
    ProductPackageStatusUpdated,
    ProductPackages,
    RiskFinancingPlanStatusLoaded,
    RiskFinancingPlansLoaded,
    SampleCalculationContainerLoaded,
    SampleCalculationContainerSaved,
    StatusLoaded,
    StatusUpdated,
    StatusUpdatedFinancingContainer,
    UpdateProductPackageDecision,
    UpdateProductPackages,
} from './financing.actions';


export interface IFinancingStateModel {
    financing?: IFinancing;
    financingContainerID?: string;
    finprocessContainer?: IFinprocessContainer;
    riskFinancingPlans: IRFPData[];
    sampleCalculationContainer?: ISampleCalculationContainer,
    productPackages?: IProductPackageData,
    acceptedProductPackage?: IProductPackageAccepted,
}

const defaultData: IFinancingStateModel = {
    riskFinancingPlans: [],
};

type KeyToKeyMap<T> = {
    [K in keyof T]: keyof T;
}

/**
 * Zustand der Finanzierung
 */
@State<IFinancingStateModel>({
    name: FinancingState.keyName,
    defaults: defaultData,
})
@Injectable()
export class FinancingState {
    /**
     * Speichert verschiedene Versionen der Berechnungsservices für Finanzierungsberechnungen
     */
    private static financingMapCalculationServices: { [key: number]: FinancingMapCalculationService } = {};

    /**
     * Speichert verschiedene Versionen der Berechnungsservices für den alten Produktrechner
     */
    private static productCalculationServices: { [key: number]: ProductCalculationService } = {};

    /**
     * Speichert verschiedene Versionen der Berechnungsservices für den Produktrechner
     */
    private static comfortProductCalculationServices: { [key: number]: ComfortProductCalculationService } = {};

    /**
     * Speichert verschiedene Versionen der Berechnungsservices für Haushalte
     */
    private static householdCalculationServices: { [key: number]: HouseholdCalculationService } = {};

    /**
     * Speichert verschiedene Versionen der Berechnungsservices für Neue Verpflichtungen
     */
    private static newLiabilityCalculationServices: { [key: number]: NewLiabilityCalculationService } = {};

    /**
     * Speichert verschiedene Versionen der Berechnungsservices für Bestehende Verpflichtungen
     */
    private static liabilityCalculationServices: { [key: number]: LiabilityCalculationService } = {};

    /**
     * Speichert verschiedene Versionen der Berechnungsservices für Kreditnehmer
     */
    private static debitorCalculationServices: { [key: number]: DebitorCalculationService } = {};

    /**
     * Speichert verschiedene Versionen der Berechnungsservices für ein Objekt
     */
    private static realEstateCalculationServices: { [key: number]: RealEstateCalculationService } = {};

    public static readonly keyName = 'financing';

    /**
     * Konstruktor
     *
     * @param {Store} store Store
     */
    public constructor(
        private store: Store) {
    }

    /**
     * Liefert die Filialen
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterdata Stammdaten
     * @returns {IBranch[]} Filialen
     */
    @Selector([MasterdataState])
    public static branches(state: IFinancingStateModel, masterdata: IMasterdataStateModel): IBranch[] {

        let branches = masterdata.branches.filter(it => !it.disabled);

        branches = branches.filter(it => it.responsibility === (masterdata.salesPartnerCenters.find(s => s.id === state.financing?.salesPartnerCenterId))?.responsibility)

        if (!branches.some(it => it.id === state.financing?.branchId)) {
            const branch = masterdata.branches.find(it => it.id === state.financing?.branchId);

            if (branch !== undefined) {
                branches.push(branch);
            }
        }

        return sort(branches).asc(it => it.name);
    }

    /**
     * Liefert die Vertriebspartnercenter
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterdata Stammdaten
     * @returns {ISalesPartnerCenter[]} Vertriebspartnercenter
     */
    @Selector([MasterdataState])
    public static salesPartnerCenter(state: IFinancingStateModel, masterdata: IMasterdataStateModel): ISalesPartnerCenter[] {

        let salesPartnerCenters = masterdata.salesPartnerCenters.filter(it => !it.disabled);

        salesPartnerCenters = salesPartnerCenters.filter(
            it => it.organisationalUnitId === (masterdata.salesPartnerCenters.find(s => s.id === state.financing?.salesPartnerCenterId))?.organisationalUnitId)

        if (!salesPartnerCenters.some(it => it.id === state.financing?.salesPartnerCenterId)) {
            const salesPartnerCenter = masterdata.salesPartnerCenters.find(it => it.id === state.financing?.salesPartnerCenterId);

            if (salesPartnerCenter !== undefined) {
                salesPartnerCenters.push(salesPartnerCenter);
            }
        }

        return sort(salesPartnerCenters).asc(it => it.name);
    }


    /**
     * Liefert, ob die Anfrage für den aktuellen Nutzer schreibgeschützt ist
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {boolean} Anfrage schreibgeschützt
     */
    @Selector()
    public static isEditingReadonly(state: IFinancingStateModel) {
        /**
         * Liefert, ob die Anfrage für den aktuellen Nutzer schreibgeschützt ist
         *
         * @param {IUser} user Aktueller Nutzer
         * @param {Role} role Rolle
         * @returns {boolean} Anfrage schreibgeschützt
         */
        return (user: IUser, role?: Role): boolean => {
            const commonStatuses = [
                FinancingStatus.SampleCalculationWaitingForAcception,
                FinancingStatus.EsisWaitingForAcception,
                FinancingStatus.Completed,
                FinancingStatus.Canceled,
                FinancingStatus.Rejected,
            ];

            // Check if the user is a referent or an expert - add additional statuses
            if (role === Role.Referent) {
                commonStatuses.push(...[
                    FinancingStatus.Open,
                    FinancingStatus.HouseholdCalculationAccepted,
                    FinancingStatus.HouseholdCalculationWaitingForAcception,
                ]);
            }

            if (role === Role.Approver) {
                commonStatuses.push(...[
                    FinancingStatus.Finalize,
                ]);
            }

            const hasLockedStatus = state.finprocessContainer?.status === undefined || commonStatuses.some(status => state.finprocessContainer?.status === status);
            const hasRequiredRole = Array.isArray(state.finprocessContainer?.users) && state.finprocessContainer?.users.some(usr => usr.id === user.id && (role === undefined || HelperService.hasBit(usr.roles, role)));
            const isTemporaryUser = state.finprocessContainer?.temporaryUser?.id === user.id;

            return hasLockedStatus || (!hasRequiredRole && !isTemporaryUser);
        };
    }

    /**
     * Liefert das Alter bei Laufzeitende
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(UUID, boolean) => number | undefined} Alter bei Laufzeitende
     */
    @Selector()
    public static ageDurationEnd(state: IFinancingStateModel): (debitorId: UUID, withOverwrites?: boolean) => number | undefined {
        /**
         * Liefert das Alter bei Laufzeitende
         *
         * @param {UUID} debitorId Id des Kreditnehmers
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number | undefined} Alter bei Laufzeitende
         */
        return (debitorId: UUID, withOverwrites = true): number | undefined => {
            if (state.financing !== undefined) {
                const debitor = state.financing.households.reduce((p: IDebitor[], cH: IHousehold) => p.concat(cH.debitors), []).find(it => it.id === debitorId);
                if (debitor !== undefined) {
                    const plannedFinancingStart = OverwriteHelperService.getMergedOverwriteValue(state.financing, 'PlannedFinancingStart', state.financing.plannedFinancingStart, withOverwrites);
                    const plannedFinancingEnd = OverwriteHelperService.getMergedOverwriteValue(state.financing, 'PlannedFinancingEnd', state.financing.plannedFinancingEnd, withOverwrites);
                    return FinancingState.debitorCalculationService(state.financing.calculationVersion).ageDurationEnd({
                        birthday: new Date(OverwriteHelperService.getMergedOverwriteValue(debitor, 'Birthday', debitor.birthday, withOverwrites)),
                        assumedDuration: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'AssumedDuration', state.financing.assumedDuration, withOverwrites),
                        plannedFinancingStart: plannedFinancingStart !== undefined ? new Date(plannedFinancingStart) : undefined,
                        plannedFinancingEnd: plannedFinancingEnd !== undefined ? new Date(plannedFinancingEnd) : undefined,
                    });
                }
            }
            return undefined;
        };
    }

    /**
     * Liefert den Kreditnehmer mit dem höchsten Einkommen (ohne Overwrites)
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {IDebitor | undefined} Kreditnehmer mit dem höchsten Einkommen
     */
    @Selector()
    public static mainDebitor(state: IFinancingStateModel): IDebitor | undefined {
        if (state.financing !== undefined) {
            const debitors = state.financing.households.reduce((pA: IDebitor[], cH: IHousehold) => pA.concat(cH.debitors), []);
            if (debitors.length > 0) {
                return sort(debitors).desc(d => FinancingState.debitorCalculationService((state.financing as IFinancing).calculationVersion).netIncomeTotal({ netIncome: d.netIncome, fourteenSalariesPerYear: d.fourteenSalariesPerYear, otherIncome: d.otherIncome }))[0];
            }
        }
        return undefined;
    }

    /**
     * Gibt eine Liste aller Kreditnehmer zurück
     * 
     * @param {IFinancingStateModel} state Zustand
     * @returns {IDebitor[]} Liste der Kreditnehmer
     */
    @Selector()
    public static debitors(state: IFinancingStateModel): IDebitor[] {
        if (state.financing !== undefined) {
            return state.financing.households.reduce((pA: IDebitor[], cH: IHousehold) => pA.concat(cH.debitors), []);
        }

        return [];
    }

    /**
     * Liefert das zu finanzierende Objekt
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {IRealEstate | undefined} Zu finanzierendes Objekt
     */
    @Selector()
    public static mainRealEstate(state: IFinancingStateModel): IRealEstate | undefined {
        if (state.financing !== undefined) {
            return state.financing.realEstates.find(it => it.objectPurpose === ObjectPurposeType.Finance);
        }
        return undefined;
    }

    /**
     * Liefert die Summe der gemeinsamen Einnahmen
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(UUID, boolean) => number} Summe der gemeinsamen Einnahmen
     */
    @Selector()
    public static incomePerHousehold(state: IFinancingStateModel): (householdId: UUID, withOverwrites?: boolean) => number {
        /**
         * Liefert die Summe der gemeinsamen Einnahmen
         *
         * @param {UUID} householdId Id des Haushalts
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Summe der gemeinsamen Einnahmen
         */
        return (householdId: UUID, withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const household = state.financing.households.find(it => it.id === householdId);
                if (household !== undefined) {
                    return household.debitors.reduce((pv, cd) => pv + FinancingState.debitorCalculationService((state.financing as IFinancing).calculationVersion).netIncomeTotal({
                        netIncome: OverwriteHelperService.getMergedOverwriteValue(cd, 'NetIncome', cd.netIncome, withOverwrites),
                        fourteenSalariesPerYear: OverwriteHelperService.getMergedOverwriteValue(cd, 'FourteenSalariesPerYear', cd.fourteenSalariesPerYear, withOverwrites),
                        otherIncome: OverwriteHelperService.getMergedOverwriteValue(cd, 'OtherIncome', cd.otherIncome, withOverwrites),
                    }), 0);
                }
            }
            return 0;
        };
    }

    /**
     * Liefert die Summe der Haushaltsausgaben
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(UUID, boolean) => number} Summe der Haushaltsausgaben
     */
    @Selector()
    public static costsPerHousehold(state: IFinancingStateModel): (householdId: UUID, withOverwrites?: boolean) => number {
        /**
         * Liefert die Summe der Haushaltsausgaben
         *
         * @param {UUID} householdId Id des Haushalts
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Summe der Haushaltsausgaben
         */
        return (householdId: UUID, withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const household = state.financing.households.find(it => it.id === householdId);
                if (household !== undefined) {
                    return FinancingState.householdCalculationService((state.financing as IFinancing).calculationVersion).sumCostsPerMonth({
                        operationalCosts: OverwriteHelperService.getMergedOverwriteValue(household, 'OperationalCosts', household.operationalCosts, withOverwrites),
                        electricityCosts: OverwriteHelperService.getMergedOverwriteValue(household, 'ElectricityCosts', household.electricityCosts, withOverwrites),
                        phoneCosts: OverwriteHelperService.getMergedOverwriteValue(household, 'PhoneCosts', household.phoneCosts, withOverwrites),
                        broadcastCosts: OverwriteHelperService.getMergedOverwriteValue(household, 'BroadcastCosts', household.broadcastCosts, withOverwrites),
                        insuranceCosts: OverwriteHelperService.getMergedOverwriteValue(household, 'InsuranceCosts', household.insuranceCosts, withOverwrites),
                        livingCosts: OverwriteHelperService.getMergedOverwriteValue(household, 'LivingCosts', household.livingCosts, withOverwrites),
                        carCosts: OverwriteHelperService.getMergedOverwriteValue(household, 'CarCosts', household.carCosts, withOverwrites),
                    });
                }
            }
            return 0;
        };
    }

    /**
     * Liefert das Verfügbare Einkommen
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(UUID, boolean) => number} Verfügbares Einkommen
     */
    @Selector()
    public static remainingPerHousehold(state: IFinancingStateModel): (householdId: UUID, withOverwrites?: boolean) => number {
        /**
         * Liefert das Verfügbare Einkommen
         *
         * @param {UUID} householdId Id des Haushalts
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Verfügbares Einkommen
         */
        return (householdId: UUID, withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const household = state.financing.households.find(it => it.id === householdId);
                if (household !== undefined) {
                    return FinancingState.householdCalculationService((state.financing as IFinancing).calculationVersion).remainingPerMonth({
                        netIncomeTotal: FinancingState.incomePerHousehold(state)(householdId, withOverwrites),
                        sumCostsPerMonth: FinancingState.costsPerHousehold(state)(householdId, withOverwrites),
                    });
                }
            }
            return 0;
        };
    }

    /**
     *  berechnet Hochrechnung auf 12 Monate
     * 
     * @param {IFinancingStateModel} state Zustand
     * @returns {(UUID, UUID) => number} Hochrechnung
     */
    @Selector()
    public static calculateSustainableMonthlyNetIncome(state: IFinancingStateModel): (householdId: UUID, debtorId: UUID) => number {
        /**
         * berechnet Hochrechnung auf 12 Monate
         *
         * @param {UUID} householdId Id des Haushalts
         * @param {UUID} debtorId Debtor Id
         * @returns {number} Hochrechnung
         */
        return (householdId: UUID, debtorId: UUID): number => {
            if (state.financing !== undefined) {
                const debtor = state.financing.households.find(it => it.id === householdId)?.debitors.find(deb => deb.id === debtorId);
                if (!!debtor) {
                    const sustainableIncome = debtor.sustainableNetIncome ?? 0;

                    if (debtor.sustainableFourteenSalariesPerYear === true) {
                        return (sustainableIncome * 14) / 12;
                    }

                    return sustainableIncome
                }
            }
            return 0;
        }
    }

    /**
     *  Berechnet die Summe der Mieteinnahmen
     * 
     * @param {IFinancingStateModel} state Zustand
     * @returns {(UUID, UUID) => number} Hochrechnung
     */
    @Selector()
    public static calculateSustainableTotalRentalIncome(state: IFinancingStateModel): (householdId: UUID, debtorId: UUID) => number {
        /**
         * Sustainable Total Rental Income Berechnung
         *
         * @param {UUID} householdId Id des Haushalts
         * @param {UUID} debtorId Debtor Id
         * @returns {number} Hochrechnung
         */
        return (householdId: UUID, debtorId: UUID): number => {
            if (state.financing !== undefined) {
                const debtor = state.financing.households.find(it => it.id === householdId)?.debitors.find(deb => deb.id === debtorId);
                if (!!debtor) {
                    return (debtor.sustainableRentalIncome ?? 0) + (debtor.sustainableExistingRentalIncome ?? 0);
                }
            }
            return 0;
        }
    }


    /**
     *  berechnet die Summe nachhaltige monatlichen Einnahme
     * 
     * @param {IFinancingStateModel} state Zustand
     * @returns {(UUID, UUID) => number} Summe
     */
    @Selector()
    public static calculateSustainableTotalIncome(state: IFinancingStateModel): (householdId: UUID, debtorId: UUID) => number {
        /**
         * berechnet die Summe nachhaltige monatlichen Einnahme
         *
         * @param {UUID} householdId Id des Haushalts
         * @param {UUID} debtorId Debtor Id
         * @returns {number} Summe
         */
        return (householdId: UUID, debtorId: UUID): number => {
            if (state.financing !== undefined) {
                const debtor = state.financing.households.find(it => it.id === householdId)?.debitors.find(deb => deb.id === debtorId);
                if (!!debtor) {
                    return this.calculateSustainableMonthlyNetIncome(state)(householdId, debtorId) + (debtor.sustainableOtherIncome ?? 0) + (debtor.sustainableRentalIncome ?? 0) + (debtor.sustainableIndependentIncome ?? 0) + (debtor.sustainableExistingRentalIncome ?? 0);
                }
            }
            return 0;
        };
    }

    /**
     * Liefert den wirtschaftlich führenden Kreditnehmer
     * 
     * @param {IFinancingStateModel} state Zustand
     * @returns {IDebitor | undefined} Wirtschaftlich führender Kreditnehmer
     */
    @Selector()
    public static economicLeader(state: IFinancingStateModel): IDebitor | undefined {
        if (state.financing !== undefined) {
            const debitors = this.sortedEconomicDebitors(state)(true);

            // Wenn noch kein nachhaltiges Einkommen angegeben wurde, erstmal nichts anzeigen
            if (debitors.length === 0 || debitors.every(deb => deb.sustainableNetIncome === 0)) {
                return undefined;
            }

            return debitors[0];
        }
        return undefined;
    }

    /**
     * Liefert eine sortierte Liste der Kreditnehmer (economic Leader)
     * 
     * @param {IFinancingStateModel} state Zustand
     * @returns {IDebitor[]} Sortierte Liste der Kreditnehmer
     */
    @Selector()
    public static sortedEconomicDebitors(state: IFinancingStateModel): (withOverwrites?: boolean) => IDebitor[] {
        return (withOverwrites = true): IDebitor[] => {
            if (state.financing !== undefined) {
                const households = state.financing.households;
                const debitors = households.reduce((debs, currentHousehold) => {
                    const householdPosition = currentHousehold.position;
                    const householdDebitors = currentHousehold.debitors.map(deb => ({
                        sustainableIncome: this.calculateSustainableTotalIncome(state)(currentHousehold.id, deb.id),
                        ownEmployee: deb.isOwnEmployee,
                        debitor: {
                            ...deb,
                            firstName: OverwriteHelperService.getMergedOverwriteValue(deb, 'firstName', deb.firstName, withOverwrites),
                            lastName: OverwriteHelperService.getMergedOverwriteValue(deb, 'lastName', deb.lastName, withOverwrites),
                            customerNumber: OverwriteHelperService.getMergedOverwriteValue(deb, 'customerNumber', deb.customerNumber, withOverwrites),
                        },
                        householdPosition: householdPosition,
                    }));
                    return debs.concat(householdDebitors);
                }, [] as Array<{ sustainableIncome: number; ownEmployee?: boolean; debitor: IDebitor; householdPosition: number }>);
         
                return sort(debitors).by([
                    { desc: deb => deb.ownEmployee },
                    { desc: deb => deb.sustainableIncome },
                    { asc: deb => deb.householdPosition },
                    { asc: deb => deb.debitor.position },
                ]).map(deb => deb.debitor);
            }
            return [];
        }
    }


    /**
     * Liefert die Summe der gemeinsamen Einnahmen für alle Haushalte
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Summe der gemeinsamen Einnahmen für alle Haushalte
     */
    @Selector()
    public static incomeTotal(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Summe der gemeinsamen Einnahmen für alle Haushalte
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Summe der gemeinsamen Einnahmen für alle Haushalte
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return state.financing.households.reduce((pv, cH) => pv + FinancingState.incomePerHousehold(state)(cH.id, withOverwrites), 0);
            }
            return 0;
        };
    }

    /**
     * Liefert die Summe der Haushaltsausgaben für alle Haushalte
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Summe der Haushaltsausgaben für alle Haushalte
     */
    @Selector()
    public static costsTotal(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Summe der Haushaltsausgaben für alle Haushalte
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Summe der Haushaltsausgaben für alle Haushalte
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return state.financing.households.reduce((pv, cH) => pv + FinancingState.costsPerHousehold(state)(cH.id, withOverwrites), 0);
            }
            return 0;
        };
    }

    /**
     * Liefert das Verfügbare Einkommen für alle Haushalte
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Verfügbares Einkommen für alle Haushalte
     */
    @Selector()
    public static remainingTotal(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert das Verfügbare Einkommen für alle Haushalte
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Verfügbares Einkommen für alle Haushalte
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return state.financing.households.reduce((pv, cH) => pv + FinancingState.remainingPerHousehold(state)(cH.id, withOverwrites), 0);
            }
            return 0;
        };
    }

    /**
     * Liefert die Sicherheitsreserve
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Sicherheitsreserve
     */
    @Selector()
    public static reserve(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Sicherheitsreserve
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Sicherheitsreserve
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.householdCalculationService(state.financing.calculationVersion).reserve({
                    remainingPerMonth: FinancingState.remainingTotal(state)(withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert das Frei Verfügbare Einkommen
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Frei Verfügbares Einkommen
     */
    @Selector()
    public static freeAccessibleIncome(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert das Frei Verfügbare Einkommen
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Frei Verfügbares Einkommen
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.householdCalculationService(state.financing.calculationVersion).freeAccessibleIncome({
                    remainingPerMonth: FinancingState.remainingTotal(state)(withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert die Alimente für alle Haushalte
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Alimente für alle Haushalte
     */
    @Selector()
    public static otherCostsTotal(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Alimente für alle Haushalte
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Alimente für alle Haushalte
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return state.financing.households.reduce((pv, cH) => pv + (OverwriteHelperService.getMergedOverwriteValue(cH, 'OtherCosts', cH.otherCosts, withOverwrites) ?? 0), 0);
            }
            return 0;
        };
    }

    /**
     * Liefert Neue Raten Förderung/Bauspardarlehen
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Neue Raten Förderung/Bauspardarlehen
     */
    @Selector()
    public static newMonthlyRates(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Neue Raten Förderung/Bauspardarlehen
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Neue Raten Förderung/Bauspardarlehen
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return state.financing.households.reduce((pv, cH) => pv + cH.newLiabilities.reduce((pnlv, cNL) => pnlv + CalculationHelperService.handleDivisionByZeroError(() => FinancingState.newLiabilityCalculationService((state.financing as IFinancing).calculationVersion).monthlyRateSecuredByLandRegister({
                    securedByLandRegister: OverwriteHelperService.getMergedOverwriteValue(cNL, 'SecuredByLandRegister', cNL.securedByLandRegister, withOverwrites),
                    monthlyRate: CalculationHelperService.handleDivisionByZeroError(() => FinancingState.newLiabilityCalculationService((state.financing as IFinancing).calculationVersion).monthlyRateCalculated({
                        monthlyRate: OverwriteHelperService.getMergedOverwriteValue(cNL, 'MonthlyRate', cNL.monthlyRate, withOverwrites),
                        amount: OverwriteHelperService.getMergedOverwriteValue(cNL, 'Amount', cNL.amount, withOverwrites),
                        loanPeriodInMonths: OverwriteHelperService.getMergedOverwriteValue(cNL, 'LoanPeriodInMonths', cNL.loanPeriodInMonths, withOverwrites),
                    }), 0),
                }), 0), 0), 0);
            }
            return 0;
        };
    }

    /**
     * Liefert Bestehend bleibende Kredit-/Leasingraten
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Bestehend bleibende Kredit-/Leasingraten
     */
    @Selector()
    public static monthlyRateNotCoveredLiabilities(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Bestehend bleibende Kredit-/Leasingraten
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Bestehend bleibende Kredit-/Leasingraten
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return state.financing.households.reduce((pv, cH) => pv + cH.liabilities.reduce((plv, cL) => plv + FinancingState.liabilityCalculationService((state.financing as IFinancing).calculationVersion).monthlyRateNotCovered({
                    monthlyRate: OverwriteHelperService.getMergedOverwriteValue(cL, 'MonthlyRate', cL.monthlyRate, withOverwrites),
                    covered: OverwriteHelperService.getMergedOverwriteValue(cL, 'Covered', cL.covered, withOverwrites),
                }), 0), 0);
            }
            return 0;
        };
    }

    /**
     * Liefert die Zumutbare Kreditrate
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Zumutbare Kreditrate
     */
    @Selector()
    public static acceptableCreditRate(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Zumutbare Kreditrate
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Zumutbare Kreditrate
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).acceptableCreditRate({
                    freeAccessibleIncome: FinancingState.freeAccessibleIncome(state)(withOverwrites),
                    newMonthlyRates: FinancingState.newMonthlyRates(state)(withOverwrites),
                    monthlyRateNotCoveredLiabilities: FinancingState.monthlyRateNotCoveredLiabilities(state)(withOverwrites),
                    otherCosts: FinancingState.otherCostsTotal(state)(withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert die Legalisierungsgebühr
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Legalisierungsgebühr
     */
    @Selector([MasterdataState])
    public static legalisationFee(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Legalisierungsgebühr
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Legalisierungsgebühr
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined && Array.isArray(masterData.legalisationFeeBases) && masterData.legalisationFeeBases.length > 0) {
                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).legalisationFee({
                    legalisationFeeBases: masterData.legalisationFeeBases,
                    netFinancingRequirementNegativeValuesAllowed: FinancingState.netFinancingRequirement(state)(withOverwrites),
                    ...FinancingState.getSumFinancingAdditionalChargesParams(state, withOverwrites),
                    landRegisterRequest: state.financing.financingConfiguration.landRegisterRequest,
                    landRegisterExtract: state.financing.financingConfiguration.landRegisterExtract,
                });
            }
            return 0;
        };
    }

    /**
     * Liefert den Langfristigen Finanzierungsbedarf Netto
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {boolean} negativeValuesAllowed Negative Werte erlaubt
     * @returns {(boolean) => number} Langfristiger Finanzierungsbedarf Netto
     */
    @Selector()
    public static netFinancingRequirement(state: IFinancingStateModel, negativeValuesAllowed = true): (withOverwrites?: boolean) => number {
        /**
         * Liefert den Langfristigen Finanzierungsbedarf Netto
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Langfristiger Finanzierungsbedarf Netto
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).netFinancingRequirement({
                    sumProjectCosts: FinancingState.sumProjectCosts(state)(withOverwrites),
                    sumOwnCapital: FinancingState.sumOwnCapital(state)(withOverwrites),
                    funding: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'Funding', state.financing.funding, withOverwrites),
                    otherOwnCapital: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'OtherOwnCapital', state.financing.otherOwnCapital, withOverwrites),
                    negativeValuesForbidden: !negativeValuesAllowed,
                });
            }
            return 0;
        };
    }

    /**
     * Liefert die Projektkosten
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Projektkosten
     */
    @Selector()
    public static sumProjectCosts(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Projektkosten
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Projektkosten
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const realEstate = FinancingState.mainRealEstate(state);
                if (realEstate !== undefined) {
                    return FinancingState.realEstateCalculationService(state.financing.calculationVersion).sumProjectCosts({
                        creditPurpose: OverwriteHelperService.getMergedOverwriteValue(realEstate, 'CreditPurpose', realEstate.creditPurpose, withOverwrites),
                        currentAmountCoveredLiabilites: FinancingState.currentAmountCoveredLiabilites(state)(withOverwrites),
                        sumAdditionalCosts: FinancingState.sumAdditionalCosts(state)(withOverwrites),
                        purchasePrice: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'PurchasePrice', state.financing.purchasePrice, withOverwrites),
                        lotPrice: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'LotPrice', state.financing.lotPrice, withOverwrites),
                        developmentCosts: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'DevelopmentCosts', state.financing.developmentCosts, withOverwrites),
                        constructionCosts: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ConstructionCosts', state.financing.constructionCosts, withOverwrites),
                        refurbishmentCosts: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RefurbishmentCosts', state.financing.refurbishmentCosts, withOverwrites),
                        constructionCostsOverrun: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ConstructionCostsOverrun', state.financing.constructionCostsOverrun, withOverwrites),
                        otherCosts: OverwriteHelperService.getMergedOverwriteValue(realEstate, 'OtherCosts', realEstate.otherCosts, withOverwrites),
                        reschedulingAmount: OverwriteHelperService.getMergedOverwriteValue(realEstate, 'ReschedulingAmount', realEstate.reschedulingAmount, withOverwrites),
                    });
                }
            }
            return 0;
        };
    }

    /**
     * Liefert die Summe der Eigenmittel
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Summe der Eigenmittel als Funktion
     */
    @Selector()
    public static sumOwnCapital(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Summe der Eigenmittel
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Summe der Eigenmittel
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).sumOwnCapital({
                    cash: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'Cash', state.financing.cash, withOverwrites),
                    salesRevenue: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'SalesRevenue', FinancingState.financingMapCalculationService(state.financing.calculationVersion).salesRevenue({
                        reducedSalesRevenue: state.financing.salesRevenue,
                        realEstates: state.financing.realEstates.map(it => ({
                            marketValue: OverwriteHelperService.getMergedOverwriteValue(it, 'MarketValue', it.marketValue, withOverwrites),
                            objectPurpose: OverwriteHelperService.getMergedOverwriteValue(it, 'ObjectPurpose', it.objectPurpose, withOverwrites),
                            collateralization: OverwriteHelperService.getMergedOverwriteValue(it, 'Collateralization', it.collateralization, withOverwrites),
                        })),
                    }), withOverwrites),
                    redemptionInsurance: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RedemptionInsurance', state.financing.redemptionInsurance, withOverwrites),
                    bausparCreditBalance: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'BausparCreditBalance', state.financing.bausparCreditBalance, withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert die Summe der Eigenmittel, die sofort verfügbar sind.
     * Sofort verfügbar bedeutet, dass diese nicht vorfinanziert sind.
     * 
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Summe der Eigenmittel, die sofort verfügbar sind
     */
    @Selector()
    public static sumOwnCapitalImmediatelyAvailable(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Summe der Eigenmittel, die sofort verfügbar sind
         * 
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Summe der Eigenmittel, die sofort verfügbar sind
         */
        return (withOverwrites: boolean = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).sumOwnCapitalImmediatelyAvailable({
                    cash: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'cash', state.financing.cash, withOverwrites),
                    salesRevenue: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'salesRevenue', FinancingState.financingMapCalculationService(state.financing.calculationVersion).salesRevenue({
                        reducedSalesRevenue: state.financing.salesRevenue,
                        realEstates: state.financing.realEstates.map(it => ({
                            marketValue: OverwriteHelperService.getMergedOverwriteValue(it, 'marketValue', it.marketValue, withOverwrites),
                            objectPurpose: OverwriteHelperService.getMergedOverwriteValue(it, 'objectPurpose', it.objectPurpose, withOverwrites),
                            collateralization: OverwriteHelperService.getMergedOverwriteValue(it, 'collateralization', it.collateralization, withOverwrites),
                        })),
                    }), withOverwrites),
                    prefinancingSales: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'prefinancingSales', state.financing.prefinancingSales, withOverwrites),
                    redemptionInsurance: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'redemptionInsurance', state.financing.redemptionInsurance, withOverwrites),
                    prefinancingInsurance: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'prefinancingInsurance', state.financing.prefinancingInsurance, withOverwrites),
                    bausparCreditBalance: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'bausparCreditBalance', state.financing.bausparCreditBalance, withOverwrites),
                    prefinancingBausparCreditBalance: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'prefinancingBausparCreditBalance', state.financing.prefinancingBausparCreditBalance, withOverwrites),
                    funding: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'funding', state.financing.funding, withOverwrites),
                    prefinancingFunding: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'prefinancingFunding', state.financing.prefinancingFunding, withOverwrites),
                    otherOwnCapital: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'otherOwnCapital', state.financing.otherOwnCapital, withOverwrites),
                    prefinancingOtherOwnCapital: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'prefinancingOtherOwnCapital', state.financing.prefinancingOtherOwnCapital, withOverwrites),
                });
            }

            return 0;
        }
    }

    /**
     * Liefert den angepassten Verkaufserlös. Der vom Nutzer eingegebene Verkaufserlös darf nicht höher als die Summe der Marktwerte der zum Verkauf stehenden Immobilien sein.
     * 
     * @param {IFinancingStateModel} state Zustand
     * @returns {number} Angepasster Verkaufserlös
     */
    @Selector()
    public static salesRevenue(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        return (withOverwrites: boolean = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).salesRevenue({
                    reducedSalesRevenue: state.financing.salesRevenue,
                    realEstates: state.financing.realEstates.map(it => ({
                        marketValue: OverwriteHelperService.getMergedOverwriteValue(it, 'MarketValue', it.marketValue, withOverwrites),
                        objectPurpose: OverwriteHelperService.getMergedOverwriteValue(it, 'ObjectPurpose', it.objectPurpose, withOverwrites),
                        collateralization: OverwriteHelperService.getMergedOverwriteValue(it, 'Collateralization', it.collateralization, withOverwrites),
                    })),
                }) ?? 0;
            }
            return 0;
        };
    }

    /**
     * Liefert die Abdeckung Vorkredit
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Abdeckung Vorkredit
     */
    @Selector()
    public static currentAmountCoveredLiabilites(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Abdeckung Vorkredit
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Abdeckung Vorkredit
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return state.financing.households.reduce((pv, cH) => pv + cH.liabilities.reduce((plv, cL) => plv + FinancingState.liabilityCalculationService((state.financing as IFinancing).calculationVersion).currentAmountCovered({
                    currentAmount: OverwriteHelperService.getMergedOverwriteValue(cL, 'CurrentAmount', cL.currentAmount, withOverwrites),
                    covered: OverwriteHelperService.getMergedOverwriteValue(cL, 'Covered', cL.covered, withOverwrites),
                }), 0), 0);
            }
            return 0;
        };
    }

    /**
     * Liefert die Kaufnebenkosten
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Kaufnebenkosten
     */
    @Selector()
    public static sumAdditionalCosts(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Kaufnebenkosten
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Kaufnebenkosten
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const realEstate = FinancingState.mainRealEstate(state);
                if (realEstate !== undefined) {
                    return FinancingState.realEstateCalculationService(state.financing.calculationVersion).sumAdditionalCosts({
                        realEstateTaxes: FinancingState.realEstateTaxes(state)(withOverwrites),
                        landregisterEntry: FinancingState.landregisterEntry(state)(withOverwrites),
                        notaryCosts: FinancingState.notaryCosts(state)(withOverwrites),
                        brokerageCosts: FinancingState.brokerageCosts(state)(withOverwrites),
                        otherAdditionalCosts: OverwriteHelperService.getMergedOverwriteValue(realEstate, 'OtherAdditionalCosts', realEstate.otherAdditionalCosts, withOverwrites),
                    });
                }
            }
            return 0;
        };
    }

    /**
     * Liefert die Eintragung Eigentumsrecht
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Eintragung Eigentumsrecht
     */
    @Selector()
    public static landregisterEntry(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Eintragung Eigentumsrecht
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Eintragung Eigentumsrecht
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const realEstate = FinancingState.mainRealEstate(state);
                if (realEstate !== undefined) {
                    return FinancingState.realEstateCalculationService(state.financing.calculationVersion).landregisterEntry({
                        sumPricesRelevantForAdditionalCosts: FinancingState.sumPricesRelevantForAdditionalCosts(state, withOverwrites),
                        landregisterEntryAmount: state.financing.landregisterEntryFeeAmount !== undefined &&
                            state.financing.landregisterEntryFeeAmount > 0 &&
                            (!withOverwrites || state.financing.landregisterEntryFee === undefined || state.financing.landregisterEntryFee <= 0) &&
                            OverwriteHelperService.getOverwriteFromEntity(state.financing, 'LandregisterEntryFee') === undefined ? state.financing.landregisterEntryFeeAmount : undefined,
                        landregisterEntryFee: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'LandregisterEntryFee', state.financing.landregisterEntryFee, withOverwrites),
                    });
                }
            }
            return 0;
        };
    }

    /**
     * Liefert die Grunderwerbssteuer
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Grunderwerbssteuer
     */
    @Selector()
    public static realEstateTaxes(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Grunderwerbssteuer
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Grunderwerbssteuer
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const realEstate = FinancingState.mainRealEstate(state);
                if (realEstate !== undefined) {
                    return FinancingState.realEstateCalculationService(state.financing.calculationVersion).realEstateTaxes({
                        sumPricesRelevantForAdditionalCosts: FinancingState.sumPricesRelevantForAdditionalCosts(state, withOverwrites),
                        realEstateTaxesAmount: state.financing.realEstatePurchaseTaxesAmount !== undefined &&
                            state.financing.realEstatePurchaseTaxesAmount > 0 &&
                            (!withOverwrites || state.financing.realEstatePurchaseTaxes === undefined || state.financing.realEstatePurchaseTaxes <= 0) &&
                            OverwriteHelperService.getOverwriteFromEntity(state.financing, 'RealEstatePurchaseTaxes') === undefined ? state.financing.realEstatePurchaseTaxesAmount : undefined,
                        realEstateTaxesFee: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RealEstatePurchaseTaxes', state.financing.realEstatePurchaseTaxes, withOverwrites),
                    });
                }
            }
            return 0;
        };
    }

    /**
     * Liefert die Errichtung Kaufvertrag/Treuhand
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Errichtung Kaufvertrag/Treuhand
     */
    @Selector()
    public static notaryCosts(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Errichtung Kaufvertrag/Treuhand
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Errichtung Kaufvertrag/Treuhand
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const realEstate = FinancingState.mainRealEstate(state);
                if (realEstate !== undefined) {
                    return FinancingState.realEstateCalculationService(state.financing.calculationVersion).notaryCosts({
                        sumPricesRelevantForAdditionalCosts: FinancingState.sumPricesRelevantForAdditionalCosts(state, withOverwrites),
                        notaryCostsAmount: state.financing.notaryFeeAmount !== undefined &&
                            state.financing.notaryFeeAmount > 0 &&
                            (!withOverwrites || state.financing.notaryFee === undefined || state.financing.notaryFee <= 0) &&
                            OverwriteHelperService.getOverwriteFromEntity(state.financing, 'NotaryFee') === undefined ? state.financing.notaryFeeAmount : undefined,
                        notaryCostsFee: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'NotaryFee', state.financing.notaryFee, withOverwrites),
                    });
                }
            }
            return 0;
        };
    }

    /**
     * Liefert die Maklergebühr
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Maklergebühr
     */
    @Selector()
    public static brokerageCosts(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Maklergebühr
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Maklergebühr
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const realEstate = FinancingState.mainRealEstate(state);
                if (realEstate !== undefined) {
                    return FinancingState.realEstateCalculationService(state.financing.calculationVersion).brokerageCosts({
                        sumPricesRelevantForAdditionalCosts: FinancingState.sumPricesRelevantForAdditionalCosts(state, withOverwrites),
                        brokerageCostsAmount: state.financing.brokerageFeeAmount !== undefined &&
                            state.financing.brokerageFeeAmount > 0 &&
                            (!withOverwrites || state.financing.brokerageFee === undefined || state.financing.brokerageFee <= 0) &&
                            OverwriteHelperService.getOverwriteFromEntity(state.financing, 'BrokerageFee') === undefined ? state.financing.brokerageFeeAmount : undefined,
                        brokerageCostsFee: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'BrokerageFee', state.financing.brokerageFee, withOverwrites),
                    });
                }
            }
            return 0;
        };
    }

    /**
     * Liefert den Langfristigen Finanzierungsbedarf Brutto
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Langfristiger Finanzierungsbedarf Brutto
     */
    @Selector([MasterdataState])
    public static grossFinancingRequirement(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert den Langfristigen Finanzierungsbedarf Brutto
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Langfristiger Finanzierungsbedarf Brutto
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).grossFinancingRequirement({
                    netFinancingRequirementNegativeValuesAllowed: FinancingState.netFinancingRequirement(state)(withOverwrites),
                    legalisationFee: FinancingState.legalisationFee(state, masterData)(withOverwrites),
                    ...FinancingState.getSumFinancingAdditionalChargesParams(state, withOverwrites),
                    landRegisterRequest: state.financing.financingConfiguration.landRegisterRequest,
                    landRegisterExtract: state.financing.financingConfiguration.landRegisterExtract,
                });
            }
            return 0;
        };
    }

    /**
     * Liefert den Langfristigen Finanzierungsbedarf Brutto mit additional values
     * 
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Langfristiger Finanzierungsbedarf Brutto mit addtional values
     */
    @Selector([MasterdataState])
    public static newCalculatedgrossFinancingRequirement(
        state: IFinancingStateModel,
        masterData: IMasterdataStateModel,
    ): (withOverwrites?: boolean) => number {
        /**
         * Liefert den Langfristigen Finanzierungsbedarf Brutto - neu berechnet
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Langfristiger Finanzierungsbedarf Brutto
         */
        return (withOverwrites = true): number => {
            if (state.financing === undefined) {
                return 0;
            }

            const oldGrossFinancingRequirement = this.grossFinancingRequirement(state, masterData)(withOverwrites);

            // Optional values based on checkboxes
            const checkboxValues: Partial<KeyToKeyMap<IFinancing>> = {
                salesRevenue: 'prefinancingSales',
                redemptionInsurance: 'prefinancingInsurance',
                bausparCreditBalance: 'prefinancingBausparCreditBalance',
                funding: 'prefinancingFunding',
                otherOwnCapital: 'prefinancingOtherOwnCapital',
            };

            // Calculate updated values based on checkboxes
            if (!!state.financing) {
                const updatedValues: Record<string, number | undefined> = {};
                Object.entries(checkboxValues).forEach(([key, checkbox]) => {
                    if (state.financing && OverwriteHelperService.getMergedOverwriteValue(state.financing, checkbox, state.financing[checkbox]) === true) {
                        const value = state.financing && OverwriteHelperService.getMergedOverwriteValue(state.financing, key, state.financing[key as keyof IFinancing]);
                        if (typeof value === 'number') {
                            updatedValues[key] = value;
                        }
                    }
                });

                // Calculate and return the result
                if (oldGrossFinancingRequirement) {
                    const sumOfValues = Object.values(updatedValues)
                        .map(value => (typeof value === 'number' ? value : 0)) // Map undefined values to 0
                        .reduce((sum, value) => sum + value, 0);
                    return oldGrossFinancingRequirement + sumOfValues;
                }
            }

            return 0;
        };
    }



    /**
     * Liefert die Finanzierungsnebenkosten
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Finanzierungsnebenkosten
     */
    @Selector([MasterdataState])
    public static sumFinancingAdditionalCharges(state: IFinancingStateModel, masterData: IMasterdataStateModel) {
        /**
         * Liefert die Finanzierungsnebenkosten
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Finanzierungsnebenkosten
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).sumFinancingAdditionalCharges({
                    processingCharges: FinancingState.processingCharges(state, masterData)(withOverwrites),
                    registrationCharges: FinancingState.registrationCharges(state, masterData)(withOverwrites),
                    estimateCharges: FinancingState.estimateCharges(state, masterData)(withOverwrites),
                    legalisationFee: FinancingState.legalisationFee(state, masterData)(withOverwrites),
                    landRegisterRequest: state.financing.financingConfiguration.landRegisterRequest,
                    landRegisterExtract: state.financing.financingConfiguration.landRegisterExtract,
                    valuationFeeExternal: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'valuationFeeExternal', state.financing.valuationFeeExternal, withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert den Kurzfristigen Finanzierungsbedarf
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Kurzfristiger Finanzierungsbedarf
     */
    @Selector()
    public static sumPrefinancing(state: IFinancingStateModel) {
        /**
         * Liefert den Kurzfristigen Finanzierungsbedarf
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Kurzfristiger Finanzierungsbedarf
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).sumPrefinancing({
                    prefinancingSales: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'PrefinancingSales', state.financing.prefinancingSales, withOverwrites),
                    salesRevenue: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'SalesRevenue', FinancingState.financingMapCalculationService(state.financing.calculationVersion).salesRevenue({
                        reducedSalesRevenue: state.financing.salesRevenue,
                        realEstates: state.financing.realEstates.map(it => ({
                            marketValue: OverwriteHelperService.getMergedOverwriteValue(it, 'MarketValue', it.marketValue, withOverwrites),
                            objectPurpose: OverwriteHelperService.getMergedOverwriteValue(it, 'ObjectPurpose', it.objectPurpose, withOverwrites),
                            collateralization: OverwriteHelperService.getMergedOverwriteValue(it, 'Collateralization', it.collateralization, withOverwrites),
                        })),
                    }), withOverwrites),
                    prefinancingInsurance: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'PrefinancingInsurance', state.financing.prefinancingInsurance, withOverwrites),
                    redemptionInsurance: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RedemptionInsurance', state.financing.redemptionInsurance, withOverwrites),
                    prefinancingBausparCreditBalance: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'PrefinancingBausparCreditBalance', state.financing.prefinancingBausparCreditBalance, withOverwrites),
                    bausparCreditBalance: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'BausparCreditBalance', state.financing.bausparCreditBalance, withOverwrites),
                    prefinancingFunding: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'PrefinancingFunding', state.financing.prefinancingFunding, withOverwrites),
                    funding: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'Funding', state.financing.funding, withOverwrites),
                    prefinancingOtherOwnCapital: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'PrefinancingOtherOwnCapital', state.financing.prefinancingOtherOwnCapital, withOverwrites),
                    otherOwnCapital: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'OtherOwnCapital', state.financing.otherOwnCapital, withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert die Bearbeitungsspesen
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Bearbeitungsspesen
     */
    @Selector([MasterdataState])
    public static processingCharges(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Bearbeitungsspesen
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Bearbeitungsspesen
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const amountOverwrite = OverwriteHelperService.getUnmergedValue(state.financing, 'ProcessingChargesAmount', state.financing.processingChargesAmount, true);
                const percentValueOverwrite = OverwriteHelperService.getUnmergedValue(state.financing, 'ProcessingChargesPercent', state.financing.processingChargesPercent, true);

                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).processingCharges({
                    grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                    processingChargesAmount:
                        (withOverwrites && amountOverwrite !== undefined && percentValueOverwrite === undefined) ||
                            (!withOverwrites && state.financing.processingChargesAmount !== undefined && state.financing.processingChargesPercent === undefined) ||
                            (state.financing.processingChargesAmount !== undefined && state.financing.processingChargesPercent === undefined && percentValueOverwrite === undefined) ?
                            OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ProcessingChargesAmount', state.financing.processingChargesAmount, withOverwrites) : undefined,
                    processingChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ProcessingChargesPercent', state.financing.processingChargesPercent, withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert die Eintragungsgebühr
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Eintragungsgebühr
     */
    @Selector([MasterdataState])
    public static registrationCharges(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Eintragungsgebühr
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Eintragungsgebühr
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const amountOverwrite = OverwriteHelperService.getUnmergedValue(state.financing, 'RegistrationChargesAmount', state.financing.registrationChargesAmount, true);
                const percentValueOverwrite = OverwriteHelperService.getUnmergedValue(state.financing, 'RegistrationChargesPercent', state.financing.registrationChargesPercent, true);

                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).registrationCharges({
                    grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                    registrationChargesAmount:
                        (withOverwrites && amountOverwrite !== undefined && percentValueOverwrite === undefined) ||
                            (!withOverwrites && state.financing.registrationChargesAmount !== undefined && state.financing.registrationChargesPercent === undefined) ||
                            (state.financing.registrationChargesAmount !== undefined && state.financing.registrationChargesPercent === undefined && percentValueOverwrite === undefined) ?
                            OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RegistrationChargesAmount', state.financing.registrationChargesAmount, withOverwrites) : undefined,
                    registrationChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RegistrationChargesPercent', state.financing.registrationChargesPercent, withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert die Schätzgebühr
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Schätzgebühr
     */
    @Selector([MasterdataState])
    public static estimateCharges(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Schätzgebühr
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Schätzgebühr
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const amountOverwrite = OverwriteHelperService.getUnmergedValue(state.financing, 'EstimateChargesAmount', state.financing.estimateChargesAmount, true);
                const percentValueOverwrite = OverwriteHelperService.getUnmergedValue(state.financing, 'EstimateChargesPercent', state.financing.estimateChargesPercent, true);

                return FinancingState.financingMapCalculationService(state.financing.calculationVersion).estimateCharges({
                    grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                    estimateChargesAmount:
                        (withOverwrites && amountOverwrite !== undefined && percentValueOverwrite === undefined) ||
                            (!withOverwrites && state.financing.estimateChargesAmount !== undefined && state.financing.estimateChargesPercent === undefined) ||
                            (state.financing.estimateChargesAmount !== undefined && state.financing.estimateChargesPercent === undefined && percentValueOverwrite === undefined) ?
                            OverwriteHelperService.getMergedOverwriteValue(state.financing, 'EstimateChargesAmount', state.financing.estimateChargesAmount, withOverwrites) : undefined,
                    estimateChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'EstimateChargesPercent', state.financing.estimateChargesPercent, withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert den korrekten Marktwert
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(UUID, boolean) => number | undefined} Marktwert
     */
    @Selector()
    public static marketValueMainRealEstate(state: IFinancingStateModel): (withOverwrites?: boolean) => number | undefined {
        /**
         * Liefert den korrekten Marktwert
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number | undefined} Marktwert
         */
        return (withOverwrites = true): number | undefined => {
            if (state.financing !== undefined) {
                const realEstate = state.financing.realEstates.find(it => it.objectPurpose === ObjectPurposeType.Finance);
                if (realEstate !== undefined) {
                    if (withOverwrites && OverwriteHelperService.getOverwriteFromEntity(realEstate, 'MarketValue') !== undefined) {
                        return OverwriteHelperService.getMergedOverwriteValue(realEstate, 'MarketValue', undefined as number | undefined, withOverwrites);
                    }

                    return FinancingState.realEstateCalculationService(state.financing.calculationVersion).calculatedMarketValue({
                        marketValue: realEstate.marketValue,
                        purchasePrice: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'PurchasePrice', state.financing.purchasePrice, withOverwrites),
                        marketValueType: realEstate.marketValueType,
                        creditPurpose: OverwriteHelperService.getMergedOverwriteValue(realEstate, 'CreditPurpose', realEstate.creditPurpose, withOverwrites),
                        realEstateType: OverwriteHelperService.getMergedOverwriteValue(realEstate, 'Type', realEstate.type, withOverwrites),
                    });
                }
            }
            return undefined;
        };
    }

    /**
     * Liefert die Summe der Marktwerte aller Objekte die zur Besicherung der neuen Finanzierung angegeben sind,
     * sowie den korrekten Marktwert des zu finanzierenden Objekts
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number | undefined} Summe der Marktwerte
     */
    @Selector()
    public static marketValuesFinancingRealEstates(state: IFinancingStateModel): (withOverwrites?: boolean) => number | undefined {
        /**
         * Liefert die Summe der Marktwerte aller Objekte die zur Besicherung der neuen Finanzierung angegeben sind,
         * sowie den korrekten Marktwert des zu finanzierenden Objekts
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number | undefined} Summe der Marktwerte
         */
        return (withOverwrites = true): number | undefined => {
            if (state.financing !== undefined) {
                const marketValueMainRealEstate = FinancingState.marketValueMainRealEstate(state)(withOverwrites);

                if (marketValueMainRealEstate !== undefined) {
                    return marketValueMainRealEstate + state.financing.realEstates.filter(realEstate => {
                        const collateralization = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'collateralization', realEstate.collateralization, withOverwrites);
                        const objectPurposeType = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'objectPurpose', realEstate.objectPurpose, withOverwrites);

                        return collateralization === Collateralization.NewFinancing && objectPurposeType === ObjectPurposeType.Owned;
                    }).reduce((sumMarketValues, currentRealEstate) => sumMarketValues + (OverwriteHelperService.getMergedOverwriteValue(currentRealEstate, 'marketValue', currentRealEstate.marketValue, withOverwrites) ?? 0), 0);
                }
                return undefined;
            }
            return undefined;
        }
    }

    /**
     * Liefert die Entscheidung, ob Kredit gesplittet werden muss
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => boolean} Wird Kredit gesplittet
     */
    @Selector([MasterdataState])
    public static useProductSplitting(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => boolean {
        /**
         * Liefert die Entscheidung, ob Kredit gesplittet werden muss
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {boolean} Wird Kredit gesplittet
         */
        return (withOverwrites = true): boolean => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).useProductSplitting({
                    grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                    comfortCredit: FinancingState.comfortCreditInternal(state, masterData, undefined, withOverwrites),
                    useLtvSplitting: FinancingState.comfortProductCalculationService(state.financing.calculationVersion).useLtvSplitting({
                        splittingRules: state.financing.financingConfiguration.splittingRules,
                        debitorsCount: state.financing.households.reduce((pv, cH) => pv + cH.debitors.length, 0),
                        ltv: FinancingState.comfortProductCalculationService(state.financing.calculationVersion).ltv({
                            grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                            liabilities: state.financing.households.reduce<ILiability[]>((pV, cH) => pV.concat(cH.liabilities), []).map(it => ({
                                covered: OverwriteHelperService.getMergedOverwriteValue(it, 'Covered', it.covered, withOverwrites),
                                securedByLandRegister: OverwriteHelperService.getMergedOverwriteValue(it, 'SecuredByLandRegister', it.securedByLandRegister, withOverwrites),
                                currentAmount: OverwriteHelperService.getMergedOverwriteValue(it, 'CurrentAmount', it.currentAmount, withOverwrites) ?? 0,
                                securedRealEstateIds: OverwriteHelperService.getMergedOverwriteValue(it, 'SecuredRealEstateIds', it.securedRealEstateIds, withOverwrites),
                            })),
                            newLiabilities: state.financing.households.reduce<INewLiability[]>((pV, cH) => pV.concat(cH.newLiabilities), []).map(it => ({
                                securedByLandRegister: OverwriteHelperService.getMergedOverwriteValue(it, 'SecuredByLandRegister', it.securedByLandRegister, withOverwrites),
                                amount: OverwriteHelperService.getMergedOverwriteValue(it, 'Amount', it.amount, withOverwrites),
                                securedRealEstateIds: OverwriteHelperService.getMergedOverwriteValue(it, 'SecuredRealEstateIds', it.securedRealEstateIds, withOverwrites),
                            })),
                            realEstates: state.financing.realEstates.map(it => ({
                                id: it.id,
                                objectPurpose: OverwriteHelperService.getMergedOverwriteValue(it, 'ObjectPurpose', it.objectPurpose, withOverwrites),
                                collateralization: OverwriteHelperService.getMergedOverwriteValue(it, 'Collateralization', it.collateralization, withOverwrites),
                                marketValue: OverwriteHelperService.getMergedOverwriteValue(it, 'MarketValue', it.marketValue, withOverwrites),
                                marketValueType: OverwriteHelperService.getOverwriteFromEntity(it, 'MarketValue') !== undefined && withOverwrites ? MarketValueType.Input : it.marketValueType,
                                purchasePrice: OverwriteHelperService.getMergedOverwriteValue(state.financing as IFinancing, 'PurchasePrice', (state.financing as IFinancing).purchasePrice, withOverwrites),
                                creditPurpose: OverwriteHelperService.getMergedOverwriteValue(it, 'CreditPurpose', it.creditPurpose, withOverwrites),
                                realEstateType: OverwriteHelperService.getMergedOverwriteValue(it, 'Type', it.type, withOverwrites),
                            })),
                        }),
                        netIncome: FinancingState.incomeTotal(state)(withOverwrites),
                        morixRating: FinancingState.realEstateMorixRatingWithHighestMarketValue(state, withOverwrites),
                    }),
                    // Kein Ratio-Splitting mehr, siehe Aufgabe #2189
                    useRatioSplitting: false,
                });
            }
            return false;
        };
    }

    /**
     * Liefert den KomfortKredit
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} KomfortKredit
     */
    @Selector([MasterdataState])
    public static comfortCredit(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert den KomfortKredit
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} KomfortKredit
         */
        return (withOverwrites = true): number => FinancingState.comfortCreditInternal(state, masterData, FinancingState.useProductSplitting(state, masterData)(withOverwrites), withOverwrites);
    }

    /**
     * Liefert die Bearbeitungsspesen für den KomfortKredit
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Bearbeitungsspesen für den KomfortKredit
     */
    @Selector([MasterdataState])
    public static comfortCreditProcessingCharges(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Bearbeitungsspesen für den KomfortKredit
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Bearbeitungsspesen für den KomfortKredit
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditProcessingCharges({
                    grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                    comfortCredit: FinancingState.comfortCredit(state, masterData)(withOverwrites),
                    processingChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ProcessingChargesPercent', state.financing.processingChargesPercent, withOverwrites),
                    processingChargesGrossFinancingRequirement: FinancingState.processingCharges(state, masterData)(withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert die Schätzgebühr für den KomfortKredit
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Schätzgebühr für den KomfortKredit
     */
    @Selector([MasterdataState])
    public static comfortCreditEstimateCharges(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Schätzgebühr für den KomfortKredit
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Schätzgebühr für den KomfortKredit
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditEstimateCharges({
                    grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                    comfortCredit: FinancingState.comfortCredit(state, masterData)(withOverwrites),
                    estimateChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'EstimateChargesPercent', state.financing.estimateChargesPercent, withOverwrites),
                    estimateChargesGrossFinancingRequirement: FinancingState.estimateCharges(state, masterData)(withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert die Eintragungsgebühr für den KomfortKredit
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Eintragungsgebühr für den KomfortKredit
     */
    @Selector([MasterdataState])
    public static comfortCreditRegistrationCharges(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Eintragungsgebühr für den KomfortKredit
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Eintragungsgebühr für den KomfortKredit
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditRegistrationCharges({
                    grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                    comfortCredit: FinancingState.comfortCredit(state, masterData)(withOverwrites),
                    registrationChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RegistrationChargesPercent', state.financing.registrationChargesPercent, withOverwrites),
                    registrationChargesGrossFinancingRequirement: FinancingState.registrationCharges(state, masterData)(withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert die Legalisierungsgebühr für den KomfortKredit
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Legalisierungsgebühr für den KomfortKredit
     */
    @Selector([MasterdataState])
    public static comfortCreditLegalisationFee(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Legalisierungsgebühr für den KomfortKredit
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Legalisierungsgebühr für den KomfortKredit
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined && Array.isArray(masterData.legalisationFeeBases) && masterData.legalisationFeeBases.length > 0) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditLegalisationFee({
                    comfortCredit: FinancingState.comfortCredit(state, masterData)(withOverwrites),
                    legalisationFeeBases: masterData.legalisationFeeBases,
                }) ?? 0;
            }
            return 0;
        };
    }

    /**
     * Liefert den Auszahlungsbetrag für den KomfortKredit
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Auszahlungsbetrag für den KomfortKredit
     */
    @Selector([MasterdataState])
    public static comfortCreditPayout(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert den Auszahlungsbetrag für den KomfortKredit
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Auszahlungsbetrag für den KomfortKredit
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditPayout({
                    comfortCredit: FinancingState.comfortCredit(state, masterData)(withOverwrites),
                    comfortCreditProcessingCharges: FinancingState.comfortCreditProcessingCharges(state, masterData)(withOverwrites),
                    comfortCreditEstimateCharges: FinancingState.comfortCreditEstimateCharges(state, masterData)(withOverwrites),
                    comfortCreditRegistrationCharges: FinancingState.comfortCreditRegistrationCharges(state, masterData)(withOverwrites),
                    legalisationFee: FinancingState.comfortCreditLegalisationFee(state, masterData)(withOverwrites),
                    landRegisterRequest: state.financing.financingConfiguration.landRegisterRequest,
                    landRegisterExtract: state.financing.financingConfiguration.landRegisterExtract,
                });
            }
            return 0;
        };
    }

    /**
     * Liefert den KomfortKredit Plus
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} KomfortKredit Plus
     */
    @Selector([MasterdataState])
    public static comfortCreditPlus(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert den KomfortKredit Plus
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} KomfortKredit Plus
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditPlus({
                    splitting: FinancingState.useProductSplitting(state, masterData)(withOverwrites),
                    grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                    comfortCredit: FinancingState.comfortCredit(state, masterData)(withOverwrites),
                });
            }
            return 0;
        }
    }

    /**
     * Liefert die Kreditlaufzeit in Monaten für den KomfortKredit Plus
     *
     * @returns {number} Kreditlaufzeit in Monaten für den KomfortKredit Plus
     */
    @Selector()
    public static comfortCreditPlusDuration(): number {
        return COMFORTCREDIT_PLUS_ASSUMEDDURATION;
    }

    /**
     * Liefert die Bearbeitungsspesen für den KomfortKredit Plus
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Bearbeitungsspesen für den KomfortKredit Plus
     */
    @Selector([MasterdataState])
    public static comfortCreditPlusProcessingCharges(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Bearbeitungsspesen für den KomfortKredit Plus
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Bearbeitungsspesen für den KomfortKredit Plus
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditPlusProcessingCharges({
                    splitting: FinancingState.useProductSplitting(state, masterData)(withOverwrites),
                    grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                    comfortCreditPlus: FinancingState.comfortCreditPlus(state, masterData)(withOverwrites),
                    processingChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ProcessingChargesPercent', state.financing.processingChargesPercent, withOverwrites),
                    processingChargesGrossFinancingRequirement: FinancingState.processingCharges(state, masterData)(withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert den Auszahlungsbetrag für den KomfortKredit Plus
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Auszahlungsbetrag für den KomfortKredit Plus
     */
    @Selector([MasterdataState])
    public static comfortCreditPlusPayout(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert den Auszahlungsbetrag für den KomfortKredit Plus
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Auszahlungsbetrag für den KomfortKredit Plus
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditPlusPayout({
                    splitting: FinancingState.useProductSplitting(state, masterData)(withOverwrites),
                    comfortCreditPlus: FinancingState.comfortCreditPlus(state, masterData)(withOverwrites),
                    comfortCreditPlusProcessingCharges: FinancingState.comfortCreditPlusProcessingCharges(state, masterData)(withOverwrites),
                });
            }
            return 0;
        };
    }

    /**
     * Liefert die Monatliche Rate KomfortKredit
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Monatliche Rate KomfortKredit
     */
    @Selector([MasterdataState])
    public static comfortCreditMonthlyDebitRate(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Monatliche Rate KomfortKredit
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Monatliche Rate KomfortKredit
         */
        return (withOverwrites = true): number => CalculationHelperService.handleDivisionByZeroError(() => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditMonthlyDebitRate({
                    comfortCredit: FinancingState.comfortCredit(state, masterData)(withOverwrites),
                    requestedDebitRate: FinancingState.requestedDebitRate(state, withOverwrites),
                    assumedDuration: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'AssumedDuration', state.financing.assumedDuration, withOverwrites),
                    gracePeriod: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'GracePeriod', state.financing.gracePeriod, withOverwrites),
                    bankAccountFee: state.financing.financingConfiguration.bankAccountFee,
                });
            }
            return 0;
        }, 0);
    }

    /**
     * Liefert die Zinsrate KomfortKredit
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Zinsrate KomfortKredit
     */
    @Selector([MasterdataState])
    public static comfortCreditMonthlyInterestRate(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Zinsrate KomfortKredit
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Zinsrate KomfortKredit
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditMonthlyInterestRate({
                    comfortCredit: FinancingState.comfortCredit(state, masterData)(withOverwrites),
                    requestedDebitRate: FinancingState.requestedDebitRate(state, withOverwrites),
                    bankAccountFee: state.financing.financingConfiguration.bankAccountFee,
                });
            }
            return 0;
        }
    }

    /**
     * Liefert die Monatliche Rate KomfortKredit Plus
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Monatliche Rate KomfortKredit Plus
     */
    @Selector([MasterdataState])
    public static comfortCreditPlusMonthlyDebitRate(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Monatliche Rate KomfortKredit Plus
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Monatliche Rate KomfortKredit Plus
         */
        return (withOverwrites = true): number => CalculationHelperService.handleDivisionByZeroError(() => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditPlusMonthlyDebitRate({
                    splitting: FinancingState.useProductSplitting(state, masterData)(withOverwrites),
                    comfortCreditPlus: FinancingState.comfortCreditPlus(state, masterData)(withOverwrites),
                    requestedDebitRate: FinancingState.requestedDebitRate(state, withOverwrites),
                    duration: FinancingState.comfortCreditPlusDuration(),
                });
            }
            return 0;
        }, 0);
    }

    /**
     * Liefert die Gesamtrate(1. - 10. Jahr)
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Gesamtrate(1. - 10. Jahr)
     */
    @Selector([MasterdataState])
    public static comfortCreditTotalMonthlyDebitRateToYearTen(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Gesamtrate(1. - 10. Jahr)
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Gesamtrate(1. - 10. Jahr)
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditTotalMonthlyDebitRateToYearTen({
                    splitting: FinancingState.useProductSplitting(state, masterData)(withOverwrites),
                    comfortCreditMonthlyDebitRate: FinancingState.comfortCreditMonthlyDebitRate(state, masterData)(withOverwrites),
                    comfortCreditPlusMonthlyDebitRate: FinancingState.comfortCreditPlusMonthlyDebitRate(state, masterData)(withOverwrites),
                });
            }
            return 0;
        }
    }

    /**
     * Liefert die Gesamtrate(ab 11. Jahr)
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Gesamtrate(ab 11. Jahr)
     */
    @Selector([MasterdataState])
    public static comfortCreditTotalMonthlyDebitRateFromYearEleven(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Gesamtrate(ab 11. Jahr)
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Gesamtrate(ab 11. Jahr)
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditTotalMonthlyDebitRateFromYearEleven({
                    comfortCreditMonthlyDebitRate: FinancingState.comfortCreditMonthlyDebitRate(state, masterData)(withOverwrites),
                    assumedDuration: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'AssumedDuration', state.financing.assumedDuration, withOverwrites),
                    comfortCreditPlusDuration: FinancingState.comfortCreditPlusDuration(),
                });
            }
            return 0;
        }
    }

    /**
     * Liefert die Gesamtkreditbetrag
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Gesamtkreditbetrag
     */
    @Selector([MasterdataState])
    public static comfortCreditTotalAmount(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Gesamtkreditbetrag
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Gesamtkreditbetrag
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditTotalAmount({
                    splitting: FinancingState.useProductSplitting(state, masterData)(withOverwrites),
                    comfortCredit: FinancingState.comfortCredit(state, masterData)(withOverwrites),
                    comfortCreditPlus: FinancingState.comfortCreditPlus(state, masterData)(withOverwrites),
                });
            }
            return 0;
        }
    }

    /**
     * Liefert die Gesamtauszahlungsbetrag
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Gesamtauszahlungsbetrag
     */
    @Selector([MasterdataState])
    public static comfortCreditTotalPayout(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Gesamtauszahlungsbetrag
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Gesamtauszahlungsbetrag
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditTotalPayout({
                    splitting: FinancingState.useProductSplitting(state, masterData)(withOverwrites),
                    comfortCreditPayout: FinancingState.comfortCreditPayout(state, masterData)(withOverwrites),
                    comfortCreditPlusPayout: FinancingState.comfortCreditPlusPayout(state, masterData)(withOverwrites),
                });
            }
            return 0;
        }
    }


    /**
     * Liefert den Zinssatz (fiktiv) für den Hauptkredit (i.d.R. KomfortKredit) in %
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number | undefined} Zinssatz (fiktiv) für den Hauptkredit (i.d.R. KomfortKredit) in %
     */
    @Selector()
    public static mainCreditFictionalRate(state: IFinancingStateModel): (withOverwrites?: boolean) => number | undefined {
        /**
         * Liefert den Zinssatz (fiktiv) für den Hauptkredit (i.d.R. KomfortKredit) in %
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number | undefined} Zinssatz (fiktiv) für den Hauptkredit (i.d.R. KomfortKredit) in %
         */
        return (withOverwrites = true): number | undefined => {
            if (state.financing !== undefined) {
                if (state.financing.calculationVersion === 0) {
                    return FinancingState.productCalculationService(state.financing.calculationVersion).fictionalRate({
                        configuration: state.financing.financingConfiguration,
                        interestMethod: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'InterestMethod', state.financing.interestMethod, withOverwrites),
                    });
                }
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditFictionalRate({
                    configuration: state.financing.financingConfiguration,
                    interestMethod: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'InterestMethod', state.financing.interestMethod, withOverwrites),
                    assumedDuration: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'AssumedDuration', state.financing.assumedDuration, withOverwrites),
                    requestedDebitRate: FinancingState.requestedDebitRate(state, withOverwrites),
                });
            }
            return undefined;
        }
    }

    /**
     * Liefert den Zinssatz (fiktiv) für den KomfortKredit Plus in %
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number | undefined} Zinssatz (fiktiv) für den KomfortKredit Plus in %
     */
    @Selector([MasterdataState])
    public static comfortCreditPlusFictionalRate(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number | undefined {
        /**
         * Liefert den Zinssatz (fiktiv) für den KomfortKredit Plus in %
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number | undefined} Zinssatz (fiktiv) für den KomfortKredit Plus in %
         */
        return (withOverwrites = true): number | undefined => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditPlusFictionalRate({
                    configuration: state.financing.financingConfiguration,
                    splitting: FinancingState.useProductSplitting(state, masterData)(withOverwrites),
                    interestMethod: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'InterestMethod', state.financing.interestMethod, withOverwrites),
                    duration: FinancingState.comfortCreditPlusDuration(),
                    requestedDebitRate: FinancingState.requestedDebitRate(state, withOverwrites),
                });
            }
            return undefined;
        }
    }

    /**
     * Liefert die Monatliche fiktive Rate KomfortKredit
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Monatliche fiktive Rate KomfortKredit
     */
    @Selector([MasterdataState])
    public static comfortCreditFictionalRateAmount(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Monatliche fiktive Rate KomfortKredit
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Monatliche fiktive Rate KomfortKredit
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const fictionalRate = FinancingState.mainCreditFictionalRate(state)(withOverwrites);
                if (fictionalRate !== undefined) {
                    return CalculationHelperService.handleDivisionByZeroError(() => FinancingState.comfortProductCalculationService((state.financing as IFinancing).calculationVersion).comfortCreditFictionalAmount({
                        fictionalRate,
                        comfortCredit: FinancingState.comfortCredit(state, masterData)(withOverwrites),
                        assumedDuration: OverwriteHelperService.getMergedOverwriteValue((state.financing as IFinancing), 'AssumedDuration', (state.financing as IFinancing).assumedDuration),
                        gracePeriod: OverwriteHelperService.getMergedOverwriteValue((state.financing as IFinancing), 'GracePeriod', (state.financing as IFinancing).gracePeriod),
                        bankAccountFee: (state.financing as IFinancing).financingConfiguration.bankAccountFee,
                    }), 0)
                }
                else {
                    /*   throw new ArgumentError('Der Parameter \'financingConfiguration\' ist nicht valide.'); */
                    // eslint-disable-next-line no-console
                    console.error('Der Parameter \'financingConfiguration\' ist nicht valide.');
                }
            }
            return 0;
        }
    }

    /**
     * Liefert die Monatliche fiktive Rate KomfortKredit Plus
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Monatliche fiktive Rate KomfortKredit Plus
     */
    @Selector([MasterdataState])
    public static comfortCreditPlusFictionalRateAmount(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Monatliche fiktive Rate KomfortKredit Plus
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Monatliche fiktive Rate KomfortKredit Plus
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                const fictionalRate = FinancingState.comfortCreditPlusFictionalRate(state, masterData)(withOverwrites);
                if (fictionalRate !== undefined) {
                    return CalculationHelperService.handleDivisionByZeroError(() => FinancingState.comfortProductCalculationService((state.financing as IFinancing).calculationVersion).comfortCreditPlusFictionalAmount({
                        fictionalRate,
                        splitting: FinancingState.useProductSplitting(state, masterData)(withOverwrites),
                        comfortCreditPlus: FinancingState.comfortCreditPlus(state, masterData)(withOverwrites),
                        duration: FinancingState.comfortCreditPlusDuration(),
                    }), 0);
                }
                else {
                    throw new ArgumentError('Der Parameter \'financingConfiguration\' ist nicht valide.');
                }
            }
            return 0;
        }
    }

    /**
     * Liefert die Monatliche fiktive Rate Gesamt
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Monatliche fiktive Rate Gesamt
     */
    @Selector([MasterdataState])
    public static comfortCreditTotalFictionalRateAmount(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Monatliche fiktive Rate Gesamt
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Monatliche fiktive Rate Gesamt
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCreditTotalFictionalAmount({
                    splitting: FinancingState.useProductSplitting(state, masterData)(withOverwrites),
                    comfortCreditFictionalAmount: FinancingState.comfortCreditFictionalRateAmount(state, masterData)(withOverwrites),
                    comfortCreditPlusFictionalAmount: FinancingState.comfortCreditPlusFictionalRateAmount(state, masterData)(withOverwrites),
                });
            }
            return 0;
        }
    }

    /**
     * Liefert Durch HHR gedeckt
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => boolean} Durch HHR gedeckt
     */
    @Selector([MasterdataState])
    public static hhrCovered(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => boolean | undefined {
        /**
         * Liefert Durch HHR gedeckt
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Durch HHR gedeckt
         */
        return (withOverwrites = true): boolean | undefined => {
            if (state.financing !== undefined) {
                return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).hhrCovered({
                    comfortCreditTotalFictionalAmount: FinancingState.comfortCreditTotalFictionalRateAmount(state, masterData)(withOverwrites),
                    acceptableCreditRate: FinancingState.acceptableCreditRate(state)(withOverwrites),
                });
            }
            return undefined;
        }
    }

    /**
     * Liefert die Fiktive Rate
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @returns {(boolean) => number} Fiktive Rate
     */
    @Selector([MasterdataState])
    public static fictionalRateAmount(state: IFinancingStateModel, masterData: IMasterdataStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Fiktive Rate
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Fiktive Rate
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                switch (state.financing.calculationVersion) {
                    case 0:
                        return FinancingState.productCalculationService(state.financing.calculationVersion).fictionalAmount({
                            fictionalRate: FinancingState.productCalculationService(state.financing.calculationVersion).fictionalRate({
                                configuration: state.financing.financingConfiguration,
                                interestMethod: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'InterestMethod', state.financing.interestMethod, withOverwrites),
                            }) ?? 4,
                            grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                            assumedDuration: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'AssumedDuration', state.financing.assumedDuration, withOverwrites),
                            gracePeriod: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'GracePeriod', state.financing.gracePeriod, withOverwrites),
                            bankAccountFee: state.financing.financingConfiguration.bankAccountFee,
                        });
                    default:
                        return FinancingState.comfortCreditTotalFictionalRateAmount(state, masterData)(withOverwrites);
                }
            }
            return 0;
        };
    }

    /**
     * Liefert alle Dokumente der Finanzierungsmappe
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {IDocument[]} Dokumente
     */
    @Selector()
    public static allDocuments(state: IFinancingStateModel): IDocument[] {
        if (state.financing === undefined) {
            return [];
        }

        return [
            ...state.financing.documents,
            ...state.financing.realEstates.reduce((docs, realestate) => docs.concat(realestate.documents), [] as IDocument[]),
            ...state.financing.households.reduce((docs, household) => docs.concat(household.documents,
                household.debitors.reduce((debitorDocs, debitor) => debitorDocs.concat(debitor.documents), [] as IDocument[])), [] as IDocument[]),
        ];
    }

    /**
     * Liefert die Dokumente zu der ID einer Entität
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(id: string) => IDocument[]} Dokumente
     */
    @Selector()
    public static documentsById(state: IFinancingStateModel): (id: string) => IDocument[] {
        /**
         * Liefert die Dokumente
         *
         * @param {string} id ID der Entität
         * @returns {IDocument[]} Dokumente
         */
        return (id: string): IDocument[] => {
            if (state.financing === undefined) {
                return [];
            }

            if (state.financing.id === id) {
                return state.financing.documents ?? [];
            }

            const household = state.financing.households.find(element => element.id === id);
            if (household !== undefined) {
                return household.documents ?? [];
            }

            const realestate = state.financing.realEstates.find(element => element.id === id);
            if (realestate !== undefined) {
                return realestate.documents ?? [];
            }

            const debtor = state.financing.households.reduce((debitors, hhold) => debitors.concat(hhold.debitors), [] as IDebitor[])
                .find(debitor => debitor.id === id);
            if (debtor !== undefined) {
                return debtor.documents ?? [];
            }

            return [];
        }
    }

    /**
     * Liefert ein Dokument in einem Format zur Anzeige unter Belegen
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(document: IDocument) => string | undefined} Name
     */
    @Selector()
    public static documentViewModel(state: IFinancingStateModel): (document: IDocument) => IDocumentView {
        /**
         * Liefert die Ansicht als ViewModel
         *
         * @param {IDocument} document Dokument
         * @returns {string} Name
         */
        return (document: IDocument): IDocumentView => {
            if (state.financing === undefined) {
                return { document };
            }

            if (state.financing.id === document.parentId) {
                return {
                    document,
                };
            }

            const householdIndex = sort(state.financing.households).asc(hh => hh.position).findIndex(element => element.id === document.parentId);
            if (householdIndex >= 0) {
                return {
                    document,
                    position: householdIndex,
                    parentName: `${householdIndex + 1}. Haushalt`,
                };
            }

            const realestate = state.financing.realEstates.find(element => element.id === document.parentId);
            if (realestate !== undefined) {
                return {
                    document,
                    parentName: RealEstateType.translate(realestate.type),
                    position: realestate.position,
                }
            }

            const debtor = state.financing.households.reduce((debitors, hhold) => debitors.concat(hhold.debitors), [] as IDebitor[])
                .find(debitor => debitor.id === document.parentId);
            if (debtor !== undefined) {
                return {
                    document,
                    parentName: `${debtor.lastName}, ${debtor.firstName}`,
                    position: debtor.position,
                }
            }

            return { document };
        }
    }

    /**
     * Liefert Summe der Marktwerte der zum Verkauf stehenden Objekte
     *
     * @param {IFinancingStateModel} state Zustand
     * @returns {(boolean) => number} Summe der Marktwerte der zum Verkauf stehenden Objekte
     */
    @Selector()
    public static sumMarketValues(state: IFinancingStateModel): (withOverwrites?: boolean) => number {
        /**
         * Liefert die Summe der Marktwerte der zum Verkauf stehenden Objekte
         *
         * @param {boolean} withOverwrites Overwrites berücksichtigen
         * @returns {number} Summe der Marktwerte der zum Verkauf stehenden Objekte
         */
        return (withOverwrites = true): number => {
            if (state.financing !== undefined) {
                return state.financing.realEstates.reduce((totalMarketValue, currentRealestate) => {
                    const marketValue = OverwriteHelperService.getMergedOverwriteValue(currentRealestate, 'marketValue', currentRealestate.marketValue, withOverwrites);
                    if (currentRealestate.objectPurpose !== ObjectPurposeType.ForSale || marketValue === undefined) {
                        return totalMarketValue;
                    }

                    return totalMarketValue + marketValue;
                }, 0);
            }

            return 0;
        };
    }

    /**
     * get Approval Decision
     * 
     * @param {IFinancingStateModel} state state
     * @returns {IProductPackageData} state productPackages
     */
    @Selector()
    public static productPackages(state: IFinancingStateModel) {
        return state.productPackages;
    }

    /**
     * Selektor für ein einzelnes Produktpaket
     * 
     * @param {IFinancingStateModel} state Aktueller Zustand
     * @returns {IProductPackage | undefined} Produktpaket
     */
    @Selector()
    public static productPackagebyId(state: IFinancingStateModel): (id: UUID) => IProductPackage | undefined {
        return (id: UUID) => state.productPackages?.assignProductPackages?.find(productPackage => productPackage.id === id);
    }

    /**
     * Liefert die Genehmigungskompetenz zu einem Produktpaket
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterdata Zustand Stammdaten
     * @returns {(productPackageId: string) => IApprovalCompetence | undefined} Fiktive Rate
     */
    @Selector([MasterdataState])
    public static approvalCompetence(state: IFinancingStateModel, masterdata: IMasterdataStateModel): (productPackageId: UUID) => IApprovalCompetence | undefined {
        return (productPackageId: UUID): IApprovalCompetence | undefined => {
            const productPackage = state.productPackages?.assignProductPackages?.find(pp => pp.id === productPackageId);

            if (!productPackage) {
                return undefined;
            }

            const competencesByEuro = sort(masterdata.approvalCompetence).desc(it => it.lowerLimit);
            const financingRequirement = productPackage.assignProducts.reduce((sum, current) => sum + current.creditAmount, 0);
            return competencesByEuro.find(it => it.lowerLimit < financingRequirement && it.upperLimit >= financingRequirement);
        };
    }

    /**
     * Überprüft ob der Benutzer Bearbeiter und Experte des Falls ist
     * 
     * @param {IFinancingStateModel} state Zustand 
     * @param {IUserStateModel} userState Zustand UserState
     * @returns {boolean} True, wenn der Nutzer Bearbeiter und Experte des Fall ist, sonst false
     */
    @Selector([UserState])
    public static isExpert(state: IFinancingStateModel, userState: IUserStateModel): boolean {
        const user = userState.data;
        if (!state.finprocessContainer || !user) {
            return false;
        }

        return state.finprocessContainer.users.some(usr => usr.id === user.id && HelperService.hasBit(usr.roles, Role.Expert)) || state.finprocessContainer.temporaryUser?.id === user.id;
    }

    /**
     * Überprüft ob der Benutzer Bearbeiter und Referent des Falls ist
     * 
     * @param {IFinancingStateModel} state Zustand 
     * @param {IUserStateModel} userState Zustand UserState
     * @returns {boolean} True, wenn der Nutzer Bearbeiter und Referent des Fall ist, sonst false
     */
    @Selector([UserState])
    public static isReferent(state: IFinancingStateModel, userState: IUserStateModel): boolean {
        const user = userState.data;
        if (!state.finprocessContainer || !user) {
            return false;
        }

        return state.finprocessContainer.users.some(usr => usr.id === user.id && HelperService.hasBit(usr.roles, Role.Referent));
    }

    /**
     * Überprüft ob der Benutzer Bearbeiter und Genehmiger des Produktpakets ist
     * 
     * @param {IFinancingStateModel} state Zustand 
     * @param {IUserStateModel} userState Zustand UserState
     * @returns {boolean} True, wenn der Nutzer Bearbeiter und Genehmiger des Fall ist, sonst false
     */
    @Selector([UserState])
    public static isApprover(state: IFinancingStateModel, userState: IUserStateModel): (productPackageId: string) => boolean {
        return (productPackageId: string) => {
            const user = userState.data;
            const productPackage = state.productPackages?.assignProductPackages.find(pp => pp.id === productPackageId);
            if (!state.finprocessContainer || !user || !productPackage) {
                return false;
            }

            return productPackage.approver?.id === user.id && state.finprocessContainer.users.some(usr => usr.id === user.id && HelperService.hasBit(usr.roles, Role.Approver));
        }
    }

    /**
     * Berechnung der zusätzlichen Transferleistungen gemäß KIM V pro Kreditnehmer
     * 
     * @param {IFinancingStateModel} state Zustand
     * @param {ISystemConfigurationStateModel} systemConfig Zustand Systemkonfiguration
     * @returns {number} Zusätzliche Transferleistungen gemäß KIM V pro Kreditnehmer
     */
    @Selector([SystemConfigurationState])
    public static additionalTransferChargesByDebitor(state: IFinancingStateModel, systemConfig: ISystemConfigurationStateModel): (debitorId: UUID) => number {
        return (debitorId: UUID) => {
            if (!systemConfig.systemConfiguration) {
                return 0;
            }

            const debitor = state.financing?.households.reduce<IDebitor[]>((debitors, household) => debitors.concat(household.debitors), []).find(deb => deb.id === debitorId);
            const baseChildrenAmount = systemConfig.systemConfiguration[SystemConfigurationType.BaseChildrenAmount] as number;
            const baseChildrenObligationAmount = systemConfig.systemConfiguration[SystemConfigurationType.BaseChildrenObligationAmount] as number;
            const baseChildrenRecipientAmount = systemConfig.systemConfiguration[SystemConfigurationType.BaseChildrenRecipientAmount] as number;

            if (!debitor || CalculationHelperService.isNullOrNaN(baseChildrenAmount) || CalculationHelperService.isNullOrNaN(baseChildrenObligationAmount) || CalculationHelperService.isNullOrNaN(baseChildrenRecipientAmount)) {
                return 0;
            }

            const numberOfDependentChildren = CalculationHelperService.isNullOrNaN(debitor.numberOfDependentChildren) ? 0 : debitor.numberOfDependentChildren;
            const numberOfChildrenForChildSupport = CalculationHelperService.isNullOrNaN(debitor.numberOfChildrenForChildSupport) ? 0 : debitor.numberOfChildrenForChildSupport;
            const numberOfChildrenReceivingChildSupport = CalculationHelperService.isNullOrNaN(debitor.numberOfChildrenReceivingChildSupport) ? 0 : debitor.numberOfChildrenReceivingChildSupport;

            return numberOfDependentChildren * baseChildrenAmount - numberOfChildrenForChildSupport * baseChildrenObligationAmount + numberOfChildrenReceivingChildSupport * baseChildrenRecipientAmount; 
        }
    }

    /**
     * Liefert den KomfortKredit
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {IMasterdataStateModel} masterData Zustand Stammdaten
     * @param {boolean | undefined} splitting Produktsplitting
     * @param {boolean} withOverwrites Overwrites berücksichtigen
     * @returns {number} KomfortKredit
     */
    private static comfortCreditInternal(state: IFinancingStateModel, masterData: IMasterdataStateModel, splitting?: boolean, withOverwrites = true): number {
        if (state.financing !== undefined) {
            const mainRealEstate = FinancingState.mainRealEstate(state);
            return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCredit({
                grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                comfortCredit1: FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCredit1({
                    currentAmountSecuredByLandRegisterNotCoveredLiabilites: FinancingState.currentAmountSecuredByLandRegisterNotCoveredLiabilites(state, withOverwrites),
                    amountSecuredByLandRegisterNewLiabilites: FinancingState.amountSecuredByLandRegisterNewLiabilites(state, withOverwrites),
                    marketValue: FinancingState.marketValuesFinancingRealEstates(state)(withOverwrites),
                    ltvFactor: FinancingState.ltvFactor(state, withOverwrites),

                }),
                comfortCredit2: FinancingState.comfortProductCalculationService(state.financing.calculationVersion).comfortCredit2({
                    grossFinancingRequirement: FinancingState.grossFinancingRequirement(state, masterData)(withOverwrites),
                    sumAdditionalCosts: FinancingState.sumAdditionalCosts(state)(withOverwrites),
                    sumOwnCapital: FinancingState.sumOwnCapital(state)(withOverwrites),
                    otherCosts: mainRealEstate !== undefined ? OverwriteHelperService.getMergedOverwriteValue(mainRealEstate, 'OtherCosts', mainRealEstate.otherCosts, withOverwrites) : undefined,
                    funding: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'Funding', state.financing.funding, withOverwrites),
                    otherOwnCapital: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'OtherOwnCapital', state.financing.otherOwnCapital, withOverwrites),
                }),
                splitting,
            });
        }
        return 0;
    }

    /**
     * Liefert den Kreditbetrag aller grundbücherlich besicherten neuen Verpflichtungen
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {boolean} withOverwrites Overwrites berücksichtigen
     * @returns {number} Kreditbetrag aller grundbücherlich besicherten neuen Verpflichtungen
     */
    private static amountSecuredByLandRegisterNewLiabilites(state: IFinancingStateModel, withOverwrites = true) {
        if (state.financing !== undefined) {
            return state.financing.households.reduce((pv, cH) => pv + cH.newLiabilities.reduce((pnlv, cNL) => pnlv + FinancingState.newLiabilityCalculationService((state.financing as IFinancing).calculationVersion).amountSecuredByLandRegister({
                amount: FinancingState.newLiabilityCalculationService((state.financing as IFinancing).calculationVersion).amountCalculated({
                    amount: OverwriteHelperService.getMergedOverwriteValue(cNL, 'Amount', cNL.amount, withOverwrites),
                    monthlyRate: OverwriteHelperService.getMergedOverwriteValue(cNL, 'MonthlyRate', cNL.monthlyRate, withOverwrites),
                    loanPeriodInMonths: OverwriteHelperService.getMergedOverwriteValue(cNL, 'LoanPeriodInMonths', cNL.loanPeriodInMonths, withOverwrites),
                }),
                securedByLandRegister: OverwriteHelperService.getMergedOverwriteValue(cNL, 'SecuredByLandRegister', cNL.securedByLandRegister, withOverwrites),
            }), 0), 0);
        }
        return 0;
    }

    /**
     * Liefert die Aktuelle Aushaftung aller nicht abgedeckten, aber grundbücherlich besicherten bestehenden Verpflichtungen
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {boolean} withOverwrites Overwrites berücksichtigen
     * @returns {number} Aktuelle Aushaftung aller nicht abgedeckten, aber grundbücherlich besicherten bestehenden Verpflichtungen
     */
    private static currentAmountSecuredByLandRegisterNotCoveredLiabilites(state: IFinancingStateModel, withOverwrites = true) {
        if (state.financing !== undefined) {
            return state.financing.households.reduce((pv, cH) => pv + cH.liabilities.reduce((plv, cL) => plv + FinancingState.liabilityCalculationService((state.financing as IFinancing).calculationVersion).currentAmountSecuredByLandRegisterNotCovered({
                currentAmount: OverwriteHelperService.getMergedOverwriteValue(cL, 'CurrentAmount', cL.currentAmount, withOverwrites),
                covered: OverwriteHelperService.getMergedOverwriteValue(cL, 'Covered', cL.covered, withOverwrites),
                securedByLandRegister: OverwriteHelperService.getMergedOverwriteValue(cL, 'SecuredByLandRegister', cL.securedByLandRegister, withOverwrites),
            }), 0), 0);
        }
        return 0;
    }

    /**
     * Liefert das Objekt mit dem höchsten Markwert
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {boolean} withOverwrites Overwrites berücksichtigen
     * @returns {IRealEstate | undefined} Objekt mit dem höchsten Markwert
     */
    private static realEstateMorixRatingWithHighestMarketValue(state: IFinancingStateModel, withOverwrites = true): number | undefined {
        if (state.financing !== undefined) {
            return this.realEstateCalculationService(state.financing.calculationVersion).getMorixRatingFromCollateralizationNewFinancingWithHighestMarketValue({
                realEstates: state.financing.realEstates.map(it => ({
                    objectPurpose: OverwriteHelperService.getMergedOverwriteValue(it, 'ObjectPurpose', it.objectPurpose, withOverwrites),
                    collateralization: OverwriteHelperService.getMergedOverwriteValue(it, 'Collateralization', it.collateralization, withOverwrites),
                    marketValue: OverwriteHelperService.getMergedOverwriteValue(it, 'MarketValue', it.marketValue, withOverwrites),
                    marketValueType: OverwriteHelperService.getOverwriteFromEntity(it, 'MarketValue') !== undefined && withOverwrites ? MarketValueType.Input : it.marketValueType,
                    purchasePrice: OverwriteHelperService.getMergedOverwriteValue(state.financing as IFinancing, 'PurchasePrice', (state.financing as IFinancing).purchasePrice, withOverwrites),
                    creditPurpose: OverwriteHelperService.getMergedOverwriteValue(it, 'CreditPurpose', it.creditPurpose, withOverwrites),
                    realEstateType: OverwriteHelperService.getMergedOverwriteValue(it, 'Type', it.type, withOverwrites),
                    morixRating: OverwriteHelperService.getMergedOverwriteValue(it, 'MorixRating', it.morixRating, withOverwrites),
                })),
            })
        }
        return undefined;
    }

    /**
     * Liefert die für die Kaufnebenkosten relevanten Projektkosten
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {boolean} withOverwrites Overwrites berücksichtigen
     * @returns {number} Für die Kaufnebenkosten relevanten Projektkosten
     */
    private static sumPricesRelevantForAdditionalCosts(state: IFinancingStateModel, withOverwrites = true): number {
        if (state.financing !== undefined) {
            const realEstate = FinancingState.mainRealEstate(state);
            if (realEstate !== undefined) {
                return FinancingState.realEstateCalculationService(state.financing.calculationVersion).sumPricesRelevantForAdditionalCosts({
                    purchasePriceRelevantForAdditionalCosts: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'PurchasePriceRelevantForAdditionalCosts', state.financing.purchasePriceRelevantForAdditionalCosts, withOverwrites),
                    purchasePrice: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'PurchasePrice', state.financing.purchasePrice, withOverwrites),
                    lotPriceRelevantForAdditionalCosts: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'LotPriceRelevantForAdditionalCosts', state.financing.lotPriceRelevantForAdditionalCosts, withOverwrites),
                    lotPrice: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'LotPrice', state.financing.lotPrice, withOverwrites),
                    developmentCostsRelevantForAdditionalCosts: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'DevelopmentCostsRelevantForAdditionalCosts', state.financing.developmentCostsRelevantForAdditionalCosts, withOverwrites),
                    developmentCosts: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'DevelopmentCosts', state.financing.developmentCosts, withOverwrites),
                    constructionCostsRelevantForAdditionalCosts: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ConstructionCostsRelevantForAdditionalCosts', state.financing.constructionCostsRelevantForAdditionalCosts, withOverwrites),
                    constructionCosts: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ConstructionCosts', state.financing.constructionCosts, withOverwrites),
                });
            }
        }
        return 0;
    }

    /**
     * Liefert den LTV Faktor
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {boolean} withOverwrites Overwrites berücksichtigen
     * @returns {number} Für die Kaufnebenkosten relevanten Projektkosten
     */
    private static ltvFactor(state: IFinancingStateModel, withOverwrites = true): number | undefined {
        if (state.financing !== undefined) {
            return FinancingState.comfortProductCalculationService(state.financing.calculationVersion).ltvFactor({
                splittingRules: state.financing.financingConfiguration.splittingRules,
                debitorsCount: state.financing.households.reduce((debitorCount, currentHousehold) => debitorCount + currentHousehold.debitors.length, 0),
                netIncome: FinancingState.incomeTotal(state)(withOverwrites),
                morixRating: FinancingState.realEstateMorixRatingWithHighestMarketValue(state, withOverwrites),
            });
        }

        return 0;
    }

    /**
     * Liefert die für die Berechnung zu nutzenden Werte der Finanzierungsnebenkosten
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {boolean} withOverwrites Overwrites berücksichtigen
     * @returns {any} Für Berechnung zu nutzende Werte
     */
    // eslint-disable-next-line complexity
    private static getSumFinancingAdditionalChargesParams(state: IFinancingStateModel, withOverwrites = true): {
        estimateChargesAmount?: number;
        estimateChargesPercent?: number;
        processingChargesAmount?: number;
        processingChargesPercent?: number;
        registrationChargesAmount?: number;
        registrationChargesPercent?: number;
    } {
        const result: {
            estimateChargesAmount?: number;
            estimateChargesPercent?: number;
            processingChargesAmount?: number;
            processingChargesPercent?: number;
            registrationChargesAmount?: number;
            registrationChargesPercent?: number;
        } = {};

        if (state.financing !== undefined) {
            if (
                state.financing.processingChargesAmount !== undefined && state.financing.processingChargesAmount > 0 &&
                (!withOverwrites || state.financing.processingChargesPercent === undefined || state.financing.processingChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'ProcessingChargesPercent') === undefined &&
                state.financing.registrationChargesAmount !== undefined && state.financing.registrationChargesAmount > 0 &&
                (!withOverwrites || state.financing.registrationChargesPercent === undefined || state.financing.registrationChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'RegistrationChargesPercent') === undefined &&
                state.financing.estimateChargesAmount !== undefined && state.financing.estimateChargesAmount > 0 &&
                (!withOverwrites || state.financing.estimateChargesPercent === undefined || state.financing.estimateChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'EstimateChargesPercent') === undefined
            ) {
                return {
                    processingChargesAmount: state.financing.processingChargesAmount,
                    registrationChargesAmount: state.financing.registrationChargesAmount,
                    estimateChargesAmount: state.financing.estimateChargesAmount,
                };
            }
            else if (
                (!(state.financing.processingChargesAmount !== undefined && state.financing.processingChargesAmount > 0 &&
                    (!withOverwrites || state.financing.processingChargesPercent === undefined || state.financing.processingChargesPercent <= 0)) ||
                    OverwriteHelperService.getOverwriteFromEntity(state.financing, 'ProcessingChargesPercent') !== undefined) &&
                state.financing.registrationChargesAmount !== undefined && state.financing.registrationChargesAmount > 0 &&
                (!withOverwrites || state.financing.registrationChargesPercent === undefined || state.financing.registrationChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'RegistrationChargesPercent') === undefined &&
                state.financing.estimateChargesAmount !== undefined && state.financing.estimateChargesAmount > 0 &&
                (!withOverwrites || state.financing.estimateChargesPercent === undefined || state.financing.estimateChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'EstimateChargesPercent') === undefined
            ) {
                return {
                    processingChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ProcessingChargesPercent', state.financing.processingChargesPercent, withOverwrites),
                    registrationChargesAmount: state.financing.registrationChargesAmount,
                    estimateChargesAmount: state.financing.estimateChargesAmount,
                };
            }
            else if (
                state.financing.processingChargesAmount !== undefined && state.financing.processingChargesAmount > 0 &&
                (!withOverwrites || state.financing.processingChargesPercent === undefined || state.financing.processingChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'ProcessingChargesPercent') === undefined &&
                (!(state.financing.registrationChargesAmount !== undefined && state.financing.registrationChargesAmount > 0 &&
                    (!withOverwrites || state.financing.registrationChargesPercent === undefined || state.financing.registrationChargesPercent <= 0)) ||
                    OverwriteHelperService.getOverwriteFromEntity(state.financing, 'RegistrationChargesPercent') !== undefined) &&
                state.financing.estimateChargesAmount !== undefined && state.financing.estimateChargesAmount > 0 &&
                (!withOverwrites || state.financing.estimateChargesPercent === undefined || state.financing.estimateChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'EstimateChargesPercent') === undefined
            ) {
                return {
                    processingChargesAmount: state.financing.processingChargesAmount,
                    registrationChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RegistrationChargesPercent', state.financing.registrationChargesPercent, withOverwrites),
                    estimateChargesAmount: state.financing.estimateChargesAmount,
                };
            }
            else if (
                state.financing.processingChargesAmount !== undefined && state.financing.processingChargesAmount > 0 &&
                (!withOverwrites || state.financing.processingChargesPercent === undefined || state.financing.processingChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'ProcessingChargesPercent') === undefined &&
                state.financing.registrationChargesAmount !== undefined && state.financing.registrationChargesAmount > 0 &&
                (!withOverwrites || state.financing.registrationChargesPercent === undefined || state.financing.registrationChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'RegistrationChargesPercent') === undefined &&
                (!(state.financing.estimateChargesAmount !== undefined && state.financing.estimateChargesAmount > 0 &&
                    (!withOverwrites || state.financing.estimateChargesPercent === undefined || state.financing.estimateChargesPercent <= 0)) ||
                    OverwriteHelperService.getOverwriteFromEntity(state.financing, 'EstimateChargesPercent') !== undefined)
            ) {
                return {
                    processingChargesAmount: state.financing.processingChargesAmount,
                    registrationChargesAmount: state.financing.registrationChargesAmount,
                    estimateChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'EstimateChargesPercent', state.financing.estimateChargesPercent, withOverwrites),
                };
            }
            else if (
                (!(state.financing.processingChargesAmount !== undefined && state.financing.processingChargesAmount > 0 &&
                    (!withOverwrites || state.financing.processingChargesPercent === undefined || state.financing.processingChargesPercent <= 0)) ||
                    OverwriteHelperService.getOverwriteFromEntity(state.financing, 'ProcessingChargesPercent') !== undefined) &&
                (!(state.financing.registrationChargesAmount !== undefined && state.financing.registrationChargesAmount > 0 &&
                    (!withOverwrites || state.financing.registrationChargesPercent === undefined || state.financing.registrationChargesPercent <= 0)) ||
                    OverwriteHelperService.getOverwriteFromEntity(state.financing, 'RegistrationChargesPercent') !== undefined) &&
                state.financing.estimateChargesAmount !== undefined && state.financing.estimateChargesAmount > 0 &&
                (!withOverwrites || state.financing.estimateChargesPercent === undefined || state.financing.estimateChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'EstimateChargesPercent') === undefined
            ) {
                return {
                    processingChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ProcessingChargesPercent', state.financing.processingChargesPercent, withOverwrites),
                    registrationChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RegistrationChargesPercent', state.financing.registrationChargesPercent, withOverwrites),
                    estimateChargesAmount: state.financing.estimateChargesAmount,
                };
            }
            else if (
                (!(state.financing.processingChargesAmount !== undefined && state.financing.processingChargesAmount > 0 &&
                    (!withOverwrites || state.financing.processingChargesPercent === undefined || state.financing.processingChargesPercent <= 0)) ||
                    OverwriteHelperService.getOverwriteFromEntity(state.financing, 'ProcessingChargesPercent') !== undefined) &&
                state.financing.registrationChargesAmount !== undefined && state.financing.registrationChargesAmount > 0 &&
                (!withOverwrites || state.financing.registrationChargesPercent === undefined || state.financing.registrationChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'RegistrationChargesPercent') === undefined &&
                (!(state.financing.estimateChargesAmount !== undefined && state.financing.estimateChargesAmount > 0 &&
                    (!withOverwrites || state.financing.estimateChargesPercent === undefined || state.financing.estimateChargesPercent <= 0)) ||
                    OverwriteHelperService.getOverwriteFromEntity(state.financing, 'EstimateChargesPercent') !== undefined)
            ) {
                return {
                    processingChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ProcessingChargesPercent', state.financing.processingChargesPercent, withOverwrites),
                    registrationChargesAmount: state.financing.registrationChargesAmount,
                    estimateChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'EstimateChargesPercent', state.financing.estimateChargesPercent, withOverwrites),
                };
            }
            else if (
                state.financing.processingChargesAmount !== undefined && state.financing.processingChargesAmount > 0 &&
                (!withOverwrites || state.financing.processingChargesPercent === undefined || state.financing.processingChargesPercent <= 0) &&
                OverwriteHelperService.getOverwriteFromEntity(state.financing, 'ProcessingChargesPercent') === undefined &&
                (!(state.financing.registrationChargesAmount !== undefined && state.financing.registrationChargesAmount > 0 &&
                    (!withOverwrites || state.financing.registrationChargesPercent === undefined || state.financing.registrationChargesPercent <= 0)) ||
                    OverwriteHelperService.getOverwriteFromEntity(state.financing, 'RegistrationChargesPercent') !== undefined) &&
                (!(state.financing.estimateChargesAmount !== undefined && state.financing.estimateChargesAmount > 0 &&
                    (!withOverwrites || state.financing.estimateChargesPercent === undefined || state.financing.estimateChargesPercent <= 0)) ||
                    OverwriteHelperService.getOverwriteFromEntity(state.financing, 'EstimateChargesPercent') !== undefined)
            ) {
                return {
                    processingChargesAmount: state.financing.processingChargesAmount,
                    registrationChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RegistrationChargesPercent', state.financing.registrationChargesPercent, withOverwrites),
                    estimateChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'EstimateChargesPercent', state.financing.estimateChargesPercent, withOverwrites),
                };
            }
            else {
                return {
                    processingChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'ProcessingChargesPercent', state.financing.processingChargesPercent, withOverwrites),
                    registrationChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RegistrationChargesPercent', state.financing.registrationChargesPercent, withOverwrites),
                    estimateChargesPercent: OverwriteHelperService.getMergedOverwriteValue(state.financing, 'EstimateChargesPercent', state.financing.estimateChargesPercent, withOverwrites),
                };
            }
        }

        return result;
    }

    /**
     * Gibt den korrigierten Wert für den angefragten Zinssatz in % zurück
     *
     * @param {IFinancingStateModel} state Zustand
     * @param {boolean} withOverwrites Overwrites berücksichtigen
     * @returns {number} Für Berechnung zu nutzende Werte
     */
    private static requestedDebitRate(state: IFinancingStateModel, withOverwrites: boolean): number {
        if (state.financing !== undefined) {
            const requestedDebitRate = OverwriteHelperService.getMergedOverwriteValue(state.financing, 'RequestedDebitRate', state.financing.requestedDebitRate, withOverwrites);

            return (requestedDebitRate ?? 0) <= 0 ? state.financing.financingConfiguration.requestedDebitRateDefault : requestedDebitRate as number;
        }

        return 0;
    }

    /**
     * Liefert den zugeordneten Berechnungsservice für Finanzierungsberechnungen
     *
     * @param {number} calculationVersion Berechnungsversion
     * @returns {FinancingMapCalculationService} Berechnungsservice für Finanzierungsberechnungen
     */
    private static financingMapCalculationService(calculationVersion: number): FinancingMapCalculationService {
        if (FinancingState.financingMapCalculationServices[calculationVersion] === undefined) {
            FinancingState.financingMapCalculationServices[calculationVersion] = new FinancingMapCalculationService(calculationVersion);
        }
        return FinancingState.financingMapCalculationServices[calculationVersion];
    }

    /**
     * Liefert den zugeordneten Berechnungsservice für den alten Produktrechner
     *
     * @param {number} calculationVersion Berechnungsversion
     * @returns {ProductCalculationService} Berechnungsservice für den alten Produktrechner
     */
    private static productCalculationService(calculationVersion: number): ProductCalculationService {
        if (FinancingState.productCalculationServices[calculationVersion] === undefined) {
            FinancingState.productCalculationServices[calculationVersion] = new ProductCalculationService(calculationVersion);
        }
        return FinancingState.productCalculationServices[calculationVersion];
    }

    /**
     * Liefert den zugeordneten Berechnungsservice für den Produktrechner
     *
     * @param {number} calculationVersion Berechnungsversion
     * @returns {ComfortProductCalculationService} Berechnungsservice für den Produktrechner
     */
    private static comfortProductCalculationService(calculationVersion: number): ComfortProductCalculationService {
        if (FinancingState.comfortProductCalculationServices[calculationVersion] === undefined) {
            FinancingState.comfortProductCalculationServices[calculationVersion] = new ComfortProductCalculationService(calculationVersion);
        }
        return FinancingState.comfortProductCalculationServices[calculationVersion];
    }

    /**
     * Liefert den zugeordneten Berechnungsservice für Kreditnehmer
     *
     * @param {number} calculationVersion Berechnungsversion
     * @returns {DebitorCalculationService} Berechnungsservice für Kreditnehmer
     */
    private static debitorCalculationService(calculationVersion: number): DebitorCalculationService {
        if (FinancingState.debitorCalculationServices[calculationVersion] === undefined) {
            FinancingState.debitorCalculationServices[calculationVersion] = new DebitorCalculationService(calculationVersion);
        }
        return FinancingState.debitorCalculationServices[calculationVersion];
    }

    /**
     * Liefert den zugeordneten Berechnungsservice für Haushalte
     *
     * @param {number} calculationVersion Berechnungsversion
     * @returns {HouseholdCalculationService} Berechnungsservice für Haushalte
     */
    private static householdCalculationService(calculationVersion: number): HouseholdCalculationService {
        if (FinancingState.householdCalculationServices[calculationVersion] === undefined) {
            FinancingState.householdCalculationServices[calculationVersion] = new HouseholdCalculationService(calculationVersion);
        }
        return FinancingState.householdCalculationServices[calculationVersion];
    }

    /**
     * Liefert den zugeordneten Berechnungsservice für Neue Verpflichtungen
     *
     * @param {number} calculationVersion Berechnungsversion
     * @returns {NewLiabilityCalculationService} Berechnungsservice für Neue Verpflichtungen
     */
    private static newLiabilityCalculationService(calculationVersion: number): NewLiabilityCalculationService {
        if (FinancingState.newLiabilityCalculationServices[calculationVersion] === undefined) {
            FinancingState.newLiabilityCalculationServices[calculationVersion] = new NewLiabilityCalculationService(calculationVersion);
        }
        return FinancingState.newLiabilityCalculationServices[calculationVersion];
    }

    /**
     * Liefert den zugeordneten Berechnungsservice für Bestehende Verpflichtungen
     *
     * @param {number} calculationVersion Berechnungsversion
     * @returns {LiabilityCalculationService} Berechnungsservice für Bestehende Verpflichtungen
     */
    private static liabilityCalculationService(calculationVersion: number): LiabilityCalculationService {
        if (FinancingState.liabilityCalculationServices[calculationVersion] === undefined) {
            FinancingState.liabilityCalculationServices[calculationVersion] = new LiabilityCalculationService(calculationVersion);
        }
        return FinancingState.liabilityCalculationServices[calculationVersion];
    }

    /**
     * Liefert den zugeordneten Berechnungsservice für das Objekt
     *
     * @param {number} calculationVersion Berechnungsversion
     * @returns {FinancingMapCalculationService} Berechnungsservice für das Objekt
     */
    private static realEstateCalculationService(calculationVersion: number): RealEstateCalculationService {
        if (FinancingState.realEstateCalculationServices[calculationVersion] === undefined) {
            FinancingState.realEstateCalculationServices[calculationVersion] = new RealEstateCalculationService(calculationVersion);
        }
        return FinancingState.realEstateCalculationServices[calculationVersion];
    }

    /**
     * Zustandsänderung beim Verlassen der Finanzierung
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    @Action(FinancingLeaved)
    public financingLeaved({ setState }: StateContext<IFinancingStateModel>): void {
        setState(defaultData);
    }

    /**
     * Zustandsänderung beim Laden der Finanzierung
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingLoaded} action Aktion
     */
    @Action(FinancingLoaded)
    public financingLoaded({ patchState }: StateContext<IFinancingStateModel>, { payload }: FinancingLoaded): void {
        patchState({
            financing: payload,
        });
    }

    /**
     * Zustandsänderung beim Laden des Containers
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingLoaded} action Aktion
     */
    @Action(FinProcessContainerLoaded)
    public containerLoaded({ patchState }: StateContext<IFinancingStateModel>, { payload }: FinProcessContainerLoaded): void {
        patchState({
            finprocessContainer: payload,
        });
    }

    /**
     * FinancingContainerID
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingContainerIDLoaded} action Aktion
     */
    @Action(FinancingContainerIDLoaded)
    public financingConttainerIDLoaded({ patchState }: StateContext<IFinancingStateModel>, { financingContainerID }: FinancingContainerIDLoaded): void {
        patchState({
            financingContainerID: financingContainerID,
        });
    }

    /**
     * RiskFinancingPlansLoaded
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {RiskFinancingPlansLoaded} action Aktion
     */
    @Action(RiskFinancingPlansLoaded)
    public riskFinancingPlansLoaded({ patchState }: StateContext<IFinancingStateModel>, { riskFinancingPlansLoaded }: RiskFinancingPlansLoaded): void {
        patchState({
            riskFinancingPlans: sort(riskFinancingPlansLoaded).asc(it => it.created),
        });
    }

    /**
     * Zustandsänderung beim Erstellen oder Ändern eines Overwrites
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {OverwriteCreatedOrUpdated} action Aktion
     */
    @Action(OverwriteCreatedOrUpdated)
    public overwriteCreatedOrUpdated({ getState, patchState }: StateContext<IFinancingStateModel>, { payload, overwriteId }: OverwriteCreatedOrUpdated): void {
        const financing = getState().financing;
        if (financing !== undefined) {
            const newFinancing = HelperService.clone(financing);
            const entity = OverwriteHelperService.getOverwriteEntityFromFinancingById(newFinancing, {
                overwriteValueClassType: payload.overwriteValueClassType,
                entityId: payload.entityForOverwriteId,
            });
            if (entity !== undefined) {
                let overwrite = OverwriteHelperService.getOverwriteFromEntity(entity, payload.fieldName);
                if (overwrite !== undefined) {
                    overwrite = {
                        ...overwrite,
                        valueType: payload.valueStorageType,
                        value: payload.value,
                    }
                }
                else {
                    overwrite = {
                        id: overwriteId,
                        fieldName: payload.fieldName,
                        valueType: payload.valueStorageType,
                        value: payload.value,
                    }
                }
                entity.overwriteValues = [...entity.overwriteValues.filter(it => it.fieldName !== payload.fieldName), overwrite];
                patchState({
                    financing: newFinancing,
                });
            }
        }
    }

    /**
     * Zustandsänderung beim Löschen eines Overwrites
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {OverwriteDeleted} action Aktion
     */
    @Action(OverwriteDeleted)
    public overwriteDeleted({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: OverwriteDeleted): void {
        const financing = getState().financing;
        if (financing !== undefined) {
            const newFinancing = HelperService.clone(financing);
            const entity = OverwriteHelperService.getOverwriteEntityFromFinancingByOverwriteId(newFinancing, {
                overwriteValueClassType: payload.overwriteValueClassType,
                overwriteId: payload.overwriteValueId,
            });
            if (entity !== undefined) {
                entity.overwriteValues = entity.overwriteValues.filter(it => it.id !== payload.overwriteValueId);
                patchState({
                    financing: newFinancing,
                });
            }
        }
    }

    /**
     * Zustandsänderung beim Speichern eines internen Werts
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {InternalFieldUpdated} action Aktion
     */
    @Action(InternalFieldUpdated)
    public internalFieldUpdated({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: InternalFieldUpdated<IFinancing | IDebitor | IProductPackage>): void {
        const updateEntity = (entity: IFinancing | IDebitor | IProductPackage) => {
            if (payload.valueStorageType === ValueStorageType.Int && payload.value !== undefined && payload.value !== null) {
                entity[payload.fieldName] = parseInt(payload.value.toString(), 10) as unknown as never;
            } else {
                entity[payload.fieldName] = payload.value as unknown as never;
            }
        }

        if (payload.entityClassType === EntityClassType.Debitor || payload.entityClassType === EntityClassType.FinProcessContainer || payload.entityClassType === EntityClassType.FinancingMap) {
            const financing = getState().financing;

            if (financing !== undefined) {
                const newFinancing = HelperService.clone(financing);
                let entity: IFinancing | IDebitor | undefined = newFinancing;

                if (payload.entityClassType === EntityClassType.Debitor) {
                    entity = newFinancing.households.reduce<IDebitor[]>((debitors, currentHousehold) => debitors.concat(currentHousehold.debitors), []).find(deb => deb.id === payload.entityId);
                }

                if (entity === undefined) {
                    return;
                }

                updateEntity(entity);

                patchState({
                    financing: newFinancing,
                });
            }
        } else if (payload.entityClassType === EntityClassType.ProductPackage) {
            const productPackages = getState().productPackages;

            if (productPackages !== undefined) {
                const newProductPackages = HelperService.clone(productPackages);
                const productPackage = newProductPackages.assignProductPackages?.find(it => it.id === payload.entityId);

                if (productPackage === undefined) {
                    return;
                }

                updateEntity(productPackage);

                patchState({
                    productPackages: newProductPackages,
                });
            }
        }
    }

    /**
     * Zustandsänderung beim Aktualisieren des Status
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {StatusUpdated} action Aktion
     */
    @Action(StatusUpdated)
    public statusUpdated({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: StatusUpdated): void {
        const finprocessContainer = getState().finprocessContainer;
        if (finprocessContainer !== undefined) {
            const newFinprocessContainer = HelperService.clone(finprocessContainer);

            newFinprocessContainer.status = payload.status;
            newFinprocessContainer.subStatus = payload.subStatus;
            newFinprocessContainer.statusEntries.unshift(payload);

            patchState({
                finprocessContainer: newFinprocessContainer,
            });
        }
    }

    /**
     * Zustandsänderung beim Laden der Status Einträge
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {StatusLoaded} action Aktion
     */
    @Action(StatusLoaded)
    public statusLoaded({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: StatusLoaded): void {
        const finprocessContainer = getState().finprocessContainer;
        if (finprocessContainer !== undefined) {
            const newFinprocessContainer = HelperService.clone(finprocessContainer);

            newFinprocessContainer.statusEntries = sort(payload).asc(entry => entry.created);
            const statusLength = newFinprocessContainer.statusEntries.length;
            newFinprocessContainer.status = newFinprocessContainer.statusEntries[statusLength - 1].status;
            newFinprocessContainer.subStatus = newFinprocessContainer.statusEntries[statusLength - 1].subStatus;

            patchState({
                finprocessContainer: newFinprocessContainer,
            });
        }
    }

    /**
     * Zustandsänderung beim Laden der Status Einträge
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {RiskFinancingPlansLoaded} action Aktion
     */
    @Action(RiskFinancingPlanStatusLoaded)
    public riskfinancingplanStatusLoaded({ setState }: StateContext<IFinancingStateModel>, { payload }: RiskFinancingPlanStatusLoaded): void {
        const sortedStatusEntries = sort(payload.financingPlanStatusEntry).desc(it => it.created);

        setState(patch({
            financing: patch({
                statusEntries: sortedStatusEntries,
                status: sortedStatusEntries[0]?.status,
            }),
            riskFinancingPlans: updateItem(rfp => rfp.id === payload.riskFinancingId, patch({
                status: sortedStatusEntries[0]?.status,
            })),
        }));
    }

    /**
     * Zustandsänderung beim Aktualisieren des FinancingContainerStatus
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {StatusUpdated} action Aktion
     */
    @Action(StatusUpdatedFinancingContainer)
    public statusUpdatedFinancingContainer({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: StatusUpdatedFinancingContainer): void {
        const financing = getState().finprocessContainer;

        if (financing !== undefined) {
            const newFinancingContainer = HelperService.clone(financing);

            newFinancingContainer.status = payload.status;
            newFinancingContainer.subStatus = payload.subStatus;

            patchState({
                finprocessContainer: newFinancingContainer,
            });
        }
    }
    /**
     * Zustandsänderung beim Laden des Rechenbeispiel Containers
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SampleCalculationContainerLoaded} action Aktion
     */
    @Action(SampleCalculationContainerLoaded)
    public sampleCalculationContainerLoaded({ patchState }: StateContext<IFinancingStateModel>, { payload }: SampleCalculationContainerLoaded): void {
        patchState({
            sampleCalculationContainer: payload,
        });
    }

    /**
     * Zustandsänderung beim Laden des Rechenbeispiel Containers
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SampleCalculationContainerSaved} action Aktion
     */
    @Action(SampleCalculationContainerSaved)
    public sampleCalculationContainerSaved({ patchState }: StateContext<IFinancingStateModel>, { payload }: SampleCalculationContainerSaved): void {
        patchState({
            sampleCalculationContainer: payload,
        });
    }

    /**
     * Zustandsänderung beim Laden des Rechenbeispiel Containers
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {CreditLineCalculationAddedOrUpdated} action Aktion
     */
    @Action(CreditLineCalculationAddedOrUpdated)
    public creditLineCalculationAddedOrUpdated({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: CreditLineCalculationAddedOrUpdated): void {
        const currentStateContainer = getState().sampleCalculationContainer;
        let calculationContainer: ISampleCalculationContainer | undefined;

        if (!!currentStateContainer) {

            const lineCalculation = currentStateContainer?.creditLineCalculations.find(calc => calc.id === payload.id);

            if (!lineCalculation) {
                calculationContainer = {
                    ...currentStateContainer,
                    creditLineCalculations: currentStateContainer.creditLineCalculations.concat([payload]),
                }
            } else {
                calculationContainer = {
                    ...currentStateContainer,
                    creditLineCalculations: currentStateContainer.creditLineCalculations.map(creditLinie => (creditLinie.id === payload.id ? payload : creditLinie)),
                }
            }
        }

        patchState({
            sampleCalculationContainer: calculationContainer,
        });
    }

    /**
     * Fügt Document in Financierung an
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingDocumentUploaded} action Aktion
     */
    @Action(FinancingDocumentUploaded)
    public financingDocumentUploaded({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: FinancingDocumentUploaded): void {
        const financing = getState().financing;

        if (financing !== undefined) {
            const newFinancing = HelperService.clone(financing);

            const newDocument = newFinancing.documents.every(oldDoc => oldDoc.id !== payload.id);

            if (newDocument) {
                newFinancing.documents.push(payload)
            } else {
                const docs = newFinancing.documents.filter(doc => doc.id !== payload.id);
                docs.push(payload);
                newFinancing.documents = docs;
            }

            patchState({
                financing: newFinancing,
            });
        }
    }

    /**
     * Fügt Document in Household an
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingDocumentUploaded} action Aktion
     */
    @Action(HouseholdDocumentUploaded)
    public householdDocumentUploaded({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: HouseholdDocumentUploaded): void {
        const financing = getState().financing;

        if (financing !== undefined) {
            const newFinancing = HelperService.clone(financing);

            const household = newFinancing.households.find(h => h.id === payload.parentId);

            if (!!household) {
                const newDocument = household?.documents.every(oldDoc => oldDoc.id !== payload.id);

                if (newDocument) {
                    household.documents.push(payload)
                } else {
                    const docs = household.documents.filter(doc => doc.id !== payload.id);
                    docs.push(payload);
                    household.documents = docs;
                }

                patchState({
                    financing: newFinancing,
                });
            }
        }
    }

    /**
     * Fügt Document in Debtor an
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingDocumentUploaded} action Aktion
     */
    @Action(DebtorDocumentUploaded)
    public debtorDocumentUploaded({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: DebtorDocumentUploaded): void {
        const financing = getState().financing;

        if (financing !== undefined) {
            const newFinancing = HelperService.clone(financing);

            const debtor = newFinancing.households.find(h => h.debitors.some(d => d.id === payload.parentId))?.debitors.find(dd => dd.id === payload.parentId);

            if (!!debtor) {
                const newDocument = debtor?.documents.every(oldDoc => oldDoc.id !== payload.id);

                if (newDocument) {
                    debtor.documents.push(payload)
                } else {
                    const docs = debtor.documents.filter(doc => doc.id !== payload.id);
                    docs.push(payload);
                    debtor.documents = docs;
                }

                patchState({
                    financing: newFinancing,
                });
            }
        }
    }

    /**
     * Fügt scoring in Debtor an
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingDocumentUploaded} action Aktion
     */
    @Action(DebitorRatingUpdated)
    public debitorRatingUpdated({ setState }: StateContext<IFinancingStateModel>, { payload }: DebitorRatingUpdated): void {
        setState(
            patch<IFinancingStateModel>({
                financing: patch<IFinancing>({
                    households: updateItem(household => household.id === payload.housholdId, patch<IHousehold>({
                        debitors: updateItem(debitor => debitor.id === payload.debitorId, patch<IDebitor>({
                            ratingResult: payload.ratingResult ?? undefined, //ratingResult kann null sein
                        })),
                    })),
                }),
            }),
        )
    }

    /**
     * Fügt scoring in Debtors an
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingDocumentUploaded} action Aktion
     */
    @Action(DebitorsRatingUpdated)
    public debitorsRatingUpdated({ setState }: StateContext<IFinancingStateModel>, { payload }: DebitorsRatingUpdated): void {
        setState(
            patch<IFinancingStateModel>({
                financing: patch<IFinancing>({
                    households: updateItem(household => household.debitors.some(debitor => payload.some(it => it.ndg === debitor.customerNumber)), existing => {
                        const newDebitors = existing.debitors.map(debitor => {
                            const newRatingResult = payload.find(it => it.ndg === debitor.customerNumber);
                            return newRatingResult ? patch<IDebitor>({
                                ratingResult: newRatingResult,
                            })(debitor) : debitor;
                        });

                        return {
                            ...existing,
                            debitors: newDebitors,
                        };
                    }),
                }),
            }),
        )
    }


    /**
     * Löscht Document by type
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {DeleteDebtorFile} action Aktion
     */
    @Action(DeleteDebtorFile)
    public deleteDebtorFile({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: DeleteDebtorFile): void {
        const financing = getState().financing;

        if (financing !== undefined) {
            const newFinancing = HelperService.clone(financing);

            const debtor = newFinancing.households.find(households => households.debitors.some(deb => deb.id === payload.debtorId))?.debitors.find(dd => dd.id === payload.debtorId);

            if (!!debtor) {
                const document = debtor.documents.find(doc => doc.files.some(file => file.id === payload.fileId));

                if (!!document) {
                    const newFiles = document.files.filter(file => file.id !== payload.fileId);

                    if (newFiles.length > 0) {
                        document.files = newFiles;
                    }
                    else {
                        const newDocuments = debtor.documents.filter(doc => doc.id !== document?.id);
                        debtor.documents = newDocuments;
                    }
                }
            }

            patchState({
                financing: newFinancing,
            });
        }
    }

    /**
     * get product packages
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {ProductPackages} action Aktion
     */
    @Action(ProductPackages)
    public productPackages({ patchState }: StateContext<IFinancingStateModel>, { productPackages }: ProductPackages): void {
        productPackages.assignProductPackages.forEach(productPackage => {
            productPackage.id = productPackage.productPackageID;
        });
        patchState({
            productPackages: productPackages,
        });
    }

    /**
     * update product packages
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UpdateProductPackages} action Aktion
     */
    @Action(UpdateProductPackages)
    public updateProductPackages({ getState, patchState }: StateContext<IFinancingStateModel>, { productPackage }: UpdateProductPackages): void {
        const state = getState();
        if (!!state.productPackages) {

            state.productPackages.assignProductPackages.push(productPackage.assignProductPackages[0]);

            patchState({
                productPackages: state.productPackages,
            });
        }
    }

    /**
     * update AcceptedProductPackageLoaded
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AcceptedProductPackageLoaded} action Aktion
     */
    @Action(AcceptedProductPackageLoaded)
    public acceptedProductPackageLoaded({ patchState }: StateContext<IFinancingStateModel>, { acceptedProductPackage }: AcceptedProductPackageLoaded): void {
        patchState({
            acceptedProductPackage: acceptedProductPackage,
        });
    }

    /**
     * update product packages decision
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UpdateProductPackages} action Aktion
     */
    @Action(UpdateProductPackageDecision)
    public updateProductPackageDecision({ getState, dispatch }: StateContext<IFinancingStateModel>, { productPackage }: UpdateProductPackageDecision): void {
        const state = getState();
        const existingPackages = state.productPackages;
        const data = productPackage;

        if (!!data) {
            if (!!existingPackages) {
                const packageExists = existingPackages.assignProductPackages.some(assignProduct => assignProduct.productPackageID === data.assignProductPackages[0].productPackageID);
                //update pp
                if (!packageExists && !!existingPackages) {
                    dispatch(new UpdateProductPackages(data));
                }

                //add pp
                if (!packageExists && !existingPackages) {
                    dispatch(new ProductPackages(data));
                }
            }
            else {
                dispatch(new ProductPackages(data));
            }
        }
    }

    /**
     * Updates the status of a product package
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UpdateProductPackages} action Aktion
     */
    @Action(ProductPackageStatusUpdated)
    public productPackageStatusUpdated({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: ProductPackageStatusUpdated): void {
        const productPackages = getState().productPackages;

        if (!productPackages) {
            return;
        }

        const newProductPackages = HelperService.clone(productPackages);
        const updatedProductPackage = newProductPackages.assignProductPackages.find(productPackage => productPackage.id === payload.productPackageId);

        if (!updatedProductPackage) {
            return;
        }

        updatedProductPackage.status = payload.status.status;
        updatedProductPackage.statusEntries.unshift(payload.status);

        patchState({
            productPackages: newProductPackages,
        });
    }

    /**
     * Updates the list of status entries for a product package
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UpdateProductPackages} action Aktion
     */
    @Action(ProductPackageStatusLoaded)
    public productPackageStatusLoaded({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: ProductPackageStatusLoaded): void {
        const productPackages = getState().productPackages;

        if (!productPackages) {
            return;
        }

        const newProductPackages = HelperService.clone(productPackages);
        const updatedProductPackage = newProductPackages.assignProductPackages.find(productPackage => productPackage.id === payload.productPackageId);

        if (!updatedProductPackage) {
            return;
        }

        updatedProductPackage.statusEntries = sort(payload.statusEntries).asc(it => it.created);
        updatedProductPackage.status = updatedProductPackage.statusEntries[updatedProductPackage.statusEntries.length - 1]?.status;

        patchState({
            productPackages: newProductPackages,
        });
    }

    /**
     * update product packages decision
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UpdateProductPackages} action Aktion
     */
    @Action(AssignedApprover)
    public approverAssigned({ getState, patchState }: StateContext<IFinancingStateModel>, { payload }: AssignedApprover): void {
        const productPackages = getState().productPackages;

        if (!productPackages) {
            return;
        }

        const newProductPackages = HelperService.clone(productPackages);
        const updatedProductPackage = newProductPackages.assignProductPackages.find(productPackage => productPackage.id === payload.productPackageId);

        if (!updatedProductPackage) {
            return;
        }

        updatedProductPackage.status = payload.status.status;
        updatedProductPackage.statusEntries.unshift(payload.status);
        updatedProductPackage.approver = payload.approver;

        patchState({
            productPackages: newProductPackages,
        });
    }
}
