import { ILiabilityDataStateModel } from '../financing-data.module';

import { IAssetStateModel } from './asset/asset.state';
import { IFinancingStateModel } from './financing/financing.state';
import { FinancingTabState, IFinancingTabStateModel } from './financing-tab/financing-tab.state';
import { IRealEstateStateModel } from './real-estate/real-estate.state';

/**
 * Interface für globalen Zustandsspeicher
 */
export interface IFinancingStateParentDefinition {
    /**
     * Zustand für Tabs
     */
     financingTabs: IFinancingTabStateModel;

    /**
     * Zustand der Finanzierung
     */
    financing: IFinancingStateModel;

    /**
     * Zustand der Assets
     */
    asset: IAssetStateModel;

    /**
     * Zustand der Liability Data
     */
    liabilityData: ILiabilityDataStateModel;

    /**
     * realEstates
     */
    realEstates: IRealEstateStateModel;
}

export const FINANCING_PERSISTENT_STATE_KEYS = [FinancingTabState.keyName];
