<finprocess-two-column-layout leftClass="bg-dark-2">
    <div left fxFill class="information-events-container">
        <finprocess-information-events></finprocess-information-events>
    </div>
    <div right fxLayout="column" fxFill>
        <div fxLayout="row wrap" fxLayoutAlign="start center"
            *ngIf="(currentTabSettings$ | async) as currentTabSettings">
            <div *ngIf="visibleDashboards.length > 0">
                <mat-button-toggle-group (change)="dashboardChanged($event)" *ngIf="visibleDashboards.length >= 2">
                    <mat-button-toggle *ngFor="let dashboard of visibleDashboards" [ngClass]="dashboard.colorClass" [class.inactive]="dashboardType !== dashboard.type" [value]="dashboard.type">
                        <span *ngIf="dashboard.type === dashboardType">{{ dashboard.label }}</span>
                        <mat-icon *ngIf="dashboard.type !== dashboardType">dashboard</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="22" fxFlex.lt-md="100" fxFlex.lt-lg ="50">
                <mat-icon class="mr-2">filter_alt</mat-icon>
                <mat-form-field class="w-100 pr-5 mat-form-field-transparent-background" appearance="fill">
                    <mat-select [panelWidth]="330" [value]="currentTabSettings.currentFilter"
                        (selectionChange)="filterStatus(currentTabSettings.tabId, $event)">
                        <mat-option *ngFor="let option of (currentFilterOptions$ | async)" [value]="option">
                            {{option | finprocessEnumTranslation : 'DashboardFilter'}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *finprocessAuthorization="Role.FinancingMapsGlobalReader" fxLayout="row" fxLayoutAlign="start center" fxFlex="22" fxFlex.lt-md="100" fxFlex.lt-lg ="50">
                <mat-icon class="mr-2">filter_alt</mat-icon>
                <mat-form-field class="w-100 pr-5 mat-form-field-transparent-background" appearance="fill">
                    <mat-select  [panelWidth]="330" [(ngModel)]="selectedSalesPartnerCenter" multiple>
                        <mat-optgroup *ngFor="let group of salesPartnerCenterGroups" [label]="group.name" >
                            <mat-option #selectGroup (click)="selectOption(currentTabSettings.tabId, selectGroup)" [value]="group.salesPartnerCenterFilter">
                                <span *ngIf="group.salesPartnerCenterFilter !== SalesPartnerCenterFilter.MobilePF">
                                    {{group.name}}
                                    {{'dashboard.features.dashboard.selected' | translate}}
                                </span>
                                <span *ngIf="group.salesPartnerCenterFilter === SalesPartnerCenterFilter.MobilePF">
                                    {{'dashboard.features.dashboard.selectAll' | translate}}
                                </span>
                            </mat-option>
                            <mat-option #selectSalesPartner (click)="selectOption(currentTabSettings.tabId, selectSalesPartner)" *ngFor="let salesPartnerCenter of group.salesPartnerCenters" [value]="salesPartnerCenter.id">
                                {{salesPartnerCenter.name}}
                              </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="22" fxFlex.lt-md="100" fxFlex.lt-lg ="50">
                <mat-icon class="mr-2">search</mat-icon>
                <mat-form-field class="w-100 pr-5 mat-form-field-transparent-background" appearance="fill">
                    <input name="search" matInput [ngModel]="currentTabSettings.currentSearch"
                        (ngModelChange)="delaySearch(currentTabSettings.tabId, $event)"
                        [placeholder]="'dashboard.features.dashboard.searchPlaceholder' | translate">
                </mat-form-field>
            </div>
            <div class="pt-2" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.lt-lg="center center" fxLayoutGap="12px" fxFlex>
                <ng-container *ngIf="!isLiveEnvironment">
                    <button mat-raised-button color="primary" *finprocessAuthorization="Role.FinancingMapsEditor"
                        (click)="toApiTestPage()">
                        API Test
                    </button>
                </ng-container>
                <ng-container *ngIf="(currentTabSettings.tabId === DashboardTab.All || currentTabSettings.tabId === DashboardTab.My) && dashboardType !== DashboardType.Approver">
                    <button mat-raised-button color="primary" *finprocessAuthorization="Role.FinancingMapsEditor"
                        (click)="assignMe()">
                        {{'dashboard.features.dashboard.requestAssignment' | translate}}
                    </button>
                </ng-container>
                <ng-container *ngIf="currentTabSettings.tabId !== DashboardTab.All && currentTabSettings.tabId !== DashboardTab.My">
                    <button mat-raised-button color="primary" *finprocessAuthorization="Role.FinancingMapsEditor"
                        (click)="removeSubstitution(currentTabSettings.tabId)">
                        {{'dashboard.features.dashboard.endSubstitution' | translate}}
                    </button>
                </ng-container>
            </div>
        </div>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedTabIndex$ | async" [ngClass.sm]="'sm'" [ngClass.md]="'md'"  [ngClass.xs]="'xs'">
            <ng-container *ngIf="dashboardType !== DashboardType.Approver">
                <mat-tab #tabAll *finprocessAuthorization="Role.FinancingMapsGlobalReader" [label]="DashboardTab.All">
                    <ng-template mat-tab-label>{{'dashboard.features.dashboard.allRequests' | translate}}</ng-template>
                    <finprocess-financing-list [tabId]="DashboardTab.All" *ngIf="tabAll.isActive" [loading]="loading" [dashboardType]="dashboardType">
                    </finprocess-financing-list>
                </mat-tab>
            </ng-container>
            <mat-tab #tabMy [label]="DashboardTab.My">
                <ng-template mat-tab-label>{{'dashboard.features.dashboard.myRequests' | translate}}</ng-template>
                <finprocess-financing-list [tabId]="DashboardTab.My" *ngIf="tabMy.isActive" [loading]="loading" [dashboardType]="dashboardType"></finprocess-financing-list>
            </mat-tab>
            <mat-tab *ngFor="let temporaryUserTab of temporaryUserTabs$ | async; let i = index" [label]="temporaryUserTab.tabId">
                <ng-template mat-tab-label>{{ temporaryUserTab.name }}</ng-template>
                <ng-template matTabContent>
                    <finprocess-financing-list [tabId]="temporaryUserTab.tabId" [loading]="loading" [dashboardType]="dashboardType"></finprocess-financing-list>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</finprocess-two-column-layout>
