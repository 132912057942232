import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Language } from '@ntag-ef/finprocess-enums';
import { EntityClassType, FinancingService, IDocument, IFinancing, IFinancingStateParentDefinition, OverwriteValueClassType } from 'app/modules/financing/data';
import { HelperService, IMultiSelectItem } from 'app/modules/shared';
import { Observable, of } from 'rxjs';

import { ISalesPartner } from './../../../../data/interfaces/sales-partner.interface';

/**
 * Komponente für den Tab Allgemein
 */
@Component({
    selector: 'finprocess-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss'],
})
export class GeneralComponent {
    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public OverwriteValueClassType = OverwriteValueClassType;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Observable für readonly Sprachwunsch Feld
     */
    public readonly$ = of(true);

    /**
     * Mehrfachauswahl - Sprachwunsch
     */
    public languageItems: IMultiSelectItem[] = [];

    public entityClassType = EntityClassType;
    
    /**
     * Observable mit dem Vertriebspartner
     */
    @Select((state: IFinancingStateParentDefinition) => (state.financing.financing?.salesPartner))
    public salesPartner$!: Observable<ISalesPartner>;

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing as IFinancing)
    public financing$!: Observable<IFinancing>;


    public documents$: Observable<((documents: IDocument[]) => IDocument[])>;

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     */
    public constructor(private financingService: FinancingService) {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;

        this.documents$ = of(() => []);

        this.languageItems = HelperService.getSortedMultiSelectItemsByValue(Language, value => Language.translate(value));
    }
}
