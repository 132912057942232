<mat-expansion-panel [togglePosition]="'before'" *ngIf="!!assetInsurance" class="bg-dark-2 ml-3 mr-3 pl-4 pr-4 pb-3" hideToggle="true">
  <mat-expansion-panel-header #ex class="mb-3 mt-3">
    <div fxLayout="row" fxLayoutAlign="space-between start" class="mb-2">
      <span fxLayout="row">
        <button class="mt-2" mat-icon-button (click)="assetExpand = !assetExpand">
          <mat-icon class="expand-icon">{{ assetExpand ? 'expand_less' : 'expand_more' }}</mat-icon>
        </button>
        <h3 class="mt-3 mr-2 sub-header"><b>{{'asset.assetInsurance.header' | translate}}</b></h3>
      </span>
      <finprocess-label-value (click)="$event.stopImmediatePropagation()" flexDirection="row" fxLayoutAlign="end start" class="pt-3">
        <span label class="pr-2">{{'financing.features.financing-processing.liabilities.liabilityOverview.active' | translate }}</span>
        <span value>
          <mat-checkbox color="primary" [checked]="assetInsurance.isActive"
          (click)="setAssetActive(assetInsurance.id, !assetInsurance.isActive)" [class.finprocess-readonly]="(fieldReadonly$ | async) ?? true">
        </mat-checkbox>
      </span>
    </finprocess-label-value>
    </div>
    <div class="wrapper"  (click)="$event.stopImmediatePropagation()">
        <finprocess-select-input
          [items]="mortgageTypeSelectItems"
          [overwrite]="false"
          [entityClassType]="entityClassType.AssetInsurance"
          [hideHorizontalRule]="true"
          fieldName="insuranceType"
          [readonly]="fieldReadonly$"
          [entity]="assetInsurance"
          [showTooltip]="true">
          <span label class="input-label">{{'asset.assetInsurance.insuranceType' | translate}}</span>
      </finprocess-select-input>
  
        <finprocess-decimal-input [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
          fieldName="insuranceSum" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
          [readonly]="fieldReadonly$"
          [entity]="assetInsurance">
          <span label class="input-label">{{'asset.assetInsurance.insuranceSum' | translate}}</span>
        </finprocess-decimal-input>
  
        <finprocess-decimal-input [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
          fieldName="deathSum" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
          [readonly]="fieldReadonly$"
          [entity]="assetInsurance">
          <span label class="input-label">{{'asset.assetInsurance.deathSum' | translate}}</span>
        </finprocess-decimal-input>
  
        <finprocess-textbox-input  [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
            fieldName="insuranceCompanyName" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$"
            [entity]="assetInsurance">
            <span label class="input-label">{{'asset.assetInsurance.insuranceCompanyName' | translate}}</span>
        </finprocess-textbox-input>
  
        <finprocess-decimal-input [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
          fieldName="materialValueTotal" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
          [readonly]="alwaysReadonly$"
          [entity]="assetInsurance">
          <span label class="input-label">{{'asset.assetInsurance.materialValueTotal' | translate}}</span>
        </finprocess-decimal-input>
    </div>
  </mat-expansion-panel-header>
      <!-- um das body lazy zu rendern brauchen wir eine reference zum panel-header -->
  <ng-template matExpansionPanelContent>
    <ng-container *ngIf="ex._isExpanded">
      <mat-divider class="mt-4"></mat-divider>
      <div class="mt-3 mb-3">
    
        <h3 class="mt-3">{{'asset.assetCommon.otherInfos' | translate}}</h3>
        <span class="wrapper-2">
          <finprocess-textbox-input  
            [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
            fieldName="redemptionValueCurrency" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$"
            [entity]="assetInsurance">
            <span label class="input-label">{{'asset.assetInsurance.redemptionValueCurrency' | translate}}</span>
          </finprocess-textbox-input>
    
          <finprocess-decimal-input 
            [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
            fieldName="currentCollateralValue" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$"
            [entity]="assetInsurance">
            <span label class="input-label">{{'asset.assetInsurance.currentCollateralValue' | translate}}</span>
          </finprocess-decimal-input>
    
          <finprocess-decimal-input 
            [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
            fieldName="currentCollateralValuePer" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$"
            [entity]="assetInsurance">
            <span label class="input-label">{{'asset.assetInsurance.currentCollateralValuePer' | translate}}</span>
          </finprocess-decimal-input>
    
          <finprocess-textbox-input 
            [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
            fieldName="applicationNo" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$"
            [entity]="assetInsurance">
            <span label class="input-label">{{'asset.assetInsurance.applicationNo' | translate}}</span>
          </finprocess-textbox-input>
    
          <finprocess-decimal-input *ngIf="showMaterialValues" style="margin-top: 5%;" class="input-fit-dates"
            [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
            fieldName="materialValueAlreadyInUse" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$"
            [entity]="assetInsurance">
            <span label class="input-label">{{'asset.assetCommon.materialValueAlreadyInUse' | translate}}</span>
          </finprocess-decimal-input>
    
          <finprocess-decimal-input *ngIf="showMaterialValues" style="margin-top: 5%;"
            [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
            fieldName="freeMaterialValue" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            class="finprocess-readonly-opacity input-fit-dates"
            [entity]="assetInsurance">
            <span label class="input-label">{{'asset.assetCommon.freeMaterialValue' | translate}}</span>
          </finprocess-decimal-input>
    
          <finprocess-datepicker-input class="date-input" 
            [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
            [entity]="assetInsurance" 
            [readonly]="fieldReadonly$"
            fieldName="insuranceStart" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true">
            <span label class="input-label">{{'asset.assetInsurance.insuranceStart' | translate}}</span>
          </finprocess-datepicker-input>
    
          <finprocess-datepicker-input class="date-input" 
            [overwrite]="false" [entityClassType]="entityClassType.AssetInsurance"
            [entity]="assetInsurance" 
            [readonly]="fieldReadonly$"
            fieldName="insuranceEnd" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true">
            <span label class="input-label">{{'asset.assetInsurance.insuranceEnd' | translate}}</span>
          </finprocess-datepicker-input>
        </span>
      </div>
      <span fxLayout="column" class="body">
        <finprocess-asset-provider style="margin-top: 20px;" 
            [assetProviders]="assetInsurance.assetProviders"
            [assetId]="assetInsurance.id"
            [entityClassType]="entityClassType.AssetInsurance"></finprocess-asset-provider>
        <mat-divider class="mb-3" style="margin-top: 30px;"></mat-divider>
        <finprocess-asset-agp  [assetsAgps]="assetInsurance.assetsAgp"></finprocess-asset-agp>
      </span>
    </ng-container>
  </ng-template>
  </mat-expansion-panel>