import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { sort } from 'fast-sort';
import { Observable, Subject, combineLatest, map, of } from 'rxjs';

import { FinancingState, IDocument, IDocumentView} from '../../../../data';


/**
 * Komponente für den Tab Belege
 */
@Component({
    selector: 'finprocess-document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit {

    @Input()
    public documentFunction?: Observable<((documents: IDocument[]) => IDocument[])>

    public specificDocuments$!: Observable<IDocumentView[]>;
    public otherDocuments$!: Observable<IDocumentView[]>;



    public showAll = false;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Standard Konstruktor
     *
     * @param {Store} store Store Injektor
     */
    public constructor(
        private store: Store,
    ) {}

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        if (!this.documentFunction) {
            this.documentFunction = of(documents => documents);
        }

        this.specificDocuments$ = combineLatest([this.store.select(FinancingState.allDocuments), this.documentFunction])
            .pipe(
                map(([documents, documentFunction]) => {
                    const filteredDocuments = documentFunction(documents);
                    return sort(filteredDocuments.map(document => this.store.selectSnapshot(FinancingState.documentViewModel)(document)))
                        .asc([
                            doc => DocumentType.translate(doc.document.type),
                            doc => doc.position,
                        ]);
                }),
            );

        this.otherDocuments$ = combineLatest([this.store.select(FinancingState.allDocuments), this.specificDocuments$])
            .pipe(
                map(([allDocuments, specificDocuments]) => {
                    const otherDocuments = allDocuments.filter(document => !specificDocuments.some(doc => doc.document.id === document.id));
                    return sort(otherDocuments.map(document => this.store.selectSnapshot(FinancingState.documentViewModel)(document)))
                        .asc([
                            doc => DocumentType.translate(doc.document.type),
                            doc => doc.position,
                        ]);
                }),
            );
    }

    /**
     * Schaltet zwischen allen Dokumente und den entitätsbezogenen Dokumenten um
     */
    public toggleShowAll(): void {
        this.showAll = !this.showAll;
    }
}
