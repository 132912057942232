import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { AssetService, FinancingService, IRealEstateDetailDialogData } from 'app/modules/financing/data';
import { IAssetRealEstate } from 'app/modules/financing/data/interfaces';
import { HelperService, ISelectItem } from 'app/modules/shared';

import { RealEstateDetailComponent } from '..';
import { RealEstateType } from '../../../../../../../../../../libs/enums/src/core/enums';
import { AppraisalType, PropertyType, TypeOfShare } from '../../../colt/enums';
import { AssetCommonComponent } from '../asset-common/asset-common';

/**
 * Asset Real Estate
 */
@Component({
    selector: 'finprocess-asset-real-estate',
    templateUrl: './asset-real-estate.component.html',
    styleUrls: ['./asset-real-estate.component.scss'],
})
export class AssetRealEstateComponent extends AssetCommonComponent implements OnInit {

    @Input()
    public assetRealEstate: IAssetRealEstate | undefined;

    public objectTypeSelectItems: ISelectItem<number>[] = [];

    public showMaterialValues = true; //TODO Luisa
   
    public typeOfShareSelectItems: ISelectItem<number>[] = [];

    public propertyTypeSelectItems: ISelectItem<number | null>[] = [];

    public valuationTypeSelectItems: ISelectItem<number>[] = [];

    public assetExpand = false;

    /**
     * Konstruktor
     * 
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param { AssetService } assetService AssetService
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService financing service
     * @param {MatDialog} dialog MatDialog-Injektor
     */
    public constructor(
        enumTranslate: EnumTranslationPipe,
        assetService: AssetService,
        store: Store,
        financingService: FinancingService,
        private dialog: MatDialog,
    ) {
        super(
            assetService,
            enumTranslate,
            store,
            financingService,
        )
    }

    /**
     * OnInit
     */
    public ngOnInit() {
        this.objectTypeSelectItems = HelperService.getSortedSelectItems(RealEstateType, value => this.enumTranslate.transform(value, 'RealEstateType') as string, [], true);
        
        this.typeOfShareSelectItems = HelperService.getSortedSelectItems(TypeOfShare, value => this.enumTranslate.transform(value, 'TypeOfShare') as string, [TypeOfShare.NewProperty], true);
        
        this.propertyTypeSelectItems = HelperService.getSortedSelectItems(PropertyType, value => this.enumTranslate.transform(value, 'PropertyType') as string, [PropertyType.Property], true);
        this.propertyTypeSelectItems.push( { displayName: 'Keine Auswahl', value: null });

        this.valuationTypeSelectItems = HelperService.getSortedSelectItems(AppraisalType, value => this.enumTranslate.transform(value, 'AppraisalType') as string, [], true);
    }

    /**
     * openRealestateDetail
     * 
     * @param { MouseEvent } event MouseEvent
     * @param { string } realestateId realestateId 
     */
    public openRealestateDetail(event: MouseEvent, realestateId: string) {
        event.stopImmediatePropagation();
        const dialogData: IRealEstateDetailDialogData = { realestateId: realestateId, isNotAssetData: false, onlyOne: true };
        //da weiter zentriert wurde, height findet sich auch in global styles und muss dort angepasst werden
        this.dialog.open(RealEstateDetailComponent, { minWidth: '60vw', minHeight: '75vh', data: dialogData });
    }
}
