<finprocess-two-column-layout leftFxLayoutAlign="start start" rightFxLayoutAlign="center center"
    [rightFxFlex]="40" [leftFxFlex]="60" rigthClass="pb-0 pt-3 px-2" leftClass="pb-0 pt-3 px-2">
    <div left>
        <ng-container *ngIf="(financing$ | async) as financing">
            <finprocess-sub-header
                [label]="'financing.features.financing-processing.formal.subHeaderGeneral' | translate">
            </finprocess-sub-header>

            <finprocess-textarea-input [label]="'financing.features.financing-processing.general.statement' | translate" [placeholder]="'...'"
                [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                [readonly]="fieldReadonly$" 
                [entity]="financing" 
                fieldName="creditApplicationStatement"
                [overwrite]="false"
                [entityClassType]="entityClassType.FinProcessContainer"
                >
            </finprocess-textarea-input>

            <finprocess-multi-select-input
                [items]="languageItems"
                [label]="'financing.features.financing-processing.general.requestedLanguages' | translate"
                [readonly]="readonly$"
                [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                [entity]="financing" fieldName="requestedLanguages"
                [autoCheckNone]="true">
            </finprocess-multi-select-input>
        </ng-container>
        <mat-divider></mat-divider>
        <ng-container *ngIf="(salesPartner$ | async) as salesPartner">
            <finprocess-sub-header
                [label]="'financing.features.financing-processing.formal.subHeaderStandardInfo' | translate">
            </finprocess-sub-header>

            <finprocess-textbox-input
                [label]="'financing.features.financing-processing.firstName' | translate"
                [entity]="salesPartner" fieldName="firstName"
                [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <finprocess-textbox-input
                [label]="'financing.features.financing-processing.lastName' | translate"
                [entity]="salesPartner" fieldName="lastName"
                [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <finprocess-textbox-input [label]="'financing.features.financing-processing.formal.salesPartner.phoneNumber' | translate"
                [overwriteValueClassType]="OverwriteValueClassType.SalesPartnerOverwriteValue"
                [readonly]="fieldReadonly$" [entity]="salesPartner" fieldName="phoneNumber">
            </finprocess-textbox-input>
        </ng-container>
    </div>
    <div right fxFill>
        <finprocess-document-viewer [documentFunction]="documents$"></finprocess-document-viewer>
    </div>
</finprocess-two-column-layout>
