import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { EditorConfig, WordCount } from 'ckeditor5';

interface ICKEditorEvent {
    editor: {
        isReadOnly: boolean;
        getData: () => string;
    };
}

/**
 * add and edit notes
 */
@Component({
    selector: 'finprocess-add-household-notes-dialog',
    templateUrl: './add-household-notes-dialog.component.html',
    styleUrls: ['./add-household-notes-dialog.component.scss'],
})
export class AddHouseholdNotesDialogComponent {

    public editor = ClassicEditor;
    public editorData!: string;
    public charsRemaining = 0;
    public maxCharCount = 4000;
    public blockSaveButton = false;
    public maxCharactersExceeded = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public editorConfig: any = {
        toolbar: {
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'undo',
                'redo',
            ],
        },
        // TODO: Reactivate word count plugin
        // plugins: [WordCount],
        // wordCount: {
        //     displayCharacters: true,
        //     onUpdate: ({ characters }: { characters: number }) => {
        //         this.blockSaveButton = characters > this.maxCharCount;
        //     },
        // },
    };

    /**
     * Konstruktor
     * 
     * @param {MatDialogRef} dialogRef dialog ref
     * @param {string} data data
     * @param {string} data.previousNote previous saved note
     */
    public constructor(public dialogRef: MatDialogRef<AddHouseholdNotesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { previousNote: string }) {

        if (data.previousNote && data.previousNote.length > 0) {
            this.editorData = data.previousNote;
            this.checkCharCount();
        }
    }

    /**
     * save note input
     */
    public saveNote() {
        if (this.editorData.length && this.editorData.length > 0) {
            this.dialogRef.close({ note: this.editorData });
        }
        else {
            this.dialogRef.close({});
        }
    }

    /**
     * onEditorChange
     *
     * @param {ICKEditorEvent} event event
     */
    public onEditorChange(event: ICKEditorEvent) {
        this.editorData = event.editor.getData();
        this.checkCharCount();
    }

    /**
     * check character count of editor content
     */
    public checkCharCount() {
        // Extract the text content from the element.
        const tempElement = document.createElement('div');
        tempElement.innerHTML = this.editorData;
        const textContent = tempElement.textContent || '';

        // Calculate the number of characters in the text content.
        const charCount = textContent.length;

        // Calculate the number of characters remaining.
        this.charsRemaining = charCount;

        // Check if the number of characters has exceeded the maximum allowed.
        if (charCount > this.maxCharCount) {
            this.blockSaveButton = true;
        } else {
            this.blockSaveButton = false;
        }
    }
}
