import { EntityClassType } from '../../enums';
import { IAssetData, IAssetProvider } from '../../interfaces';

/**
 * Aktion beim Laden der Assets
 */
export class AssetsLoaded {
    public static readonly type = 'asset.loaded';

    /**
     * Konstruktor 
     *
     * @param {IAssetData} payload Daten der Assets
     */
    public constructor(public payload: IAssetData) { }
}

/**
 * Aktion beim Laden der Collaterals
 */
export class CollateralLoaded {
    public static readonly type = 'collateral.loaded';

    /**
     * Konstruktor 
     *
     * @param {IAssetData} payload Daten der Collaterals
     */
    public constructor(public payload: IAssetData) { }
}

/**
 * Aktion beim Laden der Assets
 */
export class AssetsCreate {
    public static readonly type = 'asset.create';

    /**
     * Konstruktor 
     *
     * @param {IAssetData} payload Daten der Assets
     */
    public constructor(public payload: IAssetData) { }
}

/**
 * Aktion beim Laden der Collateral
 */
export class CollateralCreate {
    public static readonly type = 'collateral.create';

    /**
     * Konstruktor 
     *
     * @param {IAssetData} payload Daten der Collaterals
     */
    public constructor(public payload: IAssetData) { }
}

/**
 * Aktion beim Laden der Assets
 */
export class AssetsActive {
    public static readonly type = 'asset.active';

    /**
     * Konstruktor 
     *
     * @param {IAssetData} payload Daten der Assets
     */
    public constructor(public payload: IAssetData) { }
}


/**
 * Aktion beim update der Assets-Provider
 */
export class AssetsAddProvider {
    public static readonly type = 'asset.assetsAddProvider';

    /**
     * Konstruktor 
     *
     * @param {object} payload Payload
     * @param {IAssetData} payload.assetProvider Daten der IAssetProvider
     * @param {EntityClassType} payload.entityClassType EntityClassType
     */
    public constructor(public payload: {assetProvider: IAssetProvider, entityClassType: EntityClassType}) { }
}

/**
 * Aktion beim update der Assets-Provider
 */
export class AssetsDeleteProvider {
    public static readonly type = 'asset.assetsDeleteProvider';

    /**
     * Konstruktor 
     *
     * @param {object} payload Payload
     * @param {string} payload.assetId assetId
     * @param {string} payload.assetProviderId assetProviderId
     * @param {EntityClassType} payload.entityClassType EntityClassType
     */
    public constructor(public payload: {assetId: string, assetProviderId: string, entityClassType: EntityClassType}) { }
}

/**
 * Clear Collateral State
 */
export class ClearCollateral {
    public static readonly type = 'collateral.clear';
}
