import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AssetProviderService, AssetService, DocumentService, FinancingService, LiabilitiesService, RealEstateService, SampleCalculationService, ScoringService, VerfiedCustomerService } from './services';
import { AssetState, DocumentState, FinancingState, FinancingTabState, LiabilityState, RealEstateState } from './states';

export * from './enums';
export * from './interfaces';
export * from './states';
export * from './services';

/**
 * Modul für das Laden und Bearbeiten einer Finanzierung
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([DocumentState, FinancingTabState, FinancingState, AssetState, LiabilityState, RealEstateState]),
    ],
    providers: [
        DocumentService,
        FinancingService,
        LiabilitiesService,
        SampleCalculationService,
        VerfiedCustomerService,
        ScoringService,
        AssetService,
        AssetProviderService,
        RealEstateService,
    ],
})
export class FinancingDataModule { }
