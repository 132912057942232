<h1 mat-dialog-title class="mb-5 cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{'financing.features.financing-processing.householdBills.addNote' | translate }}</h1>

<div mat-dialog-content class="mb-3">

    <ckeditor [editor]="editor" [data]="editorData" [config]="editorConfig" (change)="onEditorChange($event)">
    </ckeditor>
    <div>{{charsRemaining}} / {{maxCharCount}}</div>
    <div *ngIf="blockSaveButton" class="warning-text">
        {{'financing.features.financing-processing.householdBills.warningNote' | translate: {charsRemaining:
        charsRemaining - maxCharCount} }}
    </div>
</div>

<div mat-dialog-actions class="dialog-actions">
    <button mat-raised-button mat-dialog-close [disabled]="blockSaveButton" color="primary"
        (click)="saveNote()">{{'financing.features.financing-processing.productPackages.texts.save' |
        translate}}</button>
    <button mat-raised-button mat-dialog-close>{{'financing.features.financing-processing.productPackages.texts.cancel'
        |
        translate}}</button>
</div>