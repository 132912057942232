<div class="mb-5">

    <div class="mb-5" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20">
        <h3 class="titles"><b>{{'financing.features.financing-processing.risk-decision.fireSelection' | translate}}</b>
        </h3>
        <mat-radio-group [(ngModel)]="fireOptions" (change)="onFireOptionChange(fireOptions)"
            [disabled]="(fieldReadonly$ | async) ?? true">
            <mat-radio-button [disabled]="isRiskDecisionFromApi === false" class="mr-3" color="primary" name="fireAPI"
                value="1">{{'financing.features.financing-processing.risk-decision.fireAPI' | translate}}
            </mat-radio-button>
            <mat-radio-button [disabled]="isRiskDecisionFromApi === true" color="primary" name="fireManual"
                value="2">{{'financing.features.financing-processing.risk-decision.fireManuell' |
                translate}}</mat-radio-button>
        </mat-radio-group>
    </div>

    <!--fire über schnittstellen einreichen -->
    <finprocess-generic-form-risk-decision *ngIf="fireAPI" [data]="data$">
         </finprocess-generic-form-risk-decision>

            <!--manuell fire eintragen-->
            <div *ngIf="fireManual" class="ml-3 mr-3">

                <form [formGroup]="fireForm" fxLayout="column" fxFlex="50"
                    [class.finprocess-readonly]="(fieldReadonly$ | async) ?? true">
                    <mat-form-field appearance="fill" class="mr-3" fxFlex="100">
                        <mat-label>{{'financing.features.financing-processing.risk-decision.reasonCode' |
                            translate}}</mat-label>
                        <mat-select formControlName="reasonCode">
                            <mat-option *ngFor="let reasonCode of reasonCodes" [value]="reasonCode">
                                {{reasonCode | finprocessEnumTranslation:'ReasonCode'}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{ fireForm.get('reasonCode')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" fxFlex="100" class="mr-3 finprocess-readonly-opacity">
                        <mat-label>{{'financing.features.financing-processing.risk-decision.reasonCodeText' |
                            translate}}</mat-label>
                        <input matInput formControlName="ergTextStatusFinal" readonly>
                        <mat-error>
                            {{ fireForm.get('ergTextStatusFinal')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="mr-3" fxFlex="100">
                        <mat-label>{{'financing.features.financing-processing.risk-decision.addCode' |
                            translate}}</mat-label>
                        <mat-select formControlName="ergAdditionalCode">
                            <mat-option *ngFor="let additionalCode of additionalCodes" [value]="additionalCode">
                                {{additionalCode | finprocessEnumTranslation:'AdditionalCode'}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{ fireForm.get('ergAdditionalCode')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" fxFlex="100" class="mr-3 finprocess-readonly-opacity">
                        <mat-label>{{'financing.features.financing-processing.risk-decision.addCodeText' |
                            translate}}</mat-label>
                        <input matInput formControlName="ergTextAdditionalStatus" readonly>
                        <mat-error>
                            {{ fireForm.get('ergTextAdditionalStatus')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="mr-3" fxFlex="100">
                        <mat-label>{{'financing.features.financing-processing.risk-decision.fireID' |
                            translate}}</mat-label>
                        <input matInput formControlName="proposalId">
                        <mat-error>
                            {{ fireForm.get('proposalId')?.errors | finProcessFormError}}
                        </mat-error>
                        <button *ngIf="isTestEnvironment" mat-icon-button matSuffix (click)="generateCubeId($event)">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </mat-form-field>
                    
                    <mat-form-field appearance="fill" class="mr-3" fxFlex="100">
                        <mat-label>{{'financing.features.financing-processing.risk-decision.belq' |
                            translate}}</mat-label>
                        <input matInput formControlName="kpiBelq" currencyMask [options]="percentageMaskOptions">
                        <mat-error>
                            {{ fireForm.get('kpiBelq')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="mr-3" fxFlex="100">
                        <mat-label>{{'financing.features.financing-processing.risk-decision.dsti' |
                            translate}}</mat-label>
                        <input matInput formControlName="kpiDsti" currencyMask [options]="percentageMaskOptions">
                        <mat-error>
                            {{ fireForm.get('kpiDsti')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="mr-3" fxFlex="100">
                        <mat-label>{{'financing.features.financing-processing.risk-decision.ltv' |
                            translate}}</mat-label>
                        <input matInput formControlName="kpiLtv" currencyMask [options]="percentageMaskOptions">
                        <mat-error>
                            {{ fireForm.get('kpiLtv')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="mr-3" fxFlex="100">
                        <mat-label>{{'financing.features.financing-processing.risk-decision.kimv' |
                            translate}}</mat-label>
                        <input matInput formControlName="kpiKimVRelSchuldq" currencyMask
                            [options]="percentageMaskOptions">
                        <mat-error>
                            {{ fireForm.get('kpiKimVRelSchuldq')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="mr-3" fxFlex="100">
                        <mat-label>{{'financing.features.financing-processing.risk-decision.totalCommitment' |
                            translate}}</mat-label>
                        <input matInput formControlName="totalCommitment" currencyMask
                            [options]="currencyMaskOptions">
                        <mat-error>
                            {{ fireForm.get('totalCommitment')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="mr-3" fxFlex="100">
                        <mat-label>{{'financing.features.financing-processing.risk-decision.totalCommitmentNoGuranatee' |
                            translate}}</mat-label>
                        <input matInput formControlName="totalCommitmentNoGuarantee" currencyMask
                            [options]="currencyMaskOptions">
                        <mat-error>
                            {{ fireForm.get('totalCommitmentNoGuarantee')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>

                    <!-- fire ergebnis pdf hochladen-->
                    <a fxFlex="row" fxFlexAlign="center" *ngIf="!fireDocumentData && !uploadedData" mat-raised-button
                        href="javascript:void(0)" color="primary" class="mr-2 mb-3 btn-fix-flex" style="width: fit-content"
                        (click)="file.click()">
                        <input accept="application/pdf" style="display: none;" type="file" #file [multiple]="false"
                            (change)="uploadFireDocument($event.target, false)" />
                        {{'financing.features.financing-processing.risk-decision.uploadManualFirePDF' | translate}}
                    </a>

                    <!-- hochgeladene fire ergebnis pdf anzeigen & ersetzen-->
                    <div *ngIf="uploadedData && !fireDocumentData" class="mb-3 mr-3 uploaded-pdf btn-fix-flex" fxLayout="row">
                        <mat-icon class="mr-1 document-icon">description</mat-icon>
                        <div class="mr-3">{{uploadedData.file.name}}</div>
                        <mat-icon class="icons" href="javascript:void(0)" matTooltip="Ersetzen" (click)="file.click()">
                            swap_horiz
                            <input accept="application/pdf" style="display: none;" type="file" #file [multiple]="false"
                                (change)="uploadFireDocument($event.target, false)" />
                        </mat-icon>
                    </div>

                    <!--fire formular speichern-->
                    <button *ngIf="!fireDocumentData" mat-raised-button class="mr-3 btn-fix-flex" color="primary"
                        [disabled]="fireForm.invalid || !uploadedData"
                        (click)="saveManualFireData()">{{'financing.features.financing-processing.risk-decision.save' |
                        translate}}</button>
                    <button *ngIf="fireDocumentData" mat-raised-button class="mr-3 btn-fix-flex" color="primary"
                        [disabled]="fireForm.invalid"
                        (click)="saveManualFireData()">{{'financing.features.financing-processing.risk-decision.save' |
                        translate}}</button>
                </form>

                <mat-divider [vertical]="true"></mat-divider>

                <!--hochgeladenes fire document anzeigen & ersetzen-->
                <div *ngIf="this.fireDocumentData?.fileId" fxLayout="column" fxLayoutAlign="start center" fxFlex="50">
                    <h3 class="titles"><b>{{'financing.features.financing-processing.risk-decision.uploadedFireDocument'
                            | translate}}</b></h3>
                    <div class="mb-3 uploaded-pdf" fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="mr-1 document-icon">description</mat-icon>
                        <div class="mr-5">{{fireDocumentData?.fileName}}.pdf</div>

                        <button mat-icon-button class="mr-2 icons" 
                            (click)="downloadFireDocument()" 
                            [disabled]="(fieldReadonly$ | async) ?? true">
                               <mat-icon matTooltip="Öffnen">open_in_new</mat-icon>
                        </button>

                        <button mat-icon-button class="icons" 
                            (click)="file.click()"
                            [disabled]="(fieldReadonly$ | async) ?? true">
                            <mat-icon  href="javascript:void(0)" matTooltip="Ersetzen">
                                swap_horiz
                                <input accept="application/pdf" style="display: none;" type="file" #file
                                    [multiple]="false" (change)="uploadFireDocument($event.target, true)" />
                            </mat-icon>
                        </button>
                    </div>
                </div>

            </div>
</div>