import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AssetService, EntityClassType, FinancingService, IAssetInsurance, IProductPackageData } from 'app/modules/financing/data';
import { ICollateralInsuranceDetail } from 'app/modules/financing/data/interfaces/collateral-interfaces/collateral-insurance-detail.interface';
import { AssignmentType, MortgageType } from 'app/modules/financing/features/colt/enums';
import { HelperService, ISelectItem } from 'app/modules/shared';
import { Observable, of, take } from 'rxjs';

import { ProductPackageType } from '../../../../../../../../../../../libs/enums/src/core/enums';
import { EnumTranslationPipe } from '../../../../../../../../../../../libs/enums/src/lib/pipes/enum-translation/enum-translation.pipe';
import { AssetCommonComponent } from '../../asset-common/asset-common';
import { ICollateralTableInterface } from '../collateral-real-estate-details/collateral-real-estate-details.component';

/**
 * Versicherung Sicherheitendetails
 */
@Component({
    selector: 'finprocess-collateral-insurance-details',
    templateUrl: './collateral-insurance-details.component.html',
    styleUrls: ['./collateral-insurance-details.component.scss'],
})
export class CollateralInsuranceDetailsComponent extends AssetCommonComponent implements OnInit {

    /**
     * Collateral Insurance Daten
     */
    @Input()
    public collateralInsuranceDetail?: ICollateralInsuranceDetail[] | undefined | null;

    /**
     * Asset Insurance Daten
     */
    @Input()
    public assetInsurance?: IAssetInsurance[] | undefined;

    /**
     * ProductPackage
     */
    @Input()
    public productPackage?: IProductPackageData;

    /**
     * Genehmigungsseite
     */
    @Input()
    public approvalView = false;

    public entityClassType = EntityClassType;
    public inputFlexDirection = 'column';

    /**
     * Mat expansion Expand
     */
    public collateralInsuranceDetailExpand: Record<string, boolean> = {};

    /**
     * Mat expansion Expand zugeordnete Versicherungen
     */
    public assetInsuranceExpand: Record<string, boolean> = {};

    /**
     * Observable Schreibschutz
     */
    public editingReadonly$!: Observable<boolean>;

    /**
     * Observable Schreibschutz
     */
    public editingReadonlyApprovalView$!: Observable<boolean>;

    public displayedColumns: string[] = ['position', 'name', 'duration', 'amount', 'collateralized'];
    public dataSources: Map<ICollateralInsuranceDetail, ICollateralTableInterface[]> = new Map();

    /**
     * Dropdown AssignmentTyoe
     */
    public assignmentTypeControl?: ISelectItem<number>[] = [];

    /**
     * Dropdown ConfirmationType
     */
    public booleanItems?: ISelectItem<boolean>[] = [];

    public mortgageTypeSelectItems: ISelectItem<number>[] = [];

    /**
     * selected Insurance
     */
    public selectedInsurances: IAssetInsurance[] = [];

    /**
     * FinancingMapID
     */
    public financingMapID?: string;

    /**
     * ProductPackageID
     */
    public productPackageID?: string;

    /**
     * Konstruktor
     * 
     * @param {FinancingService} financingService financingservice
     * @param {TranslateService} translate TranslateService
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param {AssetService} assetService assetservice
     * @param {WaiterService} waiterService waiterService
     * @param {NotificationService} notificationService notficationservice
     * @param {ChangeDetectorRef} changeDetection change detection
     * @param {Store} store store
     */
    public constructor(financingService: FinancingService, private translate: TranslateService,
        enumTranslate: EnumTranslationPipe, assetService: AssetService, private waiterService: WaiterService,
        private notificationService: NotificationService, private changeDetection: ChangeDetectorRef, store: Store) {
        super(
            assetService,
            enumTranslate,
            store,
            financingService,
        )
    }

    /**
     * Initialisierung
     */
    public ngOnInit() {

        this.editingReadonly$ = this.approvalView ? of(true) : this.financingService.editingReadonlyWithEditmodeExpert$;
        this.editingReadonlyApprovalView$ = this.financingService.editingReadonlyWithEditmodeExpert$;

        this.assignmentTypeControl = HelperService.getSortedSelectItems(AssignmentType, value => this.enumTranslate.transform(value, 'AssignmentTypeValue') as string, [], true);
        this.mortgageTypeSelectItems = HelperService.getSortedSelectItems(MortgageType, value => this.enumTranslate.transform(value, 'MortgageType') as string, [], true);

        this.booleanItems = [true, false].map(value => ({
            value: value,
            displayName: value ? 'Ja' : 'Nein',
        }));
        this.defineDataSource();
    }

    /**
     * define Data Source
     */
    public defineDataSource() {
        // Create a map to hold the data source for each real estate
        const dataSources: Map<ICollateralInsuranceDetail, ICollateralTableInterface[]> = new Map();

        if (!!this.productPackage) {
            this.financingMapID = this.productPackage.financingMapID;
            const products = this.productPackage?.assignProductPackages[0].assignProducts;

            this.productPackageID = this.productPackage.assignProductPackages[0].id;

            // Iterate over each real estate
            this.collateralInsuranceDetail?.forEach(detail => {
                const newData: ICollateralTableInterface[] = [];

                //Iterate over products
                products?.forEach(element => {
                    let isCollateralized = false;

                    // Find all productAssignmentInfos with the same productID
                    const productAssignmentInfos = detail.productAssignmentInfos?.find(info => info.productId === element.productID);

                    // If any productAssignmentInfo is found and isUsedAsCollateralForProduct is true, set isCollateralized to true
                    if (productAssignmentInfos && productAssignmentInfos.isUsedAsCollateralForProduct === true) {
                        isCollateralized = true;
                    }
                    else {
                        isCollateralized = false;
                    }

                    const data: ICollateralTableInterface = {
                        position: element.position,
                        name: element.productCreditType,
                        duration: element.productCreditType !== ProductPackageType.Guarantee ? element.durationWithoutASD : '-',
                        amount: element.productCreditType !== ProductPackageType.Guarantee ? element.creditAmount : element.guaranteeAmount,
                        collateralized: isCollateralized,
                        id: element.productID,
                    };

                    newData.push(data);
                });

                dataSources.set(detail, newData);
                this.dataSources = dataSources;
            });
        }
    }


    /**
     * assign insurance
     * 
     * @param {string} assignedInsuranceID assignedInsuranceID 
     * @param {string} collateralID collateralid
     * @param {boolean} isCheckboxDelete true/false
     */
    public assignInsurances(assignedInsuranceID: string, collateralID: string, isCheckboxDelete: boolean) {

        //wird es über die checkbox/dropdown gelöscht oder über button
        if (isCheckboxDelete === true) {
            if (!!this.productPackageID && !!assignedInsuranceID) {
                this.assignCollateralRequest(collateralID, assignedInsuranceID, this.productPackageID);
            }
        }
        else {
            this.notificationService.confirmOkCancel(this.translate.instant('asset.assetDetails.deleteCollateralDetail'), this.translate.instant('asset.assetDetails.deleteCollateralDetailQuestion')).subscribe(result => {
                if (result === 'submit' && !!this.productPackageID && !!assignedInsuranceID) {
                    this.assignCollateralRequest(collateralID, assignedInsuranceID, this.productPackageID);
                }
            });
        }
    }

    /**
     * assign insurances request
     * 
     * @param {string} collateralID collateralID
     * @param {string} insuranceID insuranceID
     * @param {string} productpackageID productpackageID
     */
    public assignCollateralRequest(collateralID: string, insuranceID: string, productpackageID: string) {
        this.waiterService.show();
        this.assetService.assignCollateral(collateralID, insuranceID, productpackageID).pipe(take(1)).subscribe({
            next: data => {
                if (!!data) {
                    this.collateralInsuranceDetail = data.insurances;
                    this.changeDetection.detectChanges();
                }
                this.waiterService.hide();
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.assignCollateralError'));
            },
        });
    }

    /**
     * check assigned insurance data to avoid empty body
     *
     * @param {IAssetInsurance} assetInsurance assetInsurance
     * @returns {boolean} true/false
     */
    public hasAssignedBodyData(assetInsurance: IAssetInsurance): boolean {
        return !!(
            assetInsurance.redemptionValueCurrency ||
            assetInsurance.currentCollateralValue ||
            assetInsurance.currentCollateralValuePer ||
            assetInsurance.applicationNo ||
            assetInsurance.materialValueAlreadyInUse ||
            assetInsurance.freeMaterialValue ||
            assetInsurance.insuranceStart ||
            assetInsurance.insuranceEnd
        );
    }

    /**
     * checkbox is selected for assigned insurances
     * 
     * @param {IAssetInsurance} insurance insurance 
     * @param {string} collateralID collateralid
     * @returns {boolean} true/false
     */
    public isInsuranceSelected(insurance: IAssetInsurance, collateralID: string): boolean {
        const insuranceDetail = this.collateralInsuranceDetail?.find(detail => detail.id === collateralID);
        if (!!insuranceDetail && !!insuranceDetail.assetAssignmentInfos) {
            const assetAssignmentInfo = insuranceDetail.assetAssignmentInfos.find(info => info.assetId === insurance.id);
            return assetAssignmentInfo ? true : false;
        }
        return false;
    }

    /**
     * delete Collateral
     * 
     * @param {string} collateralID collateral id
     */
    public deleteCollateral(collateralID: string) {
        this.notificationService.confirmOkCancel(this.translate.instant('asset.assetDetails.deleteCollateralDetail'), this.translate.instant('asset.assetDetails.deleteCollateralDetailQuestion')).subscribe(result => {
            if (result === 'submit' && !!this.productPackage) {
                this.waiterService.show();
                this.assetService.deleteCollateral(this.productPackage.assignProductPackages[0].productPackageID, collateralID).pipe(take(1)).subscribe({
                    next: data => {
                        if (!!data) {
                            this.collateralInsuranceDetail = data.insurances;
                            this.changeDetection.detectChanges();
                        }
                        this.waiterService.hide();
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.realEstateDetails.deleteError'));
                    },
                });
            }
        });
    }

    /**
     * secure Product to Collateral
     *
     * @param {string} collateralID collateralid
     * @param {string} productID productID
     * @param {boolean} isSecured true/false
     */
    public productSecured(collateralID: string, productID: string, isSecured: boolean) {
        if (!!this.productPackage?.assignProductPackages[0].productPackageID) {
            this.assetService.secureProductToCollateral(collateralID, productID, this.productPackage?.assignProductPackages[0].productPackageID, isSecured).pipe(take(1)).subscribe({
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.secureProductCollateralError'));
                },
            });
        }
    }
}
