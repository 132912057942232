<mat-tab-group fxLayout="column" fxLayoutAlign="space-between stretch" *ngIf="!!productPackageData">

    <form [formGroup]="form">
        <div formArrayName="productPackages">
            <mat-tab *ngFor="let products of productPackageData.assignProductPackages[0].assignProducts; let i = index"
                [formGroupName]="i"
                [label]="products.position + '. ' + getTranslatedProductType(products.productCreditType)">

                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="50px" class="mt-4 ml-3 mr-3">

                    <!--Kreditanfrage-->
                    <div fxLayout="column" fxFlex="25" class="mb-2"
                        *ngIf="products.productCreditType !== ProductPackageType.Guarantee">
                        <h4><b>{{'financing.features.financing-processing.financing-details.creditRequest' |
                                translate}}</b>
                        </h4>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                {{'financing.features.financing-processing.financing-details.disbursementDate' |
                                translate}}
                            </mat-label>
                            <input matInput [matDatepicker]="mydatepicker" formControlName="disbursementDate"
                                (blur)="updateProducts()" (dateChange)="updateProducts()">
                            <mat-datepicker-toggle matSuffix [for]="mydatepicker" class="mr-2"></mat-datepicker-toggle>
                            <mat-datepicker #mydatepicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>{{'financing.features.financing-processing.financing-details.repaymentPeriod' |
                                translate}}</mat-label>
                            <mat-select formControlName="repaymentPeriod" (blur)="updateProducts()">
                                <mat-option *ngFor="let rp of repaymentPeriod" [value]="rp">
                                    {{ rp | finprocessEnumTranslation:'RepaymentPeriod' }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                {{'financing.features.financing-processing.financing-details.repaymentDate' |
                                translate}}
                            </mat-label>
                            <input matInput [matDatepicker]="mydatepicker2" formControlName="repaymentDate"
                                (blur)="updateProducts()" (dateChange)="updateProducts()">
                            <mat-datepicker-toggle matSuffix [for]="mydatepicker2" class="mr-2"></mat-datepicker-toggle>
                            <mat-datepicker #mydatepicker2></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>{{'financing.features.financing-processing.productPackages.texts.creditAmount' |
                                translate}}</mat-label>
                            <input matInput currencyMask [options]="currencyMaskOptions" formControlName="creditAmount"
                                (blur)="updateProducts()">
                        </mat-form-field>

                        <mat-form-field
                            *ngIf="products.productCreditType === ProductPackageType.Credit || products.productCreditType === ProductPackageType.ComfortCredit"
                            appearance="fill">
                            <mat-label>{{'financing.features.financing-processing.productPackages.texts.interestOnlyPeriod'
                                | translate}}</mat-label>
                            <input matInput currencyMask [options]="numberMaskOptions" (blur)="updateProducts()"
                                formControlName="interestOnlyPeriod">
                        </mat-form-field>

                        <!--Laufzeit nicht bearbeitbar-->
                        <div *ngIf="!!products.durationWithoutASD">
                            <mat-label>{{'financing.features.financing-processing.productPackages.texts.duration' |
                                translate}}: {{products.durationWithoutASD}}
                                {{'financing.features.financing-processing.productPackages.texts.month' |
                                translate}}</mat-label>
                        </div>

                        <div>
                            {{'financing.features.financing-processing.financing-details.ratesCount' | translate}}:
                            {{products.ratesCount}}
                        </div>

                    </div>

                    <!--Zinssätze-->
                    <div fxLayout="column" fxFlex="25"
                        *ngIf="products.productCreditType !== ProductPackageType.Guarantee">
                        <h4><b>{{'financing.features.financing-processing.financing-details.rates' | translate}}</b>
                        </h4>

                        <mat-form-field appearance="fill">
                            <mat-label>{{'financing.features.financing-processing.financing-details.indicator' |
                                translate}}</mat-label>
                            <mat-select formControlName="indicator" (blur)="updateProducts()">
                                <mat-option *ngFor="let i of indicator" [value]="i">
                                    {{ i | finprocessEnumTranslation:'InterestMethod' }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>{{'financing.features.financing-processing.financing-details.interestReferenceRate'
                                | translate}}</mat-label>
                            <input matInput formControlName="interestReferenceRate" currencyMask
                                (blur)="updateProducts()" [options]="percentageMaskOptions">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>{{'financing.features.financing-processing.financing-details.addition' |
                                translate}}</mat-label>
                            <input matInput formControlName="addition" currencyMask [options]="percentageMaskOptions"
                                (blur)="updateProducts()">
                        </mat-form-field>

                        <mat-form-field *ngIf="products.withoutCoverageRegister === true" appearance="fill">
                            <mat-label>{{'financing.features.financing-processing.financing-details.additionForComparativeAnalysis'
                                | translate}}</mat-label>
                            <input matInput formControlName="additionForComparativeAnalysis" currencyMask
                                [options]="percentageMaskOptions" (blur)="updateProducts()">
                        </mat-form-field>

                        <ng-container *ngIf="legalisationFee$ | async as legalisationFee">
                            {{'financing.features.financing-processing.financing-details.legalisationFee'
                            | translate}}: {{ legalisationFee(true) | formatCurrency }}
                        </ng-container>
                    </div>

                    <!--Garantie-->
                    <div fxLayout="column" fxFlex="25"
                        *ngIf="products.productCreditType === ProductPackageType.Guarantee">
                        <h4><b>{{'financing.features.financing-processing.financing-details.guaranteeData' |
                                translate}}</b>
                        </h4>

                        <mat-form-field appearance="fill" [matTooltip]="'financing.features.financing-processing.financing-details.guaranteeAmount'
                                | translate" matTooltipPosition="above">
                            <mat-label>{{'financing.features.financing-processing.financing-details.guaranteeAmount'
                                | translate}}</mat-label>
                            <input matInput formControlName="guaranteeAmount" currencyMask
                                [options]="currencyMaskOptions" (blur)="updateProducts()">
                        </mat-form-field>

                        <mat-form-field appearance="fill"
                            [matTooltip]="'financing.features.financing-processing.financing-details.guaranteeSpecialFinancing' | translate"
                            matTooltipPosition="above">
                            <mat-label>{{'financing.features.financing-processing.financing-details.guaranteeSpecialFinancing'
                                | translate}}</mat-label>
                            <input matInput formControlName="specialFinancing" currencyMask
                                [options]="currencyMaskOptions" (blur)="updateProducts()">
                        </mat-form-field>

                        <!--Garantiesumme-->
                        <finprocess-label-value>
                            <span label>{{'financing.features.financing-processing.productPackages.texts.guaranteeSum' | translate}}</span>
                            <div value>
                                {{guaranteeSum | formatCurrency}}
                            </div>
                        </finprocess-label-value>
                        <mat-hint class="hint-danger" *ngIf="!!guaranteeSum && guaranteeSum < 0">{{'financing.features.financing-processing.productPackages.texts.guaranteeWarning'
                            | translate}}</mat-hint>
                    </div>

                    <!--Verzinsungsarten-->
                    <div fxLayout="column" fxFlex="50"
                        *ngIf="products.productCreditType !== ProductPackageType.Guarantee">
                        <h4><b>{{'financing.features.financing-processing.financing-details.rateTypes' | translate}}</b>
                        </h4>

                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div style="margin-right: 35px;">
                                {{'financing.features.financing-processing.financing-details.variableInterest' |
                                translate}}
                            </div>
                            <mat-icon class="zinssatz-icons mr-2">autorenew</mat-icon>
                            <mat-form-field class="interest-type-formfield">
                                <mat-label>Zinssatz</mat-label>
                                <input matInput formControlName="variableInterest" currencyMask
                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div style="margin-right: 20px;">
                                {{'financing.features.financing-processing.financing-details.variableInterestFix5Year' |
                                translate}}</div>
                            <mat-icon class="zinssatz-icons mr-2">autorenew</mat-icon>
                            <mat-form-field class="interest-type-formfield">
                                <mat-label>Zinssatz</mat-label>
                                <input matInput formControlName="variableInterestFix5Year" currencyMask
                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div>{{'financing.features.financing-processing.financing-details.variableInterestFix10Year'
                                | translate}}</div>
                            <mat-icon class="zinssatz-icons">autorenew</mat-icon>
                            <mat-form-field class="interest-type-formfield">
                                <mat-label>Zinssatz</mat-label>
                                <input matInput formControlName="variableInterestFix10Year" currencyMask
                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div>{{'financing.features.financing-processing.financing-details.variableInterestFix15Year'
                                | translate}}</div>
                            <mat-icon class="zinssatz-icons">autorenew</mat-icon>
                            <mat-form-field class="interest-type-formfield">
                                <mat-label>Zinssatz</mat-label>
                                <input matInput formControlName="variableInterestFix15Year" currencyMask
                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div>{{'financing.features.financing-processing.financing-details.variableInterestFix20Year'
                                | translate}}</div>
                            <mat-icon class="zinssatz-icons">autorenew</mat-icon>
                            <mat-form-field class="interest-type-formfield">
                                <mat-label>Zinssatz</mat-label>
                                <input matInput formControlName="variableInterestFix20Year" currencyMask
                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div>{{'financing.features.financing-processing.financing-details.variableInterestFix25Year'
                                | translate}}</div>
                            <mat-icon class="zinssatz-icons">autorenew</mat-icon>
                            <mat-form-field class="interest-type-formfield">
                                <mat-label>Zinssatz</mat-label>
                                <input matInput formControlName="variableInterestFix25Year" currencyMask
                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <!--Finanzierungsbedarf überschritten Warnung-->
                <div class="mt-3" fxLayout="column" fxLayoutAlign="start center" *ngIf="warningFee">
                    <div fxLayout="row">
                        <mat-icon class="mr-2 mb-3 financing-scope-warning">warning</mat-icon>
                        <p class="financing-scope-warning">
                            {{'financing.features.financing-processing.financing-details.financing-scope-warning' |
                            translate}}</p>
                    </div>
                    <div><small>{{'financing.features.financing-processing.financing-details.approved-financing-scope' |
                            translate}}: {{grossFinancingRequirement | formatCurrency}} </small></div>
                    <div><small><b>{{'financing.features.financing-processing.financing-details.exceeded-financing-scope'
                                |
                                translate}} {{differenceGrossFinancing | formatCurrency}} </b></small></div>
                </div>

                <!--Gebühren und Spesen-->
                <div *ngIf="products.productCreditType !== ProductPackageType.Guarantee">
                    <div fxLayout="row" fxLayoutAlign="start center" class="mt-4 ml-3">
                        <h4><b>{{'financing.features.financing-processing.financing-details.fees' | translate}}</b></h4>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50px" class="ml-3 mr-3">
                        <div fxLayout="column" fxFlex="50">

                            <mat-form-field *ngIf="isOwnEmployee === true" appearance="fill">
                                <mat-label>{{'financing.features.financing-processing.financing-details.riskAmount' |
                                    translate}}</mat-label>
                                <input matInput formControlName="riskAmount" currencyMask
                                    [options]="currencyMaskOptions"
                                    (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                            </mat-form-field>

                            <mat-form-field *ngIf="isOwnEmployee === false" appearance="fill">
                                <mat-label>{{'financing.features.financing-processing.financing-details.processingFees'
                                    |
                                    translate}}</mat-label>
                                <input matInput formControlName="processingFee" currencyMask
                                    [options]="currencyMaskOptions"
                                    (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{'financing.features.financing-processing.financing-details.valuationFee' |
                                    translate}}</mat-label>
                                <input matInput formControlName="valuationFee" currencyMask
                                    [options]="currencyMaskOptions"
                                    (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{'financing.features.financing-processing.financing-details.externalValuationFee'
                                    |
                                    translate}}</mat-label>
                                <input matInput formControlName="externalValuationFee" currencyMask
                                    [options]="currencyMaskOptions"
                                    (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{'financing.features.financing-processing.financing-details.accountFee' |
                                    translate}}</mat-label>
                                <input matInput formControlName="accountFee" currencyMask
                                    [options]="currencyMaskOptions"
                                    (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{'financing.features.financing-processing.financing-details.legalFee' |
                                    translate}}</mat-label>
                                <input matInput formControlName="legalFee" currencyMask [options]="currencyMaskOptions"
                                    (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" fxFlex="50">
                            <mat-form-field appearance="fill">
                                <mat-label>{{'financing.features.financing-processing.financing-details.brokerage' |
                                    translate}}</mat-label>
                                <input matInput formControlName="brokerage" currencyMask [options]="currencyMaskOptions"
                                    (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{'financing.features.financing-processing.financing-details.landRegisterEntryFee'
                                    |
                                    translate}}</mat-label>
                                <input matInput formControlName="landRegisterEntryFee" currencyMask
                                    [options]="currencyMaskOptions"
                                    (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{'financing.features.financing-processing.financing-details.priorityRankingFee'
                                    |
                                    translate}}</mat-label>
                                <input matInput formControlName="priorityRankingFee" currencyMask
                                    [options]="currencyMaskOptions"
                                    (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{'financing.features.financing-processing.financing-details.landRegisterRecord'
                                    |
                                    translate}}</mat-label>
                                <input matInput formControlName="landRegisterRecord" currencyMask
                                    [options]="currencyMaskOptions"
                                    (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{'financing.features.financing-processing.financing-details.landRegisterApplication'
                                    | translate}}</mat-label>
                                <input matInput formControlName="landRegisterApplication" currencyMask
                                    [options]="currencyMaskOptions"
                                    (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </div>
    </form>

    <!--Rechenbeispiel generieren-->
    <mat-tab label="Rechenbeispiel generieren">
        <div class="mt-3 ml-3 mr-3" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="100" fxLayoutAlign="start">
                <div class="mt-3 mb-5" fxLayout="row" fxLayoutAlign="end">
                    <button mat-raised-button color="primary" style="width: fit-content"
                        (click)="openGenerateSampleCalculation()"
                        [disabled]="(fieldReadonly$ | async) ?? true || disableCreatingSampleCalculations === true || productPackageData.assignProductPackages[0].assignProducts.length === 0">{{'financing.features.financing-processing.financing-details.addSampleCalculation'
                        | translate}}</button>
                </div>
                <h4><b>{{'financing.features.financing-processing.financing-details.generatedSampleCalculations' |
                        translate}}</b></h4>
                <mat-list role="list" *ngFor="let documents of sampleCalculationDocuments;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-list-item role="listitem">
                            <small> {{documents.sampleCalculationName}}.pdf</small>
                            <button mat-icon-button [disabled]="(fieldReadonly$ | async) === true" class="ml-4 icon-pointer open-icon"
                                 (click)="openSampleCalculationDocument(documents.sampleCalculationId, documents.sampleCalculationName)">
                            <mat-icon >open_in_new</mat-icon>
                            </button>
                            <button mat-icon-button [disabled]="(fieldReadonly$ | async) === true" class="icon-pointer delete-icon"
                                (click)="deleteSampleCalcDocument(documents.sampleCalculationId)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-list-item>
                    </div>
                    <mat-divider></mat-divider>
                </mat-list>
                <div *ngIf="sampleCalculationDocuments?.length === 0" class="rb-info">
                    {{'financing.features.financing-processing.financing-details.sampleCalculationInfo' |
                    translate}}</div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>