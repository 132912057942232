<mat-expansion-panel [togglePosition]="'before'"  *ngIf="!!assetRealEstate" class="bg-dark-2 ml-3 mr-3 pl-4 pr-4 pb-3" hideToggle="true">
  <mat-expansion-panel-header #ex class="mb-3 mt-3">
      <div fxLayout="row" fxLayoutAlign="space-between start" class="mb-2">
        <span fxLayout="row">
          <button class="mt-2" mat-icon-button (click)="assetExpand = !assetExpand">
            <mat-icon class="expand-icon">{{ assetExpand ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>
           <h3 class="mt-3 mr-2 sub-header"><b>{{'asset.assetRealEstate.header' | translate}}</b></h3>
           <button class="mt-2" mat-mini-fab style="box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" [disabled]="(fieldReadonly$ | async) ?? true" (click)="openRealestateDetail($event, assetRealEstate.id)">
            <mat-icon>preview</mat-icon>
          </button>
        </span>
  
        <finprocess-label-value (click)="$event.stopImmediatePropagation()" flexDirection="row" fxLayoutAlign="end start" class="pt-3">
          <span label class="pr-2">{{'financing.features.financing-processing.liabilities.liabilityOverview.active' | translate }}</span>
          <span value>
            <mat-checkbox color="primary" [checked]="assetRealEstate.isActive"
              (click)="setAssetActive(assetRealEstate.id, !assetRealEstate.isActive)" [class.finprocess-readonly]="(fieldReadonly$ | async) ?? true">
            </mat-checkbox>
          </span>
        </finprocess-label-value>
      </div>
      <div class="wrapper" (click)="$event.stopImmediatePropagation()">
        <finprocess-select-input [items]="objectTypeSelectItems" [overwrite]="false"
          [entityClassType]="entityClassType.AssetRealEstate" [hideHorizontalRule]="true" fieldName="assetType"
          [readonly]="fieldReadonly$" [entity]="assetRealEstate">
          <span label class="input-label">{{'asset.assetRealEstate.assetType' | translate}}</span>
        </finprocess-select-input>
  
        <!-- Eigentumsform enum-->
        <finprocess-select-input  [items]="typeOfShareSelectItems" [overwrite]="false"
          [entityClassType]="entityClassType.AssetRealEstate" [hideHorizontalRule]="true" fieldName="ownershipType"
          [readonly]="fieldReadonly$" [entity]="assetRealEstate">
          <span label class="input-label">{{'asset.assetRealEstate.ownershipType' | translate}}</span>
        </finprocess-select-input>
  
        <!-- Sonderabwicklung enum-->
        <finprocess-select-input [items]="propertyTypeSelectItems" [overwrite]="false"
          [entityClassType]="entityClassType.AssetRealEstate" [hideHorizontalRule]="true" fieldName="specialProcessing"
          [readonly]="fieldReadonly$" [entity]="assetRealEstate" [valueStorageType]="ValueStorageType.Int">
          <span label class="input-label">{{'asset.assetRealEstate.specialProcessing' | translate}}</span>
        </finprocess-select-input>
  
        <!-- Marktwert -->
        <finprocess-decimal-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
          fieldName="marketValue" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
          [readonly]="fieldReadonly$" [entity]="assetRealEstate">
          <span label class="input-label">{{'asset.assetRealEstate.marketValue' | translate}}</span>
        </finprocess-decimal-input>
  
        <!-- materieller Wert gesamt -->
        <finprocess-decimal-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
          fieldName="materialValueTotal" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
          [readonly]="alwaysReadonly$" [entity]="assetRealEstate">
          <span label class="input-label">{{'asset.assetRealEstate.materialValueTotal' | translate}}</span>
        </finprocess-decimal-input>
  
        <div></div>
      </div>
  
      <mat-divider class="mt-4"></mat-divider>
  
      <!--Objektadresse-->
      <h3 class="mt-3">{{'asset.assetRealEstate.objectAdressHeader' | translate}}</h3>
  
      <div class="wrapper" (click)="$event.stopImmediatePropagation()">
        <finprocess-integer-input [overwrite]="false" [entityClassType]="entityClassType.Address" fieldName="zip"
          [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true" [readonly]="fieldReadonly$"
          [entity]="assetRealEstate.address">
          <span label class="input-label">{{'asset.assetRealEstate.address.zip' | translate}}</span>
        </finprocess-integer-input>
  
        <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType.Address" fieldName="city"
          [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true" [readonly]="fieldReadonly$"
          [entity]="assetRealEstate.address">
          <span label class="input-label">{{'asset.assetRealEstate.address.city' | translate}}</span>
        </finprocess-textbox-input>
  
        <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType.Address" fieldName="street"
          [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true" [readonly]="fieldReadonly$"
          [entity]="assetRealEstate.address">
          <span label class="input-label">{{'asset.assetRealEstate.address.street' | translate}}</span>
        </finprocess-textbox-input>
  
        <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType.Address" fieldName="streetNumber"
          [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true" [readonly]="fieldReadonly$"
          [entity]="assetRealEstate.address">
          <span label class="input-label">{{'asset.assetRealEstate.address.streetNumber' | translate}}</span>
        </finprocess-textbox-input>
  
        <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType.Address" fieldName="stairway"
          [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true" [readonly]="fieldReadonly$"
          [entity]="assetRealEstate.address">
          <span label class="input-label">{{'asset.assetRealEstate.address.stairway' | translate}}</span>
        </finprocess-textbox-input>
  
        <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType.Address" fieldName="top"
          [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true" [readonly]="fieldReadonly$"
          [entity]="assetRealEstate.address">
          <span label class="input-label">{{'asset.assetRealEstate.address.top' | translate}}</span>
        </finprocess-textbox-input>
      </div>
    </mat-expansion-panel-header>
    <!-- um das body lazy zu rendern brauceh wir eine reference zum  panel-header -->
  <ng-template matExpansionPanelContent>
    <ng-container *ngIf="ex._isExpanded">

      <mat-divider class="mt-4"></mat-divider>
      <div class="mt-3 mb-4">
        <!--Bewertung-->
        <h3 class="mt-4">{{'asset.assetRealEstate.valuation' | translate}}</h3>
        <div class="wrapper-2">
          <finprocess-select-input [items]="valuationTypeSelectItems" [overwrite]="false"
            [entityClassType]="entityClassType.AssetRealEstate" [hideHorizontalRule]="true" fieldName="valuationType"
            [readonly]="fieldReadonly$" [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetRealEstate.valuationType' | translate}}</span>
          </finprocess-select-input>
    
          <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
            fieldName="valuationNumber" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$" [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetRealEstate.valuationNumber' | translate}}</span>
          </finprocess-textbox-input>
    
          <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
            fieldName="amgId" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true" [readonly]="fieldReadonly$"
            [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetRealEstate.amgId' | translate}}</span>
          </finprocess-textbox-input>
    
          <finprocess-integer-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
            fieldName="loanValue" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="alwaysReadonly$" [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetRealEstate.loanValue' | translate}}</span>
          </finprocess-integer-input>
    
          <finprocess-integer-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
            fieldName="existingPreloads" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="alwaysReadonly$" [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetRealEstate.existingPreloads' | translate}}</span>
          </finprocess-integer-input>
    
          <finprocess-decimal-input *ngIf="showMaterialValues" [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
            fieldName="materialValueAlreadyInUse" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="alwaysReadonly$" [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetCommon.materialValueAlreadyInUse' | translate}}</span>
          </finprocess-decimal-input>
    
          <finprocess-decimal-input *ngIf="showMaterialValues" class="finprocess-readonly-opacity" [overwrite]="false"
            [readonly]="alwaysReadonly$" [entityClassType]="entityClassType.AssetRealEstate" fieldName="freeMaterialValue"
            [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true" [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetCommon.freeMaterialValue' | translate}}</span>
          </finprocess-decimal-input>
    
        </div>
      </div>
      <mat-divider class="mt-4"></mat-divider>
    
      <div class="mt-3 mb-4">
        <!--Grundbuchsdaten-->
        <h3 class="mt-4">{{'asset.assetRealEstate.landRegisterDataHeader' | translate}}</h3>
        <div class="wrapper-2">
        
          <finprocess-integer-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
            fieldName="cadastralNumber" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$" [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetRealEstate.cadastralNumber' | translate}}</span>
          </finprocess-integer-input>
    
          <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
            fieldName="cadastralName" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$" [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetRealEstate.cadastralName' | translate}}</span>
          </finprocess-textbox-input>
    
          <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
            fieldName="districtCourt" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$" [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetRealEstate.districtCourt' | translate}}</span>
          </finprocess-textbox-input>
    
          <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
            fieldName="depositNumber" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$" [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetRealEstate.depositNumber' | translate}}</span>
          </finprocess-textbox-input>
    
          <finprocess-integer-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
            fieldName="numberOfOwners" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$" [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetRealEstate.numberOfOwners' | translate}}</span>
          </finprocess-integer-input>
    
          <finprocess-integer-input [overwrite]="false" [entityClassType]="entityClassType.AssetRealEstate"
            fieldName="shares" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true" [readonly]="fieldReadonly$"
            [entity]="assetRealEstate">
            <span label class="input-label">{{'asset.assetRealEstate.shares' | translate}}</span>
          </finprocess-integer-input>
        </div>
      </div>
      <div fxLayout="column" class="body">
        <finprocess-asset-provider style="margin-top: 60px;"
          [entityClassType]="entityClassType.AssetRealEstate" 
          [assetId]="assetRealEstate.id"
          [assetProviders]="assetRealEstate.assetProviders"
          ></finprocess-asset-provider>
        <mat-divider class="mb-3" style="margin-top: 30px;"></mat-divider>
        <finprocess-asset-agp [assetsAgps]="assetRealEstate.assetsAgp"></finprocess-asset-agp>
      </div>
    </ng-container>
  </ng-template>
</mat-expansion-panel>