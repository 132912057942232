import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { DebitorCalculationService } from '@ntag-ef/finprocess-calculations';
import { BankAustriaStatus, EmployeeStatus, EnumTranslationPipe, FinancingPremissions, Gender, HousingType, LevelOfTraining, MandantType, MaritalStatus } from '@ntag-ef/finprocess-enums';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { EntityClassType, FinancingService, FinancingState, IDebitor, IDebitorItem, IDocument, IFinancing, IFinancingStateParentDefinition, OverwriteValueClassType, RatingPollingStatus, ScoringService, ValueStorageType, VerfiedCustomerService } from 'app/modules/financing/data';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { IBranch, IMasterdataParentDefinition, IMasterdataStateModel, ISalesPartnerCenter } from 'app/modules/masterdata/data';
import { HelperService, ISelectItem, UUID } from 'app/modules/shared';
import { sort } from 'fast-sort';
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, distinctUntilChanged, filter, map, mergeMap, of, switchMap, take, takeUntil, tap, throwError } from 'rxjs';

import { VerifyCustomerDialogComponent } from '../verify-customer-dialog/verify-customer-dialog.component';

/**
 * Kreditbeiligte Kundendaten
 */
@Component({
    selector: 'finprocess-borrower-check-customer-data',
    templateUrl: './borrower-check-customer-data.component.html',
    styleUrls: ['./borrower-check-customer-data.component.scss'],
})
export class BorrowerCheckCustomerDataComponent implements OnInit, OnDestroy {

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public OverwriteValueClassType = OverwriteValueClassType;

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ValueStorageType = ValueStorageType;

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public MandantType = MandantType;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Observable immer Schreibschutz mit Bearbeitungsmodus
     */
    public alwaysReadonly$: Observable<boolean> = of(true);

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * ausgewählter Kreditnehmer
     */
    public selectedDebitor = new BehaviorSubject<IDebitorItem | undefined>(undefined);

    /**
     * Anrede Items
     */
    public genderItems!: ISelectItem<string>[];

    /**
     * Wohnart Items
     */
    public housingTypeItems!: ISelectItem<string>[];

    /**
     * Familienstand Items
     */
    public maritalStatusItems!: ISelectItem<string>[];

    /**
     * Bankkonto zu BA verlegen Items
     */
    public bankAustriaStatusItems!: ISelectItem<string>[];

    /**
     * Höchste abgeschlossene Ausbildung Items
     */
    public levelOfTrainingItems!: ISelectItem<string>[];

    /**
     * Arbeitsverhältnis Items
     */
    public employeeStatusItems!: ISelectItem<string>[];

    public isEmployeeBranch = false;

    public entityClassType = EntityClassType;

    public ratingPollingStatusSelectItems: ISelectItem<number>[] = [];

    //----------------------------------------------------------------------------------------

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;

    /**
     * Wirtschaftlich führender
     */
    @Select(FinancingState.economicLeader)
    public economicLeader$!: Observable<IDebitor | undefined>;

    /**
     * Observer Filialen
     */
    @Select(FinancingState.branches)
    public branches$!: Observable<IBranch[]>;

    /**
     * Observer für Verfügbarkeit von DGA
     */
    @Select((it: IMasterdataParentDefinition) => it.systemConfiguration.externalSystemsAvailability.customerProfileIsAvailable)
    public dgaAvailable$!: Observable<boolean>;

    /**
     * Filialen gemappt
     *
     * @returns {Observable<ISelectItem[]>} Filialnen
     */
    public branchesItems$!: Observable<ISelectItem<string>[]>;

    /**
     * Observer Vertriebspartnercenter
     */
    @Select(FinancingState.salesPartnerCenter)
    public salesPartnerCenter$!: Observable<ISalesPartnerCenter[]>;

    /**
     * Vertriebspartnercenter gemappt
     *
     * @returns {Observable<ISelectItem[]>} Filialnen
     */
    public salesPartnerCenterItems$!: Observable<ISelectItem<string>[]>;

    /**
     * Dokumente für den Dokumenten Viewer
     */
    public documents$!: Observable<((document: IDocument[]) => IDocument[])>;

    /**
     * Ob die Tin Number angezeigt werden soll
     */
    public showTinNumber!: Observable<boolean>;

    public debitors$!: Observable<IDebitorItem[]>;

    /**
     * Alter Laufzeitende in Jahren
     */
    @Select(FinancingState.ageDurationEnd)
    public ageDurationEnd$!: Observable<(debitorId: UUID, withOverwrites?: boolean) => number | undefined>;

    /**
     * Alter Laufzeitende in Jahren gemappt
     */
    public ageDurationEndMapped$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Haushaltsnummer
     */
    public householdNumber$!: Observable<(withOverwrites?: boolean) => number>;

    @Select((it: IMasterdataParentDefinition) => it.masterdata as IMasterdataStateModel)
    public masterData$!: Observable<IMasterdataStateModel>;

    /**
     * Alle Länder für Staatsbürgerschaft
     */
    public allCountriesCiticen$!: Observable<ISelectItem<string>[]>;

    /**
     * Alle Länder für Geburtsland
     */
    public allCountriesBirthCountry$!: Observable<ISelectItem<string>[]>;

    /**
     * Alle Länder für Steuerdomizil
     */
    public allCountriesTaxResidence$!: Observable<ISelectItem<string>[]>;

    /**
     * Alle Kontoverbindungen
     */
    public bankAccounts$!: Observable<ISelectItem<string>[]>;

    private calculationService = new DebitorCalculationService();

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {VerfiedCustomerService} verfiedCustomerService VerfiedCustomerService-Injektor
     * @param {MatDialog} dialog MatDialog Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {ActivatedRoute} activatedRoute activated route
     * @param {Store} store Store-Injektor
     * @param {ScoringService} scoringService scoringService-Injektor
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe-Injektor
     */
    public constructor(
        private financingService: FinancingService,
        private verfiedCustomerService: VerfiedCustomerService,
        private dialog: MatDialog,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private store: Store,
        private scoringService: ScoringService,
        private enumTranslate: EnumTranslationPipe,
    ) {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
    }

    /**
     * Angular-Lifecycle beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.init();
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Kreditnehmer angeklickt
     *
     * @param {number} index Kreditnehmer
     */
    public debitorClicked(index: number): void {
        this.debitors$.pipe(take(1)).subscribe(debitors => {
            const debitor = debitors[index];
            this.selectedDebitor.next(debitor);
        });
    }

    /**
     * Init
     */
    private init(): void {

        this.activatedRoute.paramMap.pipe(
            takeUntil(this.onDestroy$),
            map(params => params.get('riskfinancingplanid') as string),
            distinctUntilChanged(),
            filter(id => id !== undefined),
            mergeMap(id => {
                const financing = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing);
                if (financing === undefined) {
                    return this.financingService.loadFinancing(id);
                }

                return of(financing);
            }),
        ).subscribe();

        this.debitors$ = this.financing$.pipe(
            takeUntil(this.onDestroy$),
            tap(financing => {
                this.isEmployeeBranch = HelperService.hasBit(financing?.financingPermissions, FinancingPremissions.EmployeesSkill);
            }),
            switchMap(financing => this.economicLeader$.pipe(map(leader => ({ financing, leader })))),
            map(resposne => {
                const temp: IDebitorItem[] = [];

                if (resposne.financing === undefined) {
                    return temp;
                }

                resposne.financing.households.forEach(h => h.debitors.forEach(d => temp.push({
                    debitor: d,
                    isSelected: false,
                    housholdPos: h.position,
                    firstNameOverwrite: OverwriteHelperService.getMergedOverwriteValue(d, 'firstName', d.firstName),
                    lastNameOverwrite: OverwriteHelperService.getMergedOverwriteValue(d, 'lastName', d.lastName),
                    householdId: h.id,
                } as IDebitorItem)));

                const sorted = sort(temp).desc(b => b.debitor.id === resposne?.leader?.id && this.calculationService.netIncomeTotal({ netIncome: b.debitor.netIncome, fourteenSalariesPerYear: b.debitor.fourteenSalariesPerYear, otherIncome: b.debitor.otherIncome }));

                if (!this.selectedDebitor.value && sorted.length > 0) {
                    this.selectedDebitor.next(sorted[0]);
                } else {
                    this.selectedDebitor.next(sorted.find(debitor => debitor.debitor.id === this.selectedDebitor.value?.debitor.id));
                }

                return sorted;
            }));

        this.branchesItems$ = this.branches$.pipe(takeUntil(this.onDestroy$), map(it => it.map(b => ({
            value: b.id,
            displayName: b.name,
        } as ISelectItem<string>))));

        this.salesPartnerCenterItems$ = this.salesPartnerCenter$.pipe(takeUntil(this.onDestroy$), map(it => it.map(b => ({
            value: b.id,
            displayName: b.name,
        } as ISelectItem<string>))));

        this.ageDurationEndMapped$ = combineLatest([this.ageDurationEnd$, this.selectedDebitor]).pipe(
            takeUntil(this.onDestroy$),
            map(([fn, selectedDebitor]) => (withOverwrites?: boolean): number => {

                if (selectedDebitor === undefined) {
                    return 0;
                }

                const res = fn(selectedDebitor.debitor.id, withOverwrites);

                return res ?? 0;
            }));

        this.householdNumber$ = this.selectedDebitor.pipe(
            takeUntil((this.onDestroy$)),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            map(selectedDebtor => (withOverwrites?: boolean): number => {
                if (!selectedDebtor) {
                    return 1;
                }
                return selectedDebtor.housholdPos + 1;
            }));

        this.documents$ = this.selectedDebitor.pipe(takeUntil(this.onDestroy$),
            map(selectedDebitor => (documents: IDocument[]) => documents.filter(document => document.parentId === selectedDebitor?.debitor.id)),
        );

        const relevantDocumentTypes: Array<DocumentType | DocumentType> = [
            DocumentType.PhotoId,
            DocumentType.PrivacyStatementSignature,
            DocumentType.RegisterConfirmation,
        ];
        
        this.documents$ = this.selectedDebitor.pipe(
            takeUntil(this.onDestroy$),
            map(selectedDebitor => 
                (documents: IDocument[]) => documents.filter(document =>
                    document.parentId === selectedDebitor?.debitor.id &&
                  relevantDocumentTypes.includes(document.type),
                ),
            ),
        );

        this.showTinNumber = combineLatest([this.debitors$, this.selectedDebitor]).pipe(
            takeUntil(this.onDestroy$),
            map(([debitors, selectedDebitor]) => {
                const currentDebitor = debitors.find(debitor => debitor.debitor.id === selectedDebitor?.debitor.id);
                if (!!currentDebitor) {
                    return !!OverwriteHelperService.getMergedOverwriteValue(currentDebitor.debitor, 'AdditionalCitizenship', currentDebitor.debitor.additionalCitizenship)
                }
                return false;
            }));

        this.allCountriesBirthCountry$ = this.masterData$.pipe(
            takeUntil(this.onDestroy$),
            map(it => sort(it.countries.filter(c => c.useForBirthCountry)).asc(c => c.name).map(c => ({
                value: c.code,
                displayName: c.name,
            } as ISelectItem<string>))));

        this.allCountriesCiticen$ = this.masterData$.pipe(
            takeUntil(this.onDestroy$),
            map(it => sort(it.countries.filter(c => c.useForCiticenship)).asc(c => c.name).map(c => ({
                value: c.code,
                displayName: c.name,
            } as ISelectItem<string>))));
        this.allCountriesTaxResidence$ = this.masterData$.pipe(
            takeUntil(this.onDestroy$),
            map(it => sort(it.countries.filter(c => c.useForTaxResidence)).asc(c => c.name).map(c => ({
                value: c.code,
                displayName: c.name,
            } as ISelectItem<string>))));

        this.bankAccounts$ = this.masterData$.pipe(
            takeUntil(this.onDestroy$),
            map(it => {
                const bankAccountNames = it.bankAccounts.filter(b => !b.disabled).map(b => b.name);
                const debtorItem = (this.selectedDebitor.value as IDebitorItem);

                if (debtorItem && debtorItem.debitor.bankAccount) {
                    if (!bankAccountNames.includes(debtorItem.debitor.bankAccount)) {
                        bankAccountNames.push(debtorItem.debitor.bankAccount)
                    }
                }

                return sort(bankAccountNames).asc().map(c => ({
                    value: c,
                    displayName: c,
                } as ISelectItem<string>));
            }));

        this.genderItems = HelperService.getSortedSelectItems(Gender, value => Gender.translate(value));
        this.housingTypeItems = HelperService.getSortedSelectItems(HousingType, value => HousingType.translate(value));
        this.maritalStatusItems = HelperService.getSortedSelectItems(MaritalStatus, value => MaritalStatus.translate(value));
        this.bankAustriaStatusItems = HelperService.getSortedSelectItems(BankAustriaStatus, value => BankAustriaStatus.translate(value));
        this.levelOfTrainingItems = HelperService.getSortedSelectItems(LevelOfTraining, value => LevelOfTraining.translate(value));
        this.employeeStatusItems = HelperService.getSortedSelectItems(EmployeeStatus, value => EmployeeStatus.translate(value));
        this.ratingPollingStatusSelectItems = HelperService.getSortedSelectItems(RatingPollingStatus, value => this.enumTranslate.transform(value, 'RatingPollingStatus') as string, [], true);

        this.debitors$.pipe(
            filter(debitors => debitors.length > 0),
            take(1),
        ).subscribe(() => {
            this.debitorClicked(0);
        });

    }

    /**
     * Kunde NDG suchen, dialog anzeigen, NDG übernehmen, DGA kunde anlegen 
     *
     * @param {IDebitor} currentCustomer debtor
     * @param {string} financingId  financingId 
     * @param {string} financingCenterId  financingCenterId 
     * @param {string} householdId  housholdId 
     */
    public getNdg(currentCustomer: IDebitor, financingId: string, financingCenterId: string, householdId?: string): void {
        this.waiterService.show();

        this.verfiedCustomerService.loadVerifiedCustomers(currentCustomer.id).pipe(
            take(1),
            mergeMap(verifiedCustomers => this.salesPartnerCenter$.pipe(
                take(1),
                map(centers => ({
                    responsibility: centers.find(center => center.id === financingCenterId)?.responsibility,
                    verifiedCustomers: verifiedCustomers,
                })))),
            tap(() => this.waiterService.hide()),
            mergeMap(({ verifiedCustomers, responsibility }) => {
                if (!!verifiedCustomers) {
                    const dialogRef = this.dialog.open(
                        VerifyCustomerDialogComponent,
                        {
                            data: {
                                verifiedCustomers,
                                currentCustomer,
                                financingId,
                                responsibility,
                            },
                            width: '80%',
                            height: '80%',
                            autoFocus: false,

                        },
                    );
                    return dialogRef.afterClosed();
                }
                else {
                    return throwError(() => new Error('Die Liste kann nicht von den Server übertragen werden!'));
                }
            }),
            mergeMap((ndg: string | undefined) => {
                if (!!ndg) {
                    this.waiterService.show();
                    return this.financingService.saveOverwriteValue({
                        overwriteValueClassType:
                            OverwriteValueClassType.DebitorOverwriteValue,
                        entityForOverwriteId: currentCustomer.id,
                        fieldName: 'customerNumber',
                        valueStorageType: ValueStorageType.String,
                        value: ndg,
                    }).pipe(
                        map(() => ({ ndg: ndg, id: currentCustomer.id })),
                        catchError(() => throwError(() => new Error('Fehler beim Speichern den NDG!'))),
                    );
                } else {
                    return of(void 0);
                }
            }),
            mergeMap(data => {
                if (!!data && !!householdId) {
                    return this.scoringService.refreshClientRating({ debitorId: data.id, ndg: data.ndg }, householdId).pipe(
                        catchError(() => throwError(() => new Error('Fehler beim Scoring abfragen'))))
                } else {
                    return of(void 0);
                }
            }),
        ).subscribe({
            next: () => this.waiterService.hide(),
            error: e => {
                this.waiterService.hide(),
                this.notification.alert(
                    this.translate.instant('general.error'),
                    e.message,
                )
            },
            complete: () => {
                this.waiterService.hide();
            },
        });
    }

    /**
     * Bewertung aktualisieren
     * 
     * @param {IDebitor} debtor IDebitor
     * @param {string} householdId householdId
     */
    public refreshScoring(debtor: IDebitor, householdId?: string): void {
        const ndg = OverwriteHelperService.getMergedOverwriteValue(debtor, 'customerNumber', debtor.customerNumber);

        if (!!ndg && !!householdId) {
            this.waiterService.show();
            this.scoringService.refreshClientRating({debitorId: debtor.id, ndg: ndg }, householdId).pipe(
                take(1),
                tap(() => this.waiterService.hide()),
            ).subscribe();
        }
    }

    /**
     * Liefert debtor NDG
     * 
     * @param {IDebitor} debitor  debitor
     * @returns {string | undefined} ndg | undefined
     */
    public getDebtorNdg(debitor: IDebitor): string | undefined {
        return OverwriteHelperService.getMergedOverwriteValue(debitor, 'customerNumber', debitor.customerNumber);
    }
}
