import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { HelperService } from 'shared/util';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Komponente zur Eingabe eines Dezimalwertes
 */
@Component({
    selector: 'finprocess-decimal-input',
    templateUrl: './decimal-input.component.html',
    styleUrls: ['./decimal-input.component.scss'],
})
export class DecimalInputComponent<T extends IBase> extends OverwriteInputComponent<T> implements OnInit {
    /**
     * Liefert das Flexlayout-Alignment des Wertes
     *
     * @returns {string} Flexlayout-Alignment des Wertes
     */
    public get fxValueLayoutAlign(): string {
        return `${this.fxValueAlignment} center`;
    }

    /**
     * Standardeinstellungen für Maskierung einer Dezimaleingabe
     */
    public options!: NgxCurrencyConfig;

    /**
     * Anzeigeformat
     */
    @Input()
    public format = '1.2-2';

    /**
     * Minimaler Wert
     */
    @Input()
    public minValue?: number;

    /**
     * Maximaler Wert
     */
    @Input()
    public maxValue?: number;

    /**
     * Präfix
     */
    @Input()
    public praefix?: string;

    /**
     * Suffix
     */
    @Input()
    public suffix?: string;

    /**
     * Hinweistext unter dem Label
     */
    @Input()
    public hint?: string;

    /**
     * Ausrichtung Anzeigewert
     */
    @Input()
    public fxValueAlignment = 'end';

    /**
     * Lokalisierung
     */
    public locale: string;

    /**
     * is required
     */
    @Input()
    public isRequired = false;

    /**
     * Liefert den ursprünglichen Wert
     * (keinen besseren Weg gefunden um generisch einzuschraenken, dass fieldName ein keyof T ist, wo T[fieldName]: number)
     *
     * @returns {string | undefined} Wert
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get originalValue(): any {
        if (this.entity === undefined) {
            return undefined;
        }

        return this.entity[this.fieldName] as number;
    }

    /**
     * Konstruktor
     *
     * @param {string} locale Lokalisierung
     * @param {FinancingService} financingService FinancingService-Injektor
     */
    public constructor(@Inject(LOCALE_ID) locale: string, financingService: FinancingService) {
        super(ValueStorageType.Decimal, financingService);
        // Bei östereichischer lokalisierung dennoch deutsches Zahlenformat
        this.locale = locale === 'de-AT' ? 'de' : locale;
    }

    /**
     * Angular-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.options = {
            align: this.fxValueAlignment === 'end' ? 'right' : 'left',
            allowNegative: this.minValue !== undefined && this.minValue < 0,
            decimal: getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyDecimal),
            precision: HelperService.getMaxDecimalPlacesByFormat(this.format),
            prefix: this.praefix !== undefined ? `${this.praefix} ` : '',
            suffix: this.suffix !== undefined ? ` ${this.suffix}` : '',
            thousands: getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyGroup),
            allowZero: this.minValue === undefined || this.minValue <= 0,
            nullable: true,
            inputMode: NgxCurrencyInputMode.Natural,
            min: this.minValue,
            max: this.maxValue,
        };
    }
}
