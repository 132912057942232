import { Pipe, PipeTransform } from '@angular/core';

/**
 * Custom Pipe for formatting currency
 */
@Pipe({
    name: 'formatCurrency',
})
export class FormatCurrencyPipe implements PipeTransform {
    
    /**
     * format Currency
     * e.g.: 3000 -> € €3.000,00
     *
     * @param {number | undefined} value number
     * @returns {string} formatted currency
     */
    public transform(value: number | undefined | null): string {
        if (value === undefined || value === null) {
            return '';
        }
        // Convert the number to a string and replace '.' with ','
        let formattedValue = value.toFixed(2).replace('.', ',');

        // Insert '.' every three characters from the end to the start
        const regex = /\B(?=(\d{3})+(?!\d))/g;
        formattedValue = formattedValue.replace(regex, '.');

        // Add the '€' symbol at the start
        return `€ ${formattedValue}`;
    }
}
