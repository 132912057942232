<div right class="waiter w-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <waiter-component [isLoading]="loading"></waiter-component>
</div>
<div *ngIf="!loading" right fxLayout="column" fxFill>
    <mat-tab-group [class.header-less-tabs]="(this.financingMode$ | async) === FinancingMode.RiskFinancingPlan"
        class="w-100" (selectedIndexChange)="tabChanged($event)" [selectedIndex]="selectedTabIndex$ | async">
        <mat-tab *ngIf="getTabVisibility(FinancingTab.General)">
            <ng-template mat-tab-label>{{FinancingTab.General | finprocessEnumTranslation : 'FinancingTab'}}
            </ng-template>
            <ng-template matTabContent>
                <finprocess-general></finprocess-general>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="getTabVisibility(FinancingTab.Formal)">
            <ng-template mat-tab-label>{{FinancingTab.Formal | finprocessEnumTranslation : 'FinancingTab'}}
            </ng-template>
            <ng-template matTabContent>
                <finprocess-formal></finprocess-formal>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="getTabVisibility(FinancingTab.Debitor)">
            <ng-template mat-tab-label>{{FinancingTab.Debitor | finprocessEnumTranslation : 'FinancingTab'}}
            </ng-template>
            <ng-template matTabContent>
                <finprocess-debitor></finprocess-debitor>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="getTabVisibility(FinancingTab.Household)">
            <ng-template mat-tab-label>{{FinancingTab.Household | finprocessEnumTranslation : 'FinancingTab'}}
            </ng-template>
            <ng-template matTabContent>
                <finprocess-household></finprocess-household>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>{{FinancingTab.FinancingPlan | finprocessEnumTranslation : 'FinancingTab'}}
            </ng-template>
            <ng-template matTabContent>
                <finprocess-financing-plan></finprocess-financing-plan>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="getTabVisibility(FinancingTab.RealEstate)">
            <ng-template mat-tab-label>{{FinancingTab.RealEstate | finprocessEnumTranslation : 'FinancingTab'}}
            </ng-template>
            <ng-template matTabContent>
                <finprocess-real-estate></finprocess-real-estate>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="getTabVisibility(FinancingTab.ProductCalculator)">
            <ng-template mat-tab-label>{{FinancingTab.ProductCalculator | finprocessEnumTranslation :
                'FinancingTab'}}</ng-template>
            <ng-template matTabContent>
                <finprocess-product-calculator></finprocess-product-calculator>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="getTabVisibility(FinancingTab.Documents)">
            <ng-template mat-tab-label>{{FinancingTab.Documents | finprocessEnumTranslation : 'FinancingTab'}}
            </ng-template>
            <ng-template matTabContent>
                <finprocess-documents></finprocess-documents>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="getTabVisibility(FinancingTab.Process)">
            <ng-template mat-tab-label>{{FinancingTab.Process | finprocessEnumTranslation : 'FinancingTab'}}
            </ng-template>
            <ng-template matTabContent>
                <finprocess-process></finprocess-process>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="getTabVisibility(FinancingTab.SampleCalculation)">
            <ng-template mat-tab-label>{{FinancingTab.SampleCalculation | finprocessEnumTranslation :
                'FinancingTab'}}
            </ng-template>
            <ng-template matTabContent>
                <finprocess-sample-calculation></finprocess-sample-calculation>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>