<!--Wertpapierdepot-->
<ng-container *ngFor="let securityPaper of collateralSecurityPaper; let i = index">
    <mat-expansion-panel *ngIf="!!securityPaper" class="bg-dark-2 mb-3" [hideToggle]="true" [expanded]="!!collateralAccountDetailExpand[securityPaper.id]">
        <mat-expansion-panel-header class="header-1" style="cursor: default;">
            <div fxLayout="column" style="width: 100%;" (click)="$event.stopImmediatePropagation()">
                <div fxlayout="row">
                    <button mat-icon-button
                        (click)="collateralAccountDetailExpand[securityPaper.id] = !collateralAccountDetailExpand[securityPaper.id]; $event.stopImmediatePropagation()">
                        <mat-icon class="grey-color">{{ collateralAccountDetailExpand[securityPaper.id] ?
                            'expand_less' : 'expand_more'
                            }}</mat-icon>
                    </button>
                    <label fxFlex="80" class="mt-2"><b>{{securityPaper.collateralType ?? 'Wertpapierpaket' |
                            finprocessEnumTranslation:'CollateralType' }}</b></label>
                    <button class="delete" [disabled]="(editingReadonly$ | async)" fxFlex="15" mat-icon-button (click)="deleteCollateral(securityPaper.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <mat-divider></mat-divider>

                <div fxlayout="row" class="mt-2" fxLayoutAlign="start center"
                    (click)="$event.stopImmediatePropagation()">

                    <!--rechtlicher Status-->
                    <finprocess-label-value class="legalstatus" fxFlex="20" *ngIf="!!securityPaper.legalStatus">
                        <span label
                            class="legalstatus-label">{{'asset.assetDetails.accountDetails.assignmentTypeControl' |
                            translate}}</span>
                        <span value [ngStyle]="{'color': (editingReadonly$ | async) ? 'rgba(0, 0, 0, 0.38)' : 'black'}">{{securityPaper.legalStatus | finprocessEnumTranslation:
                            'AssignmentTypeValue'}}</span>
                    </finprocess-label-value>

                    <!--Sind diese Wertpapiere CRR fähig?-->
                    <finprocess-select-input class="header-select" [items]="booleanItems" [overwrite]="false"
                        [entityClassType]="entityClassType.CollateralAccount" [hideHorizontalRule]="true"
                        fieldName="isCrrCapable" [readonly]="editingReadonly$" [flexDirection]="inputFlexDirection"
                        [entity]="securityPaper" [valueStorageType]="ValueStorageType.Bool">
                        <span label class="input-label">{{'asset.assetDetails.accountDetails.securityPaperCRR' |
                            translate}}</span>
                    </finprocess-select-input>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>

        <!--restliche Angaben -->
        <div fxLayout="column" fxLayoutAlign="start baseline" fxLayoutGap="20px" class="mb-2 mt-3">
            <h4 class="mt-2"><b>{{'asset.assetDetails.insuranceDetails.otherInfos' | translate}}</b></h4>

            <finprocess-textbox-input style="height: 100%;" [overwrite]="false"
                [entityClassType]="entityClassType.CollateralAccount" fieldName="accountNumber"
                [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true" [readonly]="editingReadonlyApprovalView$"
                [entity]="securityPaper">
                <span label class="input-label">{{'asset.assetAccount.accountNumber' | translate}}</span>
            </finprocess-textbox-input>

        </div>

        <!--Wertpapiere hinzufügen-->
        <div class="mb-3 mt-4" fxLayout="row" *ngIf="!!assetSecurityPaper && assetSecurityPaper.length > 0">
            <h4 class="mr-2" style="margin-top: 6px"><b>{{'asset.assetDetails.accountDetails.securityPaperPackages' |
                    translate}}</b></h4>
            <button [disabled]="(editingReadonly$ | async)" mat-mini-fab matTooltip="Wertpapiere zuordnen" [matMenuTriggerFor]="assignSecurityPaper"
                style="box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;"><mat-icon
                    fxLayout="column" fxLayoutAlign="center center">request_quote</mat-icon></button>

            <mat-menu #assignSecurityPaper="matMenu">
                <button [disabled]="(editingReadonly$ | async)" mat-menu-item *ngFor="let aSecurityPaper of assetSecurityPaper"
                    (click)="$event.stopPropagation()"
                    (click)="assignAccounts(aSecurityPaper.id, securityPaper.id, true)">
                    <mat-checkbox color="primary"
                        [checked]="isAccountSelected(aSecurityPaper, securityPaper.id, 'securityPaper')">
                        <ng-container *ngIf="aSecurityPaper.balance; else noSecurityBalance">Depostand: {{
                            aSecurityPaper.balance | formatCurrency}}
                        </ng-container>
                        <ng-template #noSecurityBalance>kein Depostand angegeben</ng-template>
                    </mat-checkbox>
                </button>
            </mat-menu>
        </div>

        <div fxLayout="row" fxLayoutAlign="start" class="mt-4" *ngIf="!assetAccount || assetAccount.length === 0">
            <h4><b>{{'asset.assetDetails.accountDetails.noSecurityPaperDetails' | translate}}</b></h4>
        </div>

        <!--check for zugeordnete Versicherungen-->
        <ng-container *ngFor="let assignAsset of securityPaper.assetAssignmentInfos; let j = index;">
            <mat-expansion-panel *ngIf="!!assignAsset" class="expansion-2" [hideToggle]="true">
                <mat-expansion-panel-header class="header-2">
                    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="width: 100%">

                        <!--materieller Wert gesamt-->
                        <finprocess-label-value *ngIf="!!assignAsset.assetAccount.materialValueTotal">
                            <span label>{{'asset.assetAccount.materialValueTotal' | translate}}</span>
                            <span value>{{assignAsset.assetAccount.materialValueTotal | formatCurrency}}</span>
                        </finprocess-label-value>

                        <!--Depostand-->
                        <finprocess-label-value *ngIf="!!assignAsset.assetAccount.balance">
                            <span label>{{'asset.assetAccount.balance' | translate}}</span>
                            <span value>{{assignAsset.assetAccount.balance | formatCurrency}}</span>
                        </finprocess-label-value>

                        <!--Wertpapierdepotnummer-->
                        <finprocess-label-value *ngIf="!!assignAsset.assetAccount.accountNumber">
                            <span label>{{'asset.assetAccount.accountNumber' | translate}}</span>
                            <span value>{{assignAsset.assetAccount.accountNumber}}</span>
                        </finprocess-label-value>

                        <button class="delete" [disabled]="(editingReadonly$ | async)" mat-icon-button>
                            <mat-icon (click)="assignAccounts(assignAsset.assetId, securityPaper.id, false)">delete</mat-icon>
                        </button>
                    </div>
                </mat-expansion-panel-header>

                <div class="assign-body">
                    <div fxFlex="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20" class="mt-3 mb-3"
                        *ngIf="hasAssignedBodyData(assignAsset.assetAccount)">

                        <finprocess-label-value *ngIf="!!assignAsset.assetAccount.loanRate">
                            <span label>{{'asset.assetAccount.loanRateGeos' | translate}}</span>
                            <span value>{{assignAsset.assetAccount.loanRate | formatCurrency}}</span>
                        </finprocess-label-value>

                        <finprocess-label-value *ngIf="!!assignAsset.assetAccount.materialValueAlreadyInUse">
                            <span label>{{'asset.assetCommon.materialValueAlreadyInUse' | translate}}</span>
                            <span value>{{assignAsset.assetAccount.materialValueAlreadyInUse | formatCurrency}}</span>
                        </finprocess-label-value>

                        <finprocess-label-value *ngIf="!!assignAsset.assetAccount.freeMaterialValue">
                            <span label>{{'asset.assetCommon.freeMaterialValue' | translate}}</span>
                            <span value>{{assignAsset.assetAccount.freeMaterialValue | formatCurrency}}</span>
                        </finprocess-label-value>

                    </div>
                    <div *ngIf="!hasAssignedBodyData(assignAsset.assetAccount)" class="mt-2">Keine Daten vorhanden</div>

                    <div class="mt-4 mb-2">
                        <finprocess-asset-provider class="asset-provider" [assetId]="assignAsset.assetId"
                            [assetProviders]="assignAsset.assetAccount.assetProviders">
                        </finprocess-asset-provider>
                    </div>
                </div>
            </mat-expansion-panel>
        </ng-container>

        <!--Besicherung-->
        <div class="mb-3 mt-4" fxLayout="column" fxLayoutAlign="start baseline" *ngIf="!!productPackage">
            <h4 class="mr-2"><b>{{'asset.assetDetails.collateralisation' | translate}}</b></h4>

            <div *ngIf="!!dataSources" style="width: 100%">
                <table mat-table [dataSource]="dataSources.get(securityPaper) || []" class="table custom-border">
                    <!-- Position Column -->
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef class="input-label"> {{'asset.assetDetails.tablePosition'
                            | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.position}}. </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="input-label">
                            {{'asset.assetDetails.tableProductName' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.name | finprocessEnumTranslation:
                            'ProductPackageType'}} </td>
                    </ng-container>

                    <!-- Duration Column -->
                    <ng-container matColumnDef="duration">
                        <th mat-header-cell *matHeaderCellDef class="input-label"> {{'asset.assetDetails.tableDuration'
                            | translate}} </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.duration !== '-'">
                                {{element.duration}} {{'asset.assetDetails.tableMonths' | translate}}
                            </ng-container>
                            <ng-container *ngIf="element.duration === '-'">
                                {{element.duration}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <!-- Amount Column -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef class="input-label"> {{'asset.assetDetails.tableAmount' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.amount | formatCurrency}} </td>
                    </ng-container>

                    <!-- collateralized Column -->
                    <ng-container matColumnDef="collateralized">
                        <th mat-header-cell *matHeaderCellDef class="input-label">
                            {{'asset.assetDetails.tableCollateralisation' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> <mat-checkbox color="primary" [disabled]="(editingReadonly$ | async)"
                                [checked]="element.collateralized"
                                (change)="productSecured(securityPaper.id, element.id, $event.checked)"></mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Define column order -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

            </div>
        </div>
    </mat-expansion-panel>
</ng-container>

<!--Kontoguthaben-->
<ng-container *ngFor="let account of collateralAccounts; let i = index">
    <mat-expansion-panel *ngIf="!!account" class="bg-dark-2 mb-3" [hideToggle]="true" [expanded]="!!collateralAccountDetailExpand[account.id]">
        <mat-expansion-panel-header class="header-1" style="cursor: default;">
            <div fxLayout="column" style="width: 100%;" (click)="$event.stopImmediatePropagation()">
                <div fxlayout="row">
                    <button mat-icon-button
                        (click)="collateralAccountDetailExpand[account.id] = !collateralAccountDetailExpand[account.id]; $event.stopImmediatePropagation()">
                        <mat-icon class="grey-color">{{ collateralAccountDetailExpand[account.id] ?
                            'expand_less' : 'expand_more'
                            }}</mat-icon>
                    </button>
                    <label fxFlex="80" class="mt-2"><b>{{account.collateralType ?? 'Kontoguthaben' |
                            finprocessEnumTranslation:'CollateralType' }}</b></label>
                    <button class="delete" [disabled]="(editingReadonly$ | async)" fxFlex="15" mat-icon-button (click)="deleteCollateral(account.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <mat-divider></mat-divider>

                <div fxlayout="row" class="mt-2" fxLayoutAlign="start center"
                    (click)="$event.stopImmediatePropagation()">

                    <!--rechtlicher Status-->
                    <finprocess-label-value class="legalstatus" fxFlex="20">
                        <span label>{{'asset.assetDetails.accountDetails.assignmentTypeControl' | translate}}</span>
                        <span value [ngStyle]="{'color': (editingReadonly$ | async) ? 'rgba(0, 0, 0, 0.38)' : 'black'}">Verpfändung</span>
                    </finprocess-label-value>

                    <finprocess-select-input class="header-select" [items]="booleanItems" [overwrite]="false"
                        [entityClassType]="entityClassType.CollateralAccount" [hideHorizontalRule]="true"
                        fieldName="isCrrCapable" [readonly]="editingReadonly$" [flexDirection]="inputFlexDirection"
                        [entity]="account" [valueStorageType]="ValueStorageType.Bool">
                        <span label class="input-label">{{'asset.assetDetails.accountDetails.accountCRR' |
                            translate}}</span>
                    </finprocess-select-input>

                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>

        <!--restliche Angaben -->
        <div fxLayout="column" fxLayoutAlign="start baseline" fxLayoutGap="20px" class="mb-2 mt-3">
            <h4 class="mt-2"><b>{{'asset.assetDetails.insuranceDetails.otherInfos' | translate}}</b></h4>

            <finprocess-textbox-input style="height: 100%;" [overwrite]="false"
                [entityClassType]="entityClassType.CollateralAccount" fieldName="accountNumber"
                [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true" [readonly]="editingReadonlyApprovalView$"
                [entity]="account">
                <span label class="input-label">{{'asset.assetAccount.accountnr' | translate}}</span>
            </finprocess-textbox-input>

        </div>

        <!--Kontoguthaben hinzufügen-->
        <div class="mb-3 mt-4" fxLayout="row" *ngIf="!!assetAccounts && assetAccounts.length > 0">
            <h4 class="mr-2" style="margin-top: 6px"><b>{{'asset.assetDetails.accountDetails.accountAmount' |
                    translate}}</b></h4>
            <button [disabled]="(editingReadonly$ | async)" mat-mini-fab matTooltip="Kontoguthaben zuordnen" [matMenuTriggerFor]="assignSecurityPaper"
                style="box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;"><mat-icon
                    fxLayout="column" fxLayoutAlign="center center">account_balance_wallet</mat-icon></button>

            <mat-menu #assignSecurityPaper="matMenu">
                <button [disabled]="(editingReadonly$ | async)" mat-menu-item *ngFor="let aAccount of assetAccounts" (click)="$event.stopPropagation()"
                    (click)="assignAccounts(aAccount.id, account.id, true)">
                    <mat-checkbox color="primary" [checked]="isAccountSelected(aAccount, account.id, 'account')">
                        <ng-container *ngIf="aAccount.balance; else noBalance">Kontoguthaben: {{ aAccount.balance | formatCurrency}}
                        </ng-container>
                        <ng-template #noBalance>kein Kontoguthaben angegeben</ng-template>
                    </mat-checkbox>
                </button>
            </mat-menu>
        </div>

        <div fxLayout="row" fxLayoutAlign="start" class="mt-4" *ngIf="!assetAccount || assetAccount.length === 0">
            <h4><b>{{'asset.assetDetails.accountDetails.noAccountDetails' | translate}}</b></h4>
        </div>

        <!--check for zugeordnete Versicherungen-->
        <ng-container *ngFor="let assignAsset of account.assetAssignmentInfos; let j = index;">
            <mat-expansion-panel *ngIf="!!assignAsset" class="expansion-2" [hideToggle]="true">
                <mat-expansion-panel-header class="header-2">
                    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="width: 100%">

                        <!-- Kontostand-->
                        <finprocess-label-value fxFlex="50" *ngIf="!!assignAsset.assetAccount.balance">
                            <span label>{{'asset.assetAccount.balanceBond' | translate}}</span>
                            <span value>{{assignAsset.assetAccount.balance | formatCurrency}}</span>
                        </finprocess-label-value>

                        <!--Bankgarantie-->
                        <finprocess-label-value fxFlex="50"
                            *ngIf="assignAsset.assetAccount.isExclusivelyCollateral !== undefined">
                            <span label>{{'asset.assetAccount.isExclusivelyCollateral' | translate}}</span>
                            <span value *ngIf="assignAsset.assetAccount.isExclusivelyCollateral">Ja</span>
                            <span value *ngIf="!assignAsset.assetAccount.isExclusivelyCollateral">Nein</span>
                        </finprocess-label-value>

                        <button class="delete" [disabled]="(editingReadonly$ | async)" mat-icon-button>
                            <mat-icon (click)="assignAccounts(assignAsset.assetId, account.id, false)">delete</mat-icon>
                        </button>
                    </div>
                </mat-expansion-panel-header>

                <div class="assign-body">
                    <div fxFlex="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20" class="mt-3 mb-3"
                        *ngIf="hasAssignedBodyData(assignAsset.assetAccount)">

                        <finprocess-label-value *ngIf="!!assignAsset.assetAccount.loanRate">
                            <span label>{{'asset.assetAccount.loanRate' | translate}}</span>
                            <span value>{{assignAsset.assetAccount.loanRate | formatCurrency}}</span>
                        </finprocess-label-value>

                        <finprocess-label-value *ngIf="!!assignAsset.assetAccount.materialValueAlreadyInUse">
                            <span label>{{'asset.assetCommon.materialValueAlreadyInUse' | translate}}</span>
                            <span value>{{assignAsset.assetAccount.materialValueAlreadyInUse | formatCurrency}}</span>
                        </finprocess-label-value>

                        <finprocess-label-value *ngIf="!!assignAsset.assetAccount.freeMaterialValue">
                            <span label>{{'asset.assetCommon.freeMaterialValue' | translate}}</span>
                            <span value>{{assignAsset.assetAccount.freeMaterialValue | formatCurrency}}</span>
                        </finprocess-label-value>

                    </div>
                    <div *ngIf="!hasAssignedBodyData(assignAsset.assetAccount)" class="mt-2">Keine Daten vorhanden</div>
                    
                    <div class="mt-4 mb-2">
                        <finprocess-asset-provider class="asset-provider" [assetId]="assignAsset.assetId"
                            [assetProviders]="assignAsset.assetAccount.assetProviders">
                        </finprocess-asset-provider>
                    </div>

                </div>
            </mat-expansion-panel>
        </ng-container>

        <!--Besicherung-->
        <div class="mb-3 mt-4" fxLayout="column" fxLayoutAlign="start baseline" *ngIf="!!productPackage">
            <h4 class="mr-2"><b>{{'asset.assetDetails.collateralisation' | translate}}</b></h4>

            <div *ngIf="!!dataSources" style="width: 100%">
                <table mat-table [dataSource]="dataSources.get(account) || []" class="table custom-border">
                    <!-- Position Column -->
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef class="input-label"> {{'asset.assetDetails.tablePosition'
                            | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.position}}. </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="input-label">
                            {{'asset.assetDetails.tableProductName' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.name | finprocessEnumTranslation:
                            'ProductPackageType'}}</td>
                    </ng-container>

                    <!-- Duration Column -->
                    <ng-container matColumnDef="duration">
                        <th mat-header-cell *matHeaderCellDef class="input-label"> {{'asset.assetDetails.tableDuration'
                            | translate}} </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.duration !== '-'">
                                {{element.duration}} {{'asset.assetDetails.tableMonths' | translate}}
                            </ng-container>
                            <ng-container *ngIf="element.duration === '-'">
                                {{element.duration}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <!-- Amount Column -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef class="input-label"> {{'asset.assetDetails.tableAmount' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.amount | formatCurrency}} </td>
                    </ng-container>

                    <!-- collateralized Column -->
                    <ng-container matColumnDef="collateralized">
                        <th mat-header-cell *matHeaderCellDef class="input-label">
                            {{'asset.assetDetails.tableCollateralisation' | translate}}</th>
                        <td mat-cell *matCellDef="let element"> <mat-checkbox color="primary" [disabled]="(editingReadonly$ | async)"
                                [checked]="element.collateralized"
                                (change)="productSecured(account.id, element.id, $event.checked)"></mat-checkbox> </td>
                    </ng-container>

                    <!-- Define column order -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

            </div>
        </div>
    </mat-expansion-panel>
</ng-container>