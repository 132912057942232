import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CollateralType } from '@ntag-ef/finprocess-enums';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService, UUID } from 'app/modules/shared';
import { Observable, iif, map, mergeMap, of } from 'rxjs';

import { EntityClassType } from '../../enums';
import { IAssetData } from '../../interfaces';
import { AssetsActive, AssetsCreate, AssetsLoaded, CollateralCreate, CollateralLoaded } from '../../states/asset/asset.actions';

/**
 * Asset Service
 */
@Injectable()
export class AssetService {

    /**
     * Konstruktor
     *
     * @param {AuthorizationService} authorizationService AuthorizationService
     * @param {HttpClient} httpClient HTTPClient
     * @param {ConfigService} config ConfigService
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private authorizationService: AuthorizationService,
        private httpClient: HttpClient,
        private config: ConfigService,
        private store: Store,
    ) { }

    /** 
     * Sicherheiten laden
     *  
     * @param {string} financingContainerID financingContainerID ID
     * @returns { Observable<IAssetData> } Observable<IAssetData>
     */
    public getAssets(financingContainerID: string): Observable<IAssetData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.get<IAssetData>(`${this.config.getEnvironment().apiUrl}/Assets/Get`,
                        {
                            params:
                            {
                                financingContainerId: financingContainerID,
                            },
                        }).pipe(
                        mergeMap(result => this.store.dispatch(new AssetsLoaded(result)).pipe(map(() => result))),
                    ),
                    of(undefined),
                ),
            ),
        );
    }

    /** 
     * Set Sicherheit active/inactive
     *  
     * @param {UUID} financingContainerId financingContainerID ID
     * @param {UUID} assetId assetId
     * @param {boolean} isActive isActive
     * @returns { Observable<IAssetData> } Observable<IAssetData>
     */
    public setAssetActive(financingContainerId: UUID, assetId: UUID, isActive: boolean): Observable<IAssetData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.put<IAssetData>(`${this.config.getEnvironment().apiUrl}/Assets/SetActive`, undefined,
                        {
                            params:
                            {
                                financingContainerId: financingContainerId,
                                assetId: assetId,
                                isActive: isActive,
                            },
                        }).pipe(mergeMap(assetData => this.store.dispatch(new AssetsActive(assetData)).pipe(map(() => assetData))),
                    ),
                    of(undefined),
                ),
            ),
        );
    }

    /** 
     * Neue Sicherheit erstellen
     *  
     * @param {string} financingContainerId financingContainerId Id
     * @param { EntityClassType } type entityClassType
     * @param { boolean } isBonds boolean
     * @returns { Observable<IAssetData> } Observable<IAssetData>
     */
    public createNewAsset(financingContainerId: string, type: EntityClassType, isBonds?: boolean): Observable<IAssetData | undefined> {
        const params: { financingContainerId: string, type: EntityClassType, isBonds?: boolean } = {
            financingContainerId,
            type,
        };

        if (isBonds !== undefined) {
            params.isBonds = isBonds;
        }

        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized, this.httpClient.post<IAssetData>(`${this.config.getEnvironment().apiUrl}/Assets/Create`, undefined, { params })
                        .pipe(mergeMap(assetData => this.store.dispatch(new AssetsCreate(assetData)).pipe(map(() => assetData)))),
                    of(undefined),
                )),
        );
    }

    /** 
     * Sicherheit löschen
     *  
     * @param {string} productPackageId productPackageId Id
     * @param { collateralID } collateralID collateral id
     * @returns { Observable<IAssetData> } Observable<IAssetData>
     */
    public deleteAsset(productPackageId: string, collateralID: string): Observable<IAssetData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized, this.httpClient.post<IAssetData>(`${this.config.getEnvironment().apiUrl}/Collateral/`, undefined, //todo
                        {
                            params: {
                                productPackageId: productPackageId,
                                id: collateralID,
                            },
                        }),
                    of(undefined),
                )));
    }


    /** 
     * Sicherheitdetails laden
     * 
     * @param {string} productPackageID ProductPackageID
     * @returns { Observable<IAssetData> } Observable<IAssetData>
     */
    public getCollaterals(productPackageID: string): Observable<IAssetData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => (authorized ? this.httpClient.get<IAssetData>(`${this.config.getEnvironment().apiUrl}/Collateral/Get`,
                {
                    params:
                    {
                        productPackageId: productPackageID,
                    },
                }).pipe(
                mergeMap(result => this.store.dispatch(new CollateralLoaded(result)).pipe(map(() => result))),
            ) : of(undefined)),
            ));
    }

    /** 
     * Sicherheitdetail erstellen
     * 
     * @param {string} productPackageID ProductPackageID
     * @param {CollateralType | string} collateralType type of collateral
     * @returns { Observable<IAssetData> } Observable<IAssetData>
     */
    public createNewCollateral(productPackageID: string, collateralType: CollateralType | string): Observable<IAssetData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized, this.httpClient.post<IAssetData>(`${this.config.getEnvironment().apiUrl}/Collateral/Create`, undefined,
                        {
                            params: {
                                type: collateralType,
                                productPackageId: productPackageID,
                            },
                        }).pipe(mergeMap(collateralData => this.store.dispatch(new CollateralCreate(collateralData)).pipe(map(() => collateralData)))),
                    of(undefined),
                )));
    }

    /** 
     * Sicherheitdetail zuordnen
     * 
     * @param {string} collateralID collateralID
     * @param {string} assetID assetID
     * @param {string} productPackageID productPackageID
     * @returns { Observable<IAssetData> } Observable<IAssetData>
     */
    public assignCollateral(collateralID: string, assetID: string, productPackageID: string): Observable<IAssetData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.patch<IAssetData>(`${this.config.getEnvironment().apiUrl}/Collateral/ChangeAssetCollateralAssignment`, {}, {
                        params: {
                            collateralId: collateralID,
                            assetId: assetID,
                            productPackageId: productPackageID,
                        },
                    }).pipe(mergeMap(collateralData => this.store.dispatch(new CollateralCreate(collateralData)).pipe(map(() => collateralData)))),
                    of(undefined),
                )));
    }


    /** 
     * Sicherheitsdetail löschen
     *  
     * @param {string} productPackageID financingContainerId Id
     * @param { string } collateralID collateral id
     * @returns { Observable<IAssetData> } Observable<IAssetData>
     */
    public deleteCollateral(productPackageID: string, collateralID: string): Observable<IAssetData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized, this.httpClient.delete<IAssetData>(`${this.config.getEnvironment().apiUrl}/Collateral/Delete`,
                        {
                            params: {
                                collateralId: collateralID,
                                productPackageId: productPackageID,
                            },
                        }).pipe(mergeMap(collateralData => this.store.dispatch(new CollateralCreate(collateralData)).pipe(map(() => collateralData)))),
                    of(undefined),
                )));
    }

    /** 
     * Produkt besichern
     * 
     * @param {string} collateralID collateralID
     * @param {string} productID productID
     * @param {string} productPackageID productPackageID
     * @param {boolean} productIsSecured true / false
     * @returns { Observable<IAssetData> } Observable<IAssetData>
     */
    public secureProductToCollateral(collateralID: string, productID: string, productPackageID: string, productIsSecured: boolean): Observable<IAssetData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.patch<IAssetData>(`${this.config.getEnvironment().apiUrl}/Collateral/ChangeProductCollateralAssignment`, {}, {
                        params: {
                            collateralId: collateralID,
                            productId: productID,
                            productPackageId: productPackageID,
                            isSecured: productIsSecured,
                        },
                    }).pipe(mergeMap(collateralData => this.store.dispatch(new CollateralCreate(collateralData)).pipe(map(() => collateralData)))),
                    of(undefined),
                )));
    }

    /**
     * add AGP Collateral
     *
     * @param {string} collateralID collateralID
     * @param {string} productPackageID productPackageID
     * @returns {Observable<IAssetData>} Observable<IAssetData>
     */
    public addAGPCollateral(collateralID: string, productPackageID: string): Observable<IAssetData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<IAssetData>(`${this.config.getEnvironment().apiUrl}/Collateral/Reuse`, undefined, {
                        params: {
                            collateralId: collateralID,
                            productPackageId: productPackageID,
                        },
                    }).pipe(
                        mergeMap(collateralData =>
                            this.store.dispatch(new CollateralCreate(collateralData)).pipe(
                                map(() => collateralData),
                            ),
                        ),
                    ),
                    of(undefined),
                ),
            ))
    }
}
