<ng-container *ngFor="let insurance of collateralInsuranceDetail; let i = index">
    <mat-expansion-panel *ngIf="!!insurance" class="bg-dark-2 mb-3" [hideToggle]="true" [expanded]="!!collateralInsuranceDetailExpand[insurance.id]">
        <mat-expansion-panel-header class="header-1" style="cursor: default;">
            <div fxLayout="column" style="width: 100%;" (click)="$event.stopImmediatePropagation()">
                <div fxlayout="row">
                    <button mat-icon-button
                        (click)="collateralInsuranceDetailExpand[insurance.id] = !collateralInsuranceDetailExpand[insurance.id]; $event.stopImmediatePropagation()">
                        <mat-icon class="grey-color">{{ collateralInsuranceDetailExpand[insurance.id] ?
                            'expand_less' : 'expand_more'
                            }}</mat-icon>
                    </button>
                    <label fxFlex="80" class="mt-2"><b>{{insurance.collateralType ??
                            'Versicherung' | finprocessEnumTranslation:'CollateralType' }}</b></label>
                    <button class="delete" [disabled]="(editingReadonly$ | async)" fxFlex="15" mat-icon-button (click)="deleteCollateral(insurance.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <mat-divider></mat-divider>

                <div fxlayout="row" class="mt-2" fxLayoutAlign="start center">
                    <div (click)="$event.stopImmediatePropagation()">

                        <!--rechtlicher Status-->
                        <finprocess-select-input style="height:100%" [items]="assignmentTypeControl" [overwrite]="false"
                            [entityClassType]="entityClassType.CollateralInsurance" [hideHorizontalRule]="true"
                            fieldName="legalStatus" [readonly]="editingReadonly$" [entity]="insurance">
                            <span label
                                class="input-label">{{'asset.assetDetails.insuranceDetails.assignmentTypeControl' |
                                translate}}</span>
                        </finprocess-select-input>

                    </div>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider class="mt-2"></mat-divider>

        <!--sonstige angaben-->
        <div fxLayout="column" fxLayoutAlign="start baseline" fxLayoutGap="20px" class="mb-2 mt-3">
            <h4 class="mt-2"><b>{{'asset.assetDetails.insuranceDetails.otherInfos' | translate}}</b></h4>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20">

                <!--Ist diese Versicherung CRR fähig?-->
                <!--"Nicht nötig bei: BA130, BA135" -> nicht Pflichtfeld-->
                <finprocess-select-input [items]="booleanItems" [overwrite]="false" class="select-value"
                    [entityClassType]="entityClassType.CollateralInsurance" [hideHorizontalRule]="true"
                    [flexDirection]="inputFlexDirection" fieldName="isCrrCapable" [readonly]="editingReadonly$"
                    [entity]="insurance" [valueStorageType]="ValueStorageType.Bool">
                    <span label class="input-label">{{'asset.assetDetails.insuranceDetails.insuranceCRR' |
                        translate}}</span>
                </finprocess-select-input>

                <!--Selbstmordklausel-->
                <finprocess-select-input [items]="booleanItems" [overwrite]="false" class="select-value"
                    [entityClassType]="entityClassType.CollateralInsurance" [hideHorizontalRule]="true"
                    [flexDirection]="inputFlexDirection" fieldName="isSuicideClause" [readonly]="editingReadonlyApprovalView$"
                    [entity]="insurance" [valueStorageType]="ValueStorageType.Bool">
                    <span label class="input-label">{{'asset.assetDetails.insuranceDetails.isSuicideClause' |
                        translate}}</span>
                </finprocess-select-input>
            </div>
        </div>

        <!--Versicherungen hinzufügen-->
        <div class="mb-3 mt-4" fxLayout="row" *ngIf="!!assetInsurance && assetInsurance.length > 0">
            <h4 class="mr-2" style="margin-top: 6px"><b>{{'asset.assetDetails.insuranceDetails.insurance' |
                    translate}}</b></h4>
            <button [disabled]="(editingReadonly$ | async)" mat-mini-fab matTooltip="Versicherung zuordnen" [matMenuTriggerFor]="assignInsurance"
                style="box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;"><mat-icon
                    fxLayout="column" fxLayoutAlign="center center">health_and_safety</mat-icon></button>

            <mat-menu #assignInsurance="matMenu">
                <button [disabled]="(editingReadonly$ | async)" mat-menu-item *ngFor="let aInsurance of assetInsurance" (click)="$event.stopPropagation()"
                    (click)="assignInsurances(aInsurance.id, insurance.id, true)">
                    <mat-checkbox color="primary"
                        [checked]="isInsuranceSelected(aInsurance, insurance.id)">{{(aInsurance.insuranceType !== null && aInsurance.insuranceType !== undefined) ?
                            (aInsurance.insuranceType | finprocessEnumTranslation: 'CollateralType') : 'keine Versicherungsart angegeben'}}                        
                    </mat-checkbox>
                </button>
            </mat-menu>
        </div>

        <div fxLayout="row" fxLayoutAlign="start" class="mt-4" *ngIf="!assetInsurance || assetInsurance.length === 0">
            <h4><b>{{'asset.assetDetails.insuranceDetails.noInsuranceDetails' | translate}}</b></h4>
        </div>

        <!--check for zugeordnete Versicherungen-->
        <ng-container *ngFor="let assignAsset of insurance.assetAssignmentInfos; let j = index;">
            <mat-expansion-panel class="mt-3 mb-3 expansion-2" *ngIf="!!assignAsset" [hideToggle]="true">
                <mat-expansion-panel-header class="header-2">
                    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="width: 100%">

                        <button fxFlex="5" mat-icon-button
                            (click)="assetInsuranceExpand[assignAsset.assetId] = !assetInsuranceExpand[assignAsset.assetId]">
                            <mat-icon class="grey-color">{{ assetInsuranceExpand[assignAsset.assetId] ?
                                'expand_less' : 'expand_more'
                                }}</mat-icon>
                        </button>

                        <div fxLayout="column" fxFlex="90">

                            <div fxLayout="row" class="mt-2">

                                <finprocess-label-value fxFlex="33"
                                    *ngIf="assignAsset.assetInsurance.insuranceType !== undefined">
                                    <span label>{{'asset.assetInsurance.insuranceType' | translate}}</span>
                                    <span value><small>{{assignAsset.assetInsurance.insuranceType |
                                            finprocessEnumTranslation: 'CollateralType'}}</small></span>
                                </finprocess-label-value>

                                <finprocess-label-value *ngIf="!!assignAsset.assetInsurance.insuranceCompanyName">
                                    <span label>{{'asset.assetInsurance.insuranceCompanyName' | translate}}</span>
                                    <span
                                        value><small>{{assignAsset.assetInsurance.insuranceCompanyName}}</small></span>
                                </finprocess-label-value>
                            </div>

                            <div fxLayout="row" class="mb-2 mt-2">

                                <finprocess-label-value fxFlex="33" *ngIf="!!assignAsset.assetInsurance.insuranceSum">
                                    <span label>{{'asset.assetInsurance.insuranceSum' | translate}}</span>
                                    <span value>{{assignAsset.assetInsurance.insuranceSum | formatCurrency}}</span>
                                </finprocess-label-value>

                                <finprocess-label-value fxFlex="33" *ngIf="!!assignAsset.assetInsurance.deathSum">
                                    <span label>{{'asset.assetInsurance.deathSum' | translate}}</span>
                                    <span value>{{assignAsset.assetInsurance.deathSum | formatCurrency}}</span>
                                </finprocess-label-value>

                                <finprocess-label-value fxFlex="33"
                                    *ngIf="!!assignAsset.assetInsurance.materialValueTotal">
                                    <span label>{{'asset.assetInsurance.materialValueTotal' | translate}}</span>
                                    <span value>{{assignAsset.assetInsurance.materialValueTotal | formatCurrency}}</span>
                                </finprocess-label-value>
                            </div>
                        </div>
                        <button class="delete" [disabled]="(editingReadonly$ | async)" fxFlex="5" mat-icon-button>
                            <mat-icon (click)="assignInsurances(assignAsset.assetId, insurance.id, false)">delete</mat-icon>
                        </button>
                    </div>
                </mat-expansion-panel-header>

                <div class="assign-body">
                    <div fxFlex="row" fxLayoutAlign="start center" class="wrapper-3 mt-3"
                        *ngIf="hasAssignedBodyData(assignAsset.assetInsurance)">

                        <finprocess-label-value *ngIf="!!assignAsset.assetInsurance.redemptionValueCurrency">
                            <span label>{{'asset.assetInsurance.redemptionValueCurrency' | translate}}</span>
                            <span value>{{assignAsset.assetInsurance.redemptionValueCurrency}}</span>
                        </finprocess-label-value>

                        <finprocess-label-value *ngIf="!!assignAsset.assetInsurance.currentCollateralValue">
                            <span label>{{'asset.assetInsurance.currentCollateralValue' | translate}}</span>
                            <span value>{{assignAsset.assetInsurance.currentCollateralValue | formatCurrency}}</span>
                        </finprocess-label-value>

                        <finprocess-label-value *ngIf="!!assignAsset.assetInsurance.currentCollateralValuePer">
                            <span label>{{'asset.assetInsurance.currentCollateralValuePer' | translate}}</span>
                            <span value>{{assignAsset.assetInsurance.currentCollateralValuePer | formatCurrency}}</span>
                        </finprocess-label-value>

                        <finprocess-label-value *ngIf="!!assignAsset.assetInsurance.applicationNo">
                            <span label>{{'asset.assetInsurance.applicationNo' | translate}}</span>
                            <span value>{{assignAsset.assetInsurance.applicationNo}}</span>
                        </finprocess-label-value>

                        <finprocess-label-value *ngIf="!!assignAsset.assetInsurance.materialValueAlreadyInUse">
                            <span label>{{'asset.assetInsurance.materialValueAlreadyInUse' | translate}}</span>
                            <span value>{{assignAsset.assetInsurance.materialValueAlreadyInUse | formatCurrency}}</span>
                        </finprocess-label-value>

                        <finprocess-label-value *ngIf="!!assignAsset.assetInsurance.freeMaterialValue">
                            <span label>{{'asset.assetInsurance.freeMaterialValue' | translate}}</span>
                            <span value>{{assignAsset.assetInsurance.freeMaterialValue | formatCurrency}}</span>
                        </finprocess-label-value>

                        <finprocess-label-value *ngIf="!!assignAsset.assetInsurance.insuranceStart">
                            <span label>{{'asset.assetInsurance.insuranceStart' | translate}}</span>
                            <span value>{{assignAsset.assetInsurance.insuranceStart | date:'dd.MM.yyyy'}}</span>
                        </finprocess-label-value>

                        <finprocess-label-value *ngIf="!!assignAsset.assetInsurance.insuranceEnd">
                            <span label>{{'asset.assetInsurance.insuranceEnd' | translate}}</span>
                            <span value>{{assignAsset.assetInsurance.insuranceEnd | date:'dd.MM.yyyy'}}</span>
                        </finprocess-label-value>
                    </div>
                    <div *ngIf="!hasAssignedBodyData(assignAsset.assetInsurance)" class="mt-2">Keine Daten vorhanden
                    </div>

                    <div class="mt-4 mb-2">
                        <finprocess-asset-provider class="asset-provider" [assetId]="assignAsset.assetId"
                            [assetProviders]="assignAsset.assetInsurance.assetProviders">
                        </finprocess-asset-provider>
                    </div>
                </div>
            </mat-expansion-panel>
        </ng-container>

        <!--Besicherung-->
        <div class="mb-3 mt-4" fxLayout="column" fxLayoutAlign="start baseline" *ngIf="!!productPackage">
            <h4 class="mr-2"><b>{{'asset.assetDetails.collateralisation' | translate}}</b></h4>

            <div *ngIf="!!dataSources" style="width: 100%">
                <table mat-table [dataSource]="dataSources.get(insurance) || []" class="table custom-border">
                    <!-- Position Column -->
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef class="input-label"> {{'asset.assetDetails.tablePosition'
                            | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.position}}. </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="input-label">
                            {{'asset.assetDetails.tableProductName' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.name | finprocessEnumTranslation:
                            'ProductPackageType'}} </td>
                    </ng-container>

                    <!-- Duration Column -->
                    <ng-container matColumnDef="duration">
                        <th mat-header-cell *matHeaderCellDef class="input-label"> {{'asset.assetDetails.tableDuration'
                            | translate}} </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.duration !== '-'">
                                {{element.duration}} {{'asset.assetDetails.tableMonths' | translate}}
                            </ng-container>
                            <ng-container *ngIf="element.duration === '-'">
                                {{element.duration}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <!-- Amount Column -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef class="input-label"> {{'asset.assetDetails.tableAmount' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.amount | formatCurrency}} </td>
                    </ng-container>

                    <!-- collateralized Column -->
                    <ng-container matColumnDef="collateralized">
                        <th mat-header-cell *matHeaderCellDef class="input-label">
                            {{'asset.assetDetails.tableCollateralisation' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> <mat-checkbox color="primary" [disabled]="(editingReadonly$ | async)"
                                [checked]="element.collateralized"
                                (change)="productSecured(insurance.id, element.id, $event.checked)"></mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Define column order -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

            </div>
        </div>
    </mat-expansion-panel>
</ng-container>