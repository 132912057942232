import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Renderer2 } from '@angular/core';
import { LiabilityType } from '@ntag-ef/finprocess-enums';
import { ISortBy, sort } from 'fast-sort';
import { extension } from 'mime-types';
import moment from 'moment';
import { NgxCurrencyConfig } from 'ngx-currency';
import { FinancingStatus, ILiabilityConfiguration } from 'shared/data';

import { IsoDate } from '../../types';

import { IMultiSelectItem, ISelectItem } from './../../interfaces';

/**
 * Service mit statischen Hilfsfunktionen
 */
export class HelperService {

    /**
     * Ermittelt die gesetzten Bits als Zahl
     *
     * @param {boolean} value Zahl
     * @returns {number[]} Gesetzte Bits als Zahl
     */
    public static getBitNumbers(value: number): number[] {
        return value.toString(2).split('').reverse().map((char: string, index: number) => (char === '1' ? Math.pow(2, index) : undefined)).filter(it => it !== undefined) as number[];
    }

    /**
     * Prüft, ob ein bestimmtes Bit gesetzt ist
     *
     * @param {any | undefined} value Zu prüfender Wert
     * @param {any} bit Zu prüfendes Bit
     * @returns {boolean} Bit gesetzt
     */
    public static hasBit<T>(value: T | undefined, bit: T): boolean {
        if (value === undefined || value === null) {
            return false;
        }
        else {
            return ((value as unknown as number) & (bit as unknown as number)) === bit;
        }
    }

    /**
     * Gibt zu Enum Array zurück
     *
     * @param {Record<string, number>} enumObject Enum
     * @param {boolean} returnNumbers Soll der Zahlenwert zurückgegeben werden - default: false
     * @param {any} ignoreValues Werte die ausgeschlossen werden sollen
     * @returns {string[] | number[]} Enum als Array
     */
    public static getEnumArray(enumObject: Record<string, unknown>, returnNumbers = true, ignoreValues: unknown[] = []): string[] | number[] {
        return Object.keys(enumObject)
            .map(key => enumObject[key])
            .filter(value =>
                !ignoreValues.includes(value) &&
                (
                    (typeof value === 'number' && returnNumbers) ||
                    (typeof value === 'string' && !returnNumbers)
                )) as string[] | number[];
    }

    /**
     * Gibt eine sortierte Liste an Select Items als Stringwerte zurück
     * 
     * @param {Record} enumObject Enum
     * @param {(value: number) => string | undefined} translateFunction Übersetzungsfunktion
     * @returns {ISelectItem[]} SelectItems
     */
    public static getSortedSelectItems<T extends Record<string, unknown>>(enumObject: T, translateFunction: (value: number) => string | undefined): ISelectItem<string>[];
    /**
     * Gibt eine sortierte Liste an Select Items als Stringwerte zurück
     * 
     * @param {Record} enumObject Enum
     * @param {(value: number) => string | undefined} translateFunction Übersetzungsfunktion
     * @param {number[]} removeValues Zu entfernende Werte des Enums
     * @returns {ISelectItem[]} SelectItems
     */
    public static getSortedSelectItems<T extends Record<string, unknown>>(enumObject: T, translateFunction: (value: number) => string | undefined, removeValues: number[]): ISelectItem<string>[];
    /**
     * Gibt eine sortierte Liste an Select Items als numbers zurück
     * 
     * @param {Record} enumObject Enum
     * @param {(value: number) => string | undefined} translateFunction Übersetzungsfunktion
     * @param {number[]} removeValues Zu entfernende Werte des Enums
     * @param {true} returnNumbers  returnNumbers
     * @returns {ISelectItem[]} SelectItems
     */
    public static getSortedSelectItems<T extends Record<string, unknown>>(enumObject: T, translateFunction: (value: number) => string | undefined, removeValues: number[], returnNumbers: true): ISelectItem<number>[];
    /**
     * Gibt eine sortierte Liste an Select Items als Stringwerte zurück
     * 
     * @param {Record} enumObject Enum
     * @param {(value: number) => string | undefined} translateFunction Übersetzungsfunktion
     * @param {number[]} removeValues Zu entfernende Werte des Enums
     * @param {false} returnNumbers  returnNumbers
     * @returns {ISelectItem[]} SelectItems
     */
    public static getSortedSelectItems<T extends Record<string, unknown>>(enumObject: T, translateFunction: (value: number) => string | undefined, removeValues: number[], returnNumbers: false): ISelectItem<string>[];
    /**
     * Gibt eine sortierte Liste an Select Items zurück
     * 
     * @param {Record} enumObject Enum
     * @param {(value: number) => string | undefined} translateFunction Übersetzungsfunktion
     * @param {number[]} removeValues Zu entfernende Werte des Enums
     * @param {boolean} returnNumbers  returnNumbers
     * @returns {ISelectItem[]} SelectItems
     */
    public static getSortedSelectItems<T extends Record<string, unknown>>(enumObject: T, translateFunction: (value: number) => string | undefined, removeValues: number[] = [], returnNumbers = false): ISelectItem<string | number>[] {
        let enumArray = this.getEnumArray(enumObject, true) as number[];

        if (removeValues.length > 0) {
            enumArray = enumArray.filter(value => !removeValues.includes(value));
        }

        return sort(enumArray.map(e => ({
            value: returnNumbers ? e : e.toString(),
            displayName: translateFunction(e) ?? '',
        }))).asc(e => e.displayName);
    }

    /**
     * Gibt eine sortierte Liste an Select Items zurück
     *
     * @param {Record} enumObject Enum
     * @param {(value: number) => string | undefined} translateFunction Übersetzungsfunktion
     * @param {number[]} removeValues Zu entfernende Werte des Enums
     * @returns {ISelectItem[]} SelectItems
     */
    public static getSortedMultiSelectItems<T extends Record<string, unknown>>(enumObject: T, translateFunction: (value: number) => string | undefined, removeValues: number[] = []): IMultiSelectItem[] {
        return this.getSortedMultiSelectItemsInternal(enumObject, translateFunction, item => item.displayName, removeValues);
    }

    /**
     * Gibt eine sortierte Liste an Select Items zurück
     *
     * @param {Record} enumObject Enum
     * @param {(value: number) => string | undefined} translateFunction Übersetzungsfunktion
     * @param {number[]} removeValues Zu entfernende Werte des Enums
     * @returns {ISelectItem[]} SelectItems
     */
    public static getSortedMultiSelectItemsByValue<T extends Record<string, unknown>>(enumObject: T, translateFunction: (value: number) => string | undefined, removeValues: number[] = []): IMultiSelectItem[] {
        return this.getSortedMultiSelectItemsInternal(enumObject, translateFunction, item => (item.value === 0 ? 0 : item.displayName), removeValues);
    }


    /**
     * Prüft ob ein Wert leer ist
     *
     * @param {any} value Wert
     * @returns {boolean} Ist Wert leer
     */
    public static isNullOrEmpty(value?: unknown): boolean {
        return value === undefined || value === null || value === '';
    }

    /**
     * Liefert die HttpParams
     *
     * @param {Record<string, any>} data  Request-Daten
     * @param {string | undefined} currentPath Aktueller Pfad im Objekt
     * @param {Record<string, string | number | boolean>} params HTTP-Parameter bei verschachtelten Aufrufen
     * @returns {Record<string, string | number | boolean>} HttpParams
     */
    // eslint-disable-next-line complexity
    public static buildHttpParams(data: Record<string, unknown>, currentPath?: string, params?: HttpParams): HttpParams {
        if (params === undefined) {
            params = new HttpParams();
        }
        for (const key in data) {
            if (Array.isArray(data[key])) {
                const dataArray = data[key] as Array<string | number | boolean>;
                if (dataArray.length > 0) {
                    for (const value of dataArray) {
                        params = params.append(`${currentPath ?? ''}${currentPath !== undefined ? `[${key}]` : key}`, value);
                    }
                }
                else {
                    params = params.append(`${currentPath ?? ''}${currentPath !== undefined ? `[${key}]` : key}`, '');
                }

            }
            else if (data[key] instanceof Object) {
                params = this.buildHttpParams(data[key] as Record<string, unknown>, `${currentPath ?? ''}${currentPath !== undefined ? `[${key}]` : key}`, params);
            } else if (data[key] !== undefined) {
                params = params.append(`${currentPath ?? ''}${currentPath !== undefined ? `[${key}]` : key}`, data[key] as string | number | boolean);
            }
        }
        return params;
    }

    /**
     * Prüft, ob ein Objekt leer ist
     *
     * @param {Record<string, any>} object Zu prüfendes Objekt
     * @returns {boolean} Ist Objekt leer
     */
    public static isObjectEmpty(object: Record<string, unknown>): boolean {
        for (const key in object) {
            if (key in object) {
                return false;
            }
        }
        return true;
    }

    /**
     * Kopiert ein JSON-Objekt
     *
     * @template T Objekttyp
     * @param {T} entity Objekt
     * @returns {T} Kopiertes Objekt
     */
    public static clone<T>(entity: T): T {
        return JSON.parse(JSON.stringify(entity)) as T;
    }

    /**
     * Liefert die Anzahl der Dezimalstellen anhand eines Format-Strings
     *
     * @param {string} numberFormat Formatierung der Zahl
     * @returns {number} Anzahl Dezimalstellen
     */
    public static getMaxDecimalPlacesByFormat(numberFormat: string): number {
        if (numberFormat.indexOf('-') === -1) {
            return 0;
        }
        else {
            const places = parseInt(numberFormat.substr(numberFormat.indexOf('-') + 1), 10);
            if (isNaN(places)) {
                return 0;
            }
            else {
                return places;
            }
        }
    }

    /**
     * Ist der Browser ein alter Edge
     *
     * @returns {boolean} Ist der Browser ein alter Edge
     */
    public static get isEdge(): boolean {
        return /edge/.test(navigator.userAgent.toLowerCase());
    }

    /**
     * convertiert ein Enum Array in ein Flag enum
     *
     * @param {any} array Array aus Enum Werten
     * @returns {any} Flag Enum
     */
    public static convertArrayToFlag<T>(array?: T[] | null): T | undefined | null {
        if (Array.isArray(array) && array.length > 0) {
            return array.reduce((pv: T, cv: T) => {
                if (pv === undefined) {
                    return cv;
                }
                else {
                    return ((pv as unknown as number) | (cv as unknown as number)) as unknown as T;
                }
            });
        }

        return !!array ? null : array;
    }

    /**
     * Prüft ob der übergebene Wert gesetzt ist
     *
     * @param {any} value zu prüfender Wert
     * @returns {boolean} ist gesetzt
     */
    public static hasValue<T>(value: T | undefined | null): value is T {
        return value !== null && value !== undefined;
    }

    /**
     * ersetzt alle string line brakes in HTML brakes
     *
     * @param {string} text originaler string
     * @returns {string} string mit ersetzten line brakes
     */
    public static toHTMLBreakes(text: string): string {
        // https://stackoverflow.com/questions/10805125/how-to-remove-all-line-breaks-from-a-string
        return !!text ? text.replace(/(\r\n|\n|\r)/gm, '<br>') : '';
    }

    /**
     * Erstellt aus einem Base64 String einen Blob
     * 
     * @param {string | null} fileContent Base64 String
     * @param {string} mimeType MIME Type
     * @returns {Blob | undefined} Blob
     */
    public static fileContentToBlob(fileContent?: string | null, mimeType?: string): Blob | undefined {
        if (fileContent === null || fileContent === undefined) {
            return undefined;
        }

        const byteCharacters = window.atob(fileContent);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        return new Blob([byteArray], {type: mimeType});
    }

    /**
     * Datei aus gegebenem Blob herunterladen
     *
     * @param {Blob} blob Blob
     * @param {string} filename Name der Datei
     * @returns {Promise<void>} Void Promise
     */
    public static downloadFileFromBlob(blob: Blob, filename: string): Promise<void> {
        return new Promise<void>(resolve => {
            const ext = `.${extension(blob.type)}`;

            const a = document.createElement('a');
            a.style.display = 'none';
            a.download = filename + ext;

            const blobUrl = (window.URL ? window.URL : window['webkitURL']).createObjectURL(blob);
            a.href = blobUrl;

            document.body.appendChild(a);
            a.click();

            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(blobUrl);
                resolve();
            }, 200);
        
        });
    }

    /**
     * Datei aus gegebenem Blob in neuem Fenster öffnen
     *
     * @param {Blob} blob Blob
     * @returns {Promise<void>} Void Promise
     */
    public static openFileFromBlob(blob: Blob): Promise<void> {
        return new Promise<void>(resolve => {
            const a = document.createElement('a');
            a.style.display = 'none';
            a.target = '_blank';

            const blobUrl = (window.URL ? window.URL : window['webkitURL']).createObjectURL(blob);
            a.href = blobUrl;
            a.target = '_blank';

            document.body.appendChild(a);
            a.click();

            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(blobUrl);
                resolve();
            }, 200);
        });
    }

    /**
     * Erstellt einen ISO String aus einem String wie er beim Created Datum von Objekten vorkommt
     * dd.MM.yyyy hh:mm timezone
     *
     * @param {string} date Datumstring
     * @returns {string} ISO Datumstring
     */
    public static parseGermanDateString(date?: string): string | undefined {
        if (date === undefined) {
            return undefined;
        }
        const parsed = moment(date, 'DD.MM.YYYY HH:mm:ss ZZ');

        return parsed.toISOString();
    }
    
    /**
     * parset das Format "DD.MM.YYYY" in ein ISODate
     *
     * @param {string} date date in format "DD.MM.YYYY"
     * @returns {IsoDate} geparstes Datum
     */
    public static parseGermanDateToISODate(date?: string): IsoDate | null | undefined {

        if (date === null) {
            return null;
        }

        if (date === undefined || date.length === 0) {
            return undefined;
        }

        const parts = date.match(/(\d+)/g);
        const asDate = !!parts && parts.length === 3 ?
            HelperService.toUTCDate(parseInt(parts[2], 10), parseInt(parts[1], 10), parseInt(parts[0], 10)) : null;

        return !!asDate ? asDate.toISOString() : null;
    }
    /**
     * Parsed Jahr, Monat und Tag in ein UTC Date
     *
     * @see https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC
     * @param {number} year das Jahr
     * @param {number} month der Monat
     * @param {number} day der Tag
     * @returns {Date} UTC Datum
     */
    public static toUTCDate(year: number, month: number, day: number): Date {
        // year = integer, month = 0-11, day = 1-31
        return new Date(Date.UTC(year, month - 1, day));
    }
        
    /**
     * null to undefined
     *
     * @param {any} obj T
     */
    public static nullToUndefined<T>(obj: T): void {
        if (Array.isArray(obj)) {
            for (const item of obj) {
                HelperService.nullToUndefined(item);
            }
        } else if (obj !== undefined && obj !== null && 'object' === typeof obj) {
            for (const key in obj) {
                if (obj[key] === null) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj[key] = undefined;
                }
                else if (Array.isArray(obj[key]) || 'object' === typeof obj[key]) {
                    HelperService.nullToUndefined(obj[key]);
                }
            }
        }
    }

    /**
     * isMapReadonly
     *
     * @param {FinancingStatus} status FinancingStatus
     * @returns {boolean} isMapReadOnly boolean
     */
    public static isMapReadonly(status: FinancingStatus): boolean {
        return (
            status === FinancingStatus.Canceled ||
            status === FinancingStatus.SampleCalculationWaitingForAcception ||
            status === FinancingStatus.EsisWaitingForAcception ||
            status === FinancingStatus.Completed ||
            status === FinancingStatus.Rejected
        );
    }

    /**
     * download file
     *
     * @param {Blob} file Blob
     * @param {string} fileName string
     * @param {Renderer2} renderer Renderer2
     */
    public static downloadFile(file: Blob, fileName: string, renderer: Renderer2): void {
        const url = window.URL.createObjectURL(file);
        const a = renderer.createElement('a');
        renderer.setStyle(a, 'display', 'none');
        renderer.setAttribute(a, 'href', url);
        renderer.setAttribute(a, 'download', fileName);
        renderer.appendChild(document.body, a);

        a.click();

        window.URL.revokeObjectURL(url);
        renderer.removeChild(document.body, a);
    }

    /**
     * Gibt eine Standard Eingabemaske für Währungen zurück
     *
     * @param {string} locale Code für Locale
     * @returns {NgxCurrencyConfig} Standard Eingabemaske für Währungen
     */
    public static getStandardCurrencyMask(locale: string): NgxCurrencyConfig {
        return this.getInputMask(locale, {
            prefix: '€ ',
        });
    }

    /**
     * Gibt eine Standard Eingabemaske für Währungen zurück
     *
     * @param {string} locale Code für Locale
     * @returns {NgxCurrencyConfig} Standard Eingabemaske für Währungen
     */
    public static getStandardPercentageMask(locale: string): NgxCurrencyConfig {
        return this.getInputMask(locale, {
            suffix: '',
            precision: HelperService.getMaxDecimalPlacesByFormat('1.0-3'),
            allowNegative: true,
        });
    }

    /**
     * Gibt eine minimale Inputmaske für Eingabefelder zurück
     *
     * @param {string} locale Code für Locale
     * @param {Partial<NgxCurrencyConfig>} extraOptions Optionen zum Überschreiben der Defaultwerte
     * @returns {NgxCurrencyConfig} CurrencyMask
     */
    public static getInputMask(locale: string, extraOptions: Partial<NgxCurrencyConfig> = {}): NgxCurrencyConfig {
        return Object.assign({
            align: 'left',
            allowNegative: false,
            allowZero: true,
            decimal: getLocaleNumberSymbol(locale, NumberSymbol.CurrencyDecimal),
            precision: HelperService.getMaxDecimalPlacesByFormat('1.2-2'),
            prefix: '',
            suffix: '',
            thousands: getLocaleNumberSymbol(locale, NumberSymbol.CurrencyGroup),
            nullable: true,
            max: undefined,
        }, extraOptions);
    }

    /**
     * Entfernt Enumwerte aus einem Enum
     *
     * @param {any} enumObject original Enum
     * @param {number[]} toRemove zu entfernende Werte
     * @returns {any} verkleinertes Enum
     */
    public static removeValuesFromEnum<T>(enumObject: T, toRemove: number[]): T {

        if (toRemove.length === 0) {
            return enumObject;
        }

        const asRecord = { ...enumObject } as unknown as Record<string, number | string>

        for (const key of Object.keys(asRecord)) {
            const value = asRecord[key];
            if (typeof value === 'number' && toRemove.includes(value)) {
                delete asRecord[asRecord[key]];
                delete asRecord[key];
            }
        }

        return asRecord as unknown as T;
    }

    /**
     * Decodiert den Inhalt eines JSON Web Token und gibt ihn als Objekt zurück
     *
     * @param {string} jwt JSON Web Token
     * @returns {any} Inhalt des Tokens
     */
    public static decodeJWT<T>(jwt: string): T {
        const base64url = jwt.split('.')[1];
        const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
        // eslint-disable-next-line prefer-template
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(char => `%${('00' + char.charCodeAt(0).toString(16)).slice(-2)}`).join(''));
        return JSON.parse(jsonPayload);
    }

    /**
     * Gibt einen Zinssatz aus der LiabilityConfiguration zurück
     * 
     * @param {ILiabilityConfiguration} liabilityConfiguration ILiabilityConfiguration
     * @param {LiabilityType} type Art der Verbindlichkeit
     * @returns {number | undefined} Fiktiver Zinssatz
     */
    // eslint-disable-next-line complexity
    public static getInterestRateFromLiabilityConfiguration(liabilityConfiguration: ILiabilityConfiguration, type?: LiabilityType | null): number | undefined {
        if (type === undefined || type === null) {
            return undefined;
        }

        switch (type) {
            case LiabilityType.Credit:
                return liabilityConfiguration.creditFictionalRate;
            case LiabilityType.ComfortCredit:
                return liabilityConfiguration.comfortCreditFictionalRate;
            case LiabilityType.Overdraft:
                return liabilityConfiguration.overdraftFictionalRate;
            case LiabilityType.OneTimeCashLoan:
                return liabilityConfiguration.oneTimeCashLoanFictionalRate;
            case LiabilityType.GuaranteeStandAlone:
                return liabilityConfiguration.guaranteeStandAloneFictionalRate;
            case LiabilityType.GuaranteeConstruction:
                return liabilityConfiguration.guaranteeConstructionFictionalRate;
            case LiabilityType.ConstructionInterimFinancing:
                return liabilityConfiguration.constructionInterimFinancingFictionalRate;
            case LiabilityType.ConstructionPrefinancingInvestmentFlatLow:
                return liabilityConfiguration.constructionPrefinancingInvestmentFlatLowFictionalRate;
            case LiabilityType.ConstructionPrefinancingInvestmentFlatHigh:
                return liabilityConfiguration.constructionPrefinancingInvestmentFlatHighFictionalRate;
            case LiabilityType.ConstructionFollowUpFinancing:
                return liabilityConfiguration.constructionFollowUpFinancingFictionalRate;
            case LiabilityType.ConstructionFollowUpFinancingBuildingLoan:
                return liabilityConfiguration.constructionFollowUpFinancingBuildingLoanFictionalRate;
            case LiabilityType.CreditCard:
                return liabilityConfiguration.creditCardFictionalRate;
            case LiabilityType.DevelopmentLoan:
                return liabilityConfiguration.developmentLoanFictionalRate;
            case LiabilityType.SubsidizedLoan:
                return liabilityConfiguration.subsidizedLoanFictionalRate;
            case LiabilityType.CompanyCredit:
                return liabilityConfiguration.companyCreditFictionalRate;
            case LiabilityType.KfzLeasing:
                return liabilityConfiguration.kfzLeasingFictionalRate;
            default:
                return undefined;
        }
    }

    /**
     * Gibt eine sortierte Liste an Select Items zurück
     *
     * @param {Record} enumObject Enum
     * @param {(value: number) => string | undefined} translateFunction Übersetzungsfunktion
     * @param {ISortBy<IMultiSelectItem>} sortBy sortBy
     * @param {number[]} removeValues Zu entfernende Werte des Enums
     * @returns {ISelectItem[]} SelectItems
     */
    private static getSortedMultiSelectItemsInternal<T extends Record<string, unknown>>(enumObject: T, translateFunction: (value: number) => string | undefined, sortBy: ISortBy<IMultiSelectItem>, removeValues: number[] = []): IMultiSelectItem[] {
        let enumArray = this.getEnumArray(enumObject, true) as number[];

        if (removeValues.length > 0) {
            enumArray = enumArray.filter(value => !removeValues.includes(value));
        }

        return sort(enumArray.map(e => ({
            value: e,
            displayName: translateFunction(e) ?? '',
            isChecked: false,
        }))).asc(sortBy);
    }
}
