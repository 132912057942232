import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { AssetService, EntityClassType, FinancingService, IFinancingStateParentDefinition, ValueStorageType } from 'app/modules/financing/data';
import { Observable, of, take } from 'rxjs';

/**
 * Common for all Asset
 */
@Component({
    template: '',
})

export class AssetCommonComponent {

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ValueStorageType = ValueStorageType;
    
    public entityClassType = EntityClassType;    
    
    public inputFlexDirection = 'column';

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Observable immer Schreibschutz mit Bearbeitungsmodus
     */
    public alwaysReadonly$: Observable<boolean> = of(true);
    
    /**
     * Standard Constructor
     * 
     * @param {AssetService} assetService AssetService-Injector
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService financing service
     */
    public constructor(
        protected assetService: AssetService,
        protected enumTranslate: EnumTranslationPipe,
        protected store: Store,
        protected financingService: FinancingService,
    ) {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
    }

    /**
     * Set Asset as Active
     * 
     * @param {string} assetId assetId 
     * @param { boolean }isActive boolean
     */
    public setAssetActive(assetId: string, isActive: boolean): void {
        const financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);
        if (financingContainerID) {
            this.assetService.setAssetActive(financingContainerID, assetId, isActive).pipe(take(1)).subscribe();     
        }
    }
}
