export enum Environment {
    Debug,
    Dev,
    Test,
    Midterm,
    Integration,
    Preview,
    Demo,
    Live,
}
