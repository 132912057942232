<div class="mr-2 ml-2 mb-3">
    <div fxLayoutAlign="center">
        <h4 *ngIf="calcType === 0" class="titles">
            <b>{{'financing.features.financing-processing.householdBills.riskTitle' | translate }}</b>
        </h4>
        <h4 *ngIf="calcType === 2" class="titles">
            <b>{{'financing.features.financing-processing.householdBills.pensionTitle' | translate }}</b>
        </h4>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <!-- Navigation buttons -->
        <button mat-button [disabled]="currentHouseHoldPage === 0"
            (click)="onPreviousOrNextData(-1)">
            <mat-icon color="primary"
                [style.color]="currentHouseHoldPage === 0 ? 'lightgrey' : ''">arrow_back</mat-icon>
        </button>

        <div class="switch-debitor">
            {{'financing.features.financing-processing.householdBills.householdSwitch' | translate }}
        </div>

        <button mat-button [disabled]="currentHouseHoldPage === (totalHouseHoldPages ? totalHouseHoldPages - 1 : 0)"
            (click)="onPreviousOrNextData(1)">
            <mat-icon color="primary"
                [style.color]="currentHouseHoldPage === (totalHouseHoldPages ? totalHouseHoldPages - 1 : 0) ? 'lightgrey' : ''">arrow_forward</mat-icon>
        </button>
    </div>

    <div fxLayout="column" fxLayoutAlign="center">
        <table>
            <thead style="height: 49px;">
                <tr>
                    <ng-container *ngFor="let riskHoushold of visibleHouseHoldOverview; let i = index;">

                        <!--riskhousehold table-->
                        <th *ngIf="calcType === 0" class="pension-table-th">
                            <span>
                                {{'financing.features.financing-processing.householdBills.household' |  translate}}
                                {{householdData && visibleHouseHoldOverview ? householdData.indexOf(visibleHouseHoldOverview[i]) + 1 : 0}}
                            </span>
                            <button *ngIf="isRiskCalcTable" mat-stroked-button color="primary" class="ml-3" style="width: 30px; height: 30px;" 
                                    [disabled]="generateDocumentDisabled || isBaf || (fieldReadonly$ | async)"
                                    [matTooltip]="riskHoushold?.householdCalculationExist === true ? 'Dokumente vorhanden, erneut generieren' : 'Dokumente generieren'"
                                    [matTooltipDisabled]="isBaf"
                                    (click)="onGenerateDocuments(riskHoushold.id)">
                                <mat-icon 
                                    [matTooltip]="'financing.features.financing-processing.householdBills.notPossible' |  translate"
                                    [matTooltipDisabled]="!isBaf"
                                > file_copy</mat-icon>
                                <span *ngIf="riskHoushold?.householdCalculationExist" style="position: relative; top: -5px;">*</span>
                            </button>
                         </th>

                         <!--pension table-->
                         <th *ngIf="calcType === 2" class="pension-table-th">
                            <span>
                                {{'financing.features.financing-processing.householdBills.household' |  translate}}
                                {{householdData && visibleHouseHoldOverview ? householdData.indexOf(visibleHouseHoldOverview[i]) + 1 : 0}}
                            </span>
                         </th>

                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <ng-container *ngFor="let riskhousehold of visibleHouseHoldOverview">
                        <td *ngFor="let calculation of riskhousehold.calculations">
                            {{ calculation?.sumOfIncome | formatCurrency }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <td [attr.colspan]="visibleHouseHoldOverview?.length">
                        <ng-container *ngFor="let riskhousehold of householdData">
                            {{ riskhousehold?.sumOfIncomeOverview | formatCurrency }}
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <ng-container *ngFor="let riskhousehold of visibleHouseHoldOverview">
                        <td *ngFor="let calculation of riskhousehold.calculations">
                            {{ calculation?.expenditure?.sumOfExpenditure | formatCurrency }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <td [attr.colspan]="visibleHouseHoldOverview?.length">
                        <ng-container *ngFor="let riskhousehold of householdData">
                            <b>{{ riskhousehold?.expenditureOverview | formatCurrency }}</b>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <ng-container *ngFor="let riskhousehold of visibleHouseHoldOverview">
                        <td *ngFor="let calculation of riskhousehold.calculations">
                            {{ calculation?.availableIncome | formatCurrency }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <td [attr.colspan]="visibleHouseHoldOverview?.length">
                        <ng-container *ngFor="let riskhousehold of householdData">
                            <b>{{ riskhousehold?.availableIncomeOverview | formatCurrency }}</b>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <ng-container *ngFor="let riskhousehold of visibleHouseHoldOverview">
                        <td *ngFor="let calculation of riskhousehold.calculations">
                            {{ calculation?.safetyReserve | formatCurrency
                            }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <td [attr.colspan]="visibleHouseHoldOverview?.length">
                        <ng-container *ngFor="let riskhousehold of householdData">
                            <b>{{ riskhousehold?.discretionaryIncomeOverview | formatCurrency }}</b>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <ng-container *ngFor="let riskhousehold of visibleHouseHoldOverview">
                        <td *ngFor="let calculation of riskhousehold.calculations">
                            {{ calculation?.sumNewExternalLiabilities | formatCurrency }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <ng-container *ngFor="let riskhousehold of visibleHouseHoldOverview">
                        <td *ngFor="let calculation of riskhousehold.calculations">
                            {{ calculation?.existingLiabilities| formatCurrency
                            }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <td [attr.colspan]="visibleHouseHoldOverview?.length">
                        <ng-container *ngFor="let riskhousehold of householdData">
                            <b>{{ riskhousehold?.loanOverview | formatCurrency }}</b>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td [attr.colspan]="visibleHouseHoldOverview?.length">
                        <ng-container *ngFor="let riskhousehold of householdData">
                            <b>{{ riskhousehold?.reasonableCreditRateOverview | formatCurrency }}</b>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
